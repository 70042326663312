<ng-container *ngIf="T$ | async as T">

  <ng-content></ng-content>

  <ng-container *ngIf="advancedSearch; else searchFields">
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <!-- Multiselect search -->
          <ng-template #multiselectSearch let-controlName="controlName" let-options="options" ,
            let-optionValue="optionValue" let-optionLabel="optionLabel" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-showToggleAll="showToggleAll">
            <div class="p-inputgroup">
              <p-multiSelect [options]="options" [formControlName]="controlName" [optionLabel]="optionLabel ?? 'des'"
                [optionValue]="optionValue ?? 'id'" styleClass="multiselect-inputgroup"
                [placeholder]="T | translateItem: placeholderKey" class="inputfield multiselect-sm"
                [emptyMessage]="T | translateItem: 'generic.dropdownempty'" [maxSelectedLabels]="1"
                [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" [filter]="true"
                [autofocusFilter]="true" [resetFilterOnHide]="true" [showToggleAll]="showToggleAll ?? true">
              </p-multiSelect>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <!-- Date search -->
          <ng-template #dateSearch let-controlName="controlName" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-minDate="minDate" let-maxDate="maxDate">
            <div class="p-inputgroup">
              <p-calendar [formControlName]="controlName" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" styleClass="multiselect-inputgroup" class="inputfield p-inputtext-sm"
                [placeholder]="T | translateItem: placeholderKey" [minDate]="minDate" [maxDate]="maxDate">
              </p-calendar>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataInizio', placeholderKey: 'giudiciimpegnimaniexp.dataInizio', minDate: dataInizioLimite, maxDate: dataFineLimite}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataFine', placeholderKey: 'giudiciimpegnimaniexp.dataFine', minDate: dataInizioLimite, maxDate: dataFineLimite}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'tipoManiId', options: calTipoManiList$ | async, optionValue:'id',  optionLabel:'des', placeholderKey: 'giudiciimpegnimaniexp.tipoMani'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="p-inputgroup">
              <input pInputText type="text" formControlName="luogoLabel"
                [placeholder]="T | translateItem: 'giudiciimpegnimaniexp.luogo'" class="inputfield p-inputtext-sm">
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl('luogoLabel')"></button>
            </div>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2 flex">
            <p-checkbox formControlName="flGiudiceAssegnato"
              [label]="T | translateItem: 'giudiciimpegnimaniexp.giudiceAssegnato'" [value]="true" [binary]="true"
              class="inputfield">
            </p-checkbox>
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchBasic'" [label]="T | translateItem: 'generic.searchBasic'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template #searchFields>
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <span class="col-12">
            <input pInputText type="text" formControlName="simpleSearch"
              [placeholder]="T | translateItem: 'giudiciimpegnimaniexp.simpleSearchPlaceholder'"
              class="inputfield p-inputtext-sm">
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchAdvanced'" [label]="T | translateItem: 'generic.searchAdvanced'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-template>

  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" responsiveLayout="stack" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" style="width: 3rem"></th>

        <th class="white-space-nowrap" pSortableColumn="dataInizio">
          {{T | translateItem: 'giudiciimpegnimaniexp.dataInizio'}}
          <p-sortIcon field="dataInizio"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataFine">
          {{T | translateItem: 'giudiciimpegnimaniexp.dataFine'}}
          <p-sortIcon field="dataFine"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="tipoManiDes">
          {{T | translateItem: 'giudiciimpegnimaniexp.tipoMani'}}
          <p-sortIcon field="tipoManiDes"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="luogoLabel">
          {{T | translateItem: 'giudiciimpegnimaniexp.luogo'}}
          <p-sortIcon field="luogoLabel"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="flGiudiceAssegnato">
          {{T | translateItem: 'giudiciimpegnimaniexp.giudiceAssegnato'}}
          <p-sortIcon field="flGiudiceAssegnato"></p-sortIcon>
        </th>

        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.giornateImpegnate'}}
        </th>

        <!-- <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.razze'}}
        </th>

        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.raggruppamenti'}}
        </th> -->

        <th class="white-space-nowrap buttons-column">
          <div class="buttons-cnt">
            <div>
              <p-menu #exportMenu [popup]="true" [model]="exportMenuItems" [appendTo]="'body'"> </p-menu>
              <button type="button" pButton icon="fa-solid fa-file-arrow-down"
                [title]="T | translateItem: 'generic.export'" [disabled]="elaboration"
                class="p-button-md p-button-outlined" (click)="setExportMenuItems(); exportMenu.toggle($event)"
                *grantVisible="grants$ | async; key 'exportdata'"></button>
            </div>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-ri="rowIndex" let-expanded>
      <tr [class.table-hilighted-row]="rowData.id === maniId"
        [pTooltip]="T | translateItem: 'giudiciimpegnimaniexp.manifestazioneCorrente'"
        [tooltipDisabled]="rowData.id !== maniId" tooltipPosition="top">
        <td>
          <button type="button" pButton pRipple [pRowToggler]="rowData"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-right'"
            [disabled]="rowData?.impegniRazzeList.length === 0"></button>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.dataInizio'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.dataInizio | date: dateFormat}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.dataFine'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.dataFine | date: dateFormat}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.tipoMani'}}
          </span>
          {{rowData.tipoManiDes}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.luogo'}}
          </span>
          {{rowData.luogoLabel}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.giudiceAssegnato'}}
          </span>
          <app-yes-no [value]="rowData.flGiudiceAssegnato"
            [labels]="{yes: T | translateItem: 'generic.si', no: T | translateItem: 'generic.no'}">
          </app-yes-no>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.giornateImpegnate'}}
          </span>
          {{rowData.giorniImpegnatiList | giorniToString : dateFormat}}
        </td>

        <!-- <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.razze'}}
          </span>
          <span [innerHtml]="rowData.razzeLabel"></span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.raggruppamenti'}}
          </span>
          {{rowData.raggruppamentiLabel}}
        </td> -->

        <td class="buttons-column"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData>
      <tr>
        <td colspan="100%">
          <p-tabView>
            <!-- Razze -->
            <p-tabPanel>
              <ng-template pTemplate="header">
                {{T | translateItem: 'giudiciimpegnimaniexp.razze'}}
              </ng-template>
              <ng-template pTemplate="content">
                <p-table [value]="rowData.impegniRazzeList" dataKey="id" responsiveLayout="scroll" [lazy]="true"
                  styleClass="p-datatable-striped p-datatable-md">
                  <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.giorno'}}
        </th>

        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.data'}}
        </th>

        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.gruppoFci'}}
        </th>

        <th class="white-space-nowrap">
          {{T | translateItem: 'giudiciimpegnimaniexp.razza'}}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowDataDetailRazza>
      <tr>
        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.giorno'}}
          </span>
          {{rowDataDetailRazza.giorno}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.data'}}
          </span>
          {{rowDataDetailRazza.data | date: dateFormat}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.gruppoFci'}}
          </span>
          {{rowDataDetailRazza.gruppoFciLabel}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexp.razza'}}
          </span>
          {{rowDataDetailRazza.razzaDes}}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center">
          {{T | translateItem: 'generic.listEmpty'}}
        </td>
      </tr>
    </ng-template>
  </p-table>
  </ng-template>
  </p-tabPanel>

  <!-- Raggruppamenti -->
  <p-tabPanel>
    <ng-template pTemplate="header">
      {{T | translateItem: 'giudiciimpegnimaniexp.raggruppamenti'}}
    </ng-template>
    <ng-template pTemplate="content">
      <p-table [value]="rowData.impegniRaggruppamentiList" dataKey="id" responsiveLayout="scroll" [lazy]="true"
        styleClass="p-datatable-striped p-datatable-md">
        <ng-template pTemplate="header">
          <tr>
            <th class="white-space-nowrap">
              {{T | translateItem: 'giudiciimpegnimaniexp.giorno'}}
            </th>

            <th class="white-space-nowrap">
              {{T | translateItem: 'giudiciimpegnimaniexp.data'}}
            </th>

            <th class="white-space-nowrap">
              {{T | translateItem: 'giudiciimpegnimaniexp.raggruppamento'}}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowDataDetailRaggruppamento>
          <tr>
            <td>
              <span class="p-column-title">
                {{T | translateItem: 'giudiciimpegnimaniexp.giorno'}}
              </span>
              {{rowDataDetailRaggruppamento.flPerGiornata ?rowDataDetailRaggruppamento.giorno : (T | translateItem:
              'giudiciimpegnimaniexp.tuttiGiorni')}}
            </td>

            <td>
              <span class="p-column-title">
                {{T | translateItem: 'giudiciimpegnimaniexp.data'}}
              </span>
              {{rowDataDetailRaggruppamento.flPerGiornata ? (rowDataDetailRaggruppamento.data | date: dateFormat) :
              (null
              | noValuePlaceholder)}}
            </td>

            <td>
              <span class="p-column-title">
                {{T | translateItem: 'giudiciimpegnimaniexp.raggruppamento'}}
              </span>
              {{rowDataDetailRaggruppamento.raggruppamentoDes}}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="100%" class="text-center">
              {{T | translateItem: 'generic.listEmpty'}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-tabPanel>
  </p-tabView>
  </td>
  </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%" class="text-center danger">
        {{T | translateItem: 'generic.norecordsfound'}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</ng-container>