import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppManagerRoutes } from '../../app-manager-routes';
import { AppInjector } from '../../app.module';
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from '../data-search';
import { Entity } from '../entity';
import { CmdEvent, CmdField, CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from '../entity-config';

export class RuoloPiattaformaDto {
  name: string;
  roleGuid: string;
  isSelected: boolean;
}

export class SecUtenteRuoliPiattaformeDto {
  guid: string;
  email: string;
  des: string;
  ruoliShow: RuoloPiattaformaDto[];
  ruoliProve: RuoloPiattaformaDto[];
  ruoliServizi: RuoloPiattaformaDto[];
  ruoliSlovenia: RuoloPiattaformaDto[];
  ruoliMonaco: RuoloPiattaformaDto[];
}

export class SecUtente extends Entity {
  guid: string;
  currentProfiloId: number;
  currentLanguage: string;
  email: string;

  regioneId: number;
  regioneCod: string;
  regioneDes: string;
  provinciaId: number;
  provinciaCod: string;
  provinciaDes: string;
  provinciaTarga: string;
  enciIdAnag: number;
  livelloProfiloCod: string;
  profiloTipologia: string;
  ragioneSociale: string;
  flHoRilasciDaAccettare: boolean;

  nome: string;
  cognome: string;
  codFiscale: string;
  des: string;
  flCustomer: boolean;
  flBoShow: boolean;
  flBoKzs: boolean;
  flBoMonaco: boolean;
  flBoProve: boolean;
  flBoServizi: boolean;

  public get isCo() {
    return this.livelloProfiloCod === 'CO';
  }

  public get isAs() {
    return this.livelloProfiloCod === 'AS';
  }

  public get isCcr() {
    return this.livelloProfiloCod === 'CCR';
  }

  public get isAdmin() {
    const adminProfiles = ['SUPER_ADMIN', 'ADMIN'];
    return adminProfiles.includes(this.profiloTipologia);
  }
}

export class SecUtenteEMC extends EntityManagerConfig {
  router: Router;
  activatedRoute: ActivatedRoute;

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none };

  init() {
    this.textComponent = {
      windowListHeader: `${this.name}.windowListHeader`,
      windowEditHeader: `${this.name}.windowEditHeader`
    };
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.dataSortValue = [new DataSortValue('email', SortMode.Asc)];

    this.router = AppInjector.get(Router);
    this.activatedRoute = AppInjector.get(ActivatedRoute);

    const genLangDs: DataSearch = new DataSearch();
    genLangDs.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, genLangDs);

    const secProfiloDs: DataSearch = new DataSearch();
    secProfiloDs.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['secprofilo'] = this.getEntityService('secprofilo').searchEntitiesAsync(this.fLoad, secProfiloDs);

    this.attributes['yesNo'] = [{ value: '1', des: 'Si' }, { value: '0', des: 'No' }];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow, mode: CmdFieldMode.btn, target: CmdFieldTarget.containerTools,
        title: 'generic.help', icon: Icons.help, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },
      {
        key: CmdFieldKey.back, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.back', label: 'generic.back', icon: Icons.back, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save, mode: CmdFieldMode.btn, target: CmdFieldTarget.detailHeader,
        title: 'generic.save', label: 'generic.save', icon: Icons.save, class: CmdFieldStyle.btnPrimary,
        backAfterClick: true
      },

      // List search
      {
        key: CmdFieldKey.search, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, type: 'submit',
        label: 'generic.search', title: 'generic.search', icon: Icons.search, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset', title: 'generic.searchReset', icon: Icons.reset, class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle, mode: CmdFieldMode.btn, target: CmdFieldTarget.listSearch, label: 'generic.searchBasic',
        title: 'generic.searchBasic', icon: Icons.filters, class: CmdFieldStyle.btnSecondary,
        state: false
      },

      //List detail
      {
        key: CmdFieldKey.add, mode: CmdFieldMode.btn, target: CmdFieldTarget.listHeader, entityManagerInfo: AppManagerRoutes.SecUtenteNew,
        label: 'generic.add', title: 'generic.add', icon: Icons.plus, class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: 'generic.edit', icon: Icons.edit, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecUtenteDetail,
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.SecUtenteProfiliList`, icon: Icons.user, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecUtenteProfiliList
      },
      {
        key: CmdFieldKey.navigateGuid, mode: CmdFieldMode.btn, target: CmdFieldTarget.listBody,
        title: `${this.name}.SecUtenteRuoliPiattaformeList`, icon: Icons.globe, class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.SecUtenteRuoliPiattaformeList
      }
    ];

    this.listFields = [
      {
        key: 'id', type: FormFieldType.number, label: `${this.name}.id`, sortable: true
      },
      {
        key: 'guid', type: FormFieldType.string, label: `${this.name}.guid`, sortable: true
      },
      {
        key: 'email', type: FormFieldType.string, label: `${this.name}.email`, sortable: true
      },
      {
        key: 'des', type: FormFieldType.string, label: `${this.name}.des`, sortable: true
      },
      //{
      //  key: 'nome', type: FormFieldType.string, label: `${this.name}.nome`, sortable: true
      //},
      //{
      //  key: 'cognome', type: FormFieldType.string, label: `${this.name}.cognome`, sortable: true
      //},
      //{
      //  key: 'codFiscale', type: FormFieldType.string, label: `${this.name}.codFiscale`, sortable: true
      //},
      {
        key: 'currentLanguage', type: FormFieldType.string, label: `${this.name}.currentLanguage`, sortable: true
      },
      {
        key: 'livelloProfiloCod', type: FormFieldType.string, label: `${this.name}.livelloProfiloCod`, sortable: true
      },
      {
        key: 'flCustomer', type: FormFieldType.boolean, label: `${this.name}.flCustomer`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'flBoShow', type: FormFieldType.boolean, label: `${this.name}.flBoShow`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'flBoProve', type: FormFieldType.boolean, label: `${this.name}.flBoProve`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'flBoServizi', type: FormFieldType.boolean, label: `${this.name}.flBoServizi`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'flBoMonaco', type: FormFieldType.boolean, label: `${this.name}.flBoMonaco`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      },
      {
        key: 'flBoKzs', type: FormFieldType.boolean, label: `${this.name}.flBoKzs`, sortable: true, format: { yesNo: { yesLbl: 'generic.si', noLbl: 'generic.no' } },
      }
    ];

    this.detailFields = [
      {
        key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'codFiscale', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text,
        label: `${this.name}.codFiscale`, placeholder: `${this.name}.codFiscale`,
        validators: [Validators.required]
      },
      // {
      //   key: 'id', type: FormFieldType.number, typeHtml: FormFieldTypeHtml.readonly
      // },
      // {
      //   key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
      //   label: `${this.name}.email`, placeholder: `${this.name}.email`,
      //   validators: [Validators.required]
      // },
      // {
      //   key: 'guid', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
      //   label: `${this.name}.guid`, placeholder: `${this.name}.guid`,
      //   validators: [Validators.required]
      // },
      // {
      //   key: 'currentLanguage', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
      //   label: `${this.name}.currentLanguage`, placeholder: `${this.name}.currentLanguage`,
      //   validators: [Validators.required]
      // },
      // {
      //   key: 'livelloProfiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.readonly,
      //   label: `${this.name}.livelloProfiloCod`, placeholder: `${this.name}.livelloProfiloCod`,
      //   validators: [Validators.required]
      // }
    ];

    this.searchFields = [
      {
        key: 'search', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: { searchFields: 'email,guid', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'id', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.id`,
        options: { searchFields: 'id', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'guid', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.guid`,
        options: { searchFields: 'guid', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'email', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.email`,
        options: { searchFields: 'email', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'des', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.text, placeholder: `${this.name}.des`,
        options: { searchFields: 'des', searchCompOp: ComparisonOperator.Contains }, class: FormFieldStyle.default
      },
      {
        key: 'currentLanguage', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.currentLanguage`,
        options: { searchFields: 'currentLanguage', data: this.attributes['genlang'], dataKey: 'lang', dataLabel: 'des', searchCompOp: ComparisonOperator.Equals, }, class: FormFieldStyle.default
      },
      {
        key: 'livelloProfiloCod', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.livelloProfiloCod`,
        options: {
          data: this.attributes['secprofilo'], dataKey: 'cod',
          dataLabel: 'des', searchFields: 'livelloProfiloCod', searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flCustomer', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flCustomer`,
        options: {
          searchFields: 'flCustomer',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flBoShow', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flBoShow`,
        options: {
          searchFields: 'flBoShow',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flBoProve', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flBoProve`,
        options: {
          searchFields: 'flBoProve',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flBoServizi', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flBoServizi`,
        options: {
          searchFields: 'flBoServizi',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flBoMonaco', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flBoMonaco`,
        options: {
          searchFields: 'flBoMonaco',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      },
      {
        key: 'flBoKzs', type: FormFieldType.string, typeHtml: FormFieldTypeHtml.select, placeholder: `${this.name}.flBoKzs`,
        options: {
          searchFields: 'flBoKzs',
          data: this.attributes['yesNo'], dataKey: 'value', dataLabel: 'des',
          searchCompOp: ComparisonOperator.Equals,
        }, class: FormFieldStyle.default
      }
    ];
  }

  onCmd(event: CmdEvent): boolean {
    switch (event.field.key) {
      case CmdFieldKey.navigate:
        if (event.field.target == CmdFieldTarget.containerTools) {
          this.router.navigate([AppManagerRoutes.Gestione.url, this.id, event.field.entityManagerInfo.url]);
          // event will not be propagate forward
          return true;
        }
        return false;

      default:
        return super.onCmd(event);
    }
  }

  isCmdFieldVisible(field: CmdField, args: any): boolean {
    switch (field.key) {
      case CmdFieldKey.edit:
        return !args?.entity?.codFiscale;
      default:
        return super.isCmdFieldVisible(field, args);
    }
  }
}
