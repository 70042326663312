import { Component } from '@angular/core';

type Lang = 'it-IT' | 'en-GB';

@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrls: ['./unauthorized-user.component.scss']
})

export class UnauthorizedUserComponent {
  currentLanguage: Lang = 'it-IT';

  messageMap: { [key: string]: string } = {
    'it-IT': 'Utente non autorizzato all\'utilizzo dell\'applicazione Cinofila',
    'en-GB': 'User not authorized to use the application Cinofilia',
    'other': 'Utente non autorizzato all\'utilizzo dell\'applicazione Cinofila'
  };

  secondaryMessageMap: { [key: string]: string } = {
    'it-IT': 'Si prgea di richiedere ad ENCI l\'abilitazione all\'utilizzo dell\'applicativo.',
    'en-GB': 'Please request permission to use the application to ENCI.',
    'other': 'Si prgea di richiedere ad ENCI l\'abilitazione all\'utilizzo dell\'applicativo.'
  };

  setlanguage(lang: Lang): void {
    this.currentLanguage = lang;
  }
}
