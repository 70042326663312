import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { capitalize } from 'lodash-es';
import { Info } from 'luxon';
import { Observable, of } from 'rxjs';
import { finalize, map, share, tap } from 'rxjs/operators';
import { CalCircuito } from '../models/anagrafiche/calendario/cal-circuito';
import { CalDefRaggruppamenti } from '../models/anagrafiche/calendario/cal-def-raggruppamenti';
import { CalFamiRazze } from '../models/anagrafiche/calendario/cal-fami-razze';
import { CalMotivoBloccoData } from '../models/anagrafiche/calendario/cal-motivo-blocco-data';
import { CalStatoCal } from '../models/anagrafiche/calendario/cal-stato-cal';
import { CalStatoMani } from '../models/anagrafiche/calendario/cal-stato-mani';
import { CalTipoCal } from '../models/anagrafiche/calendario/cal-tipo-cal';
import { CalTipoMani } from '../models/anagrafiche/calendario/cal-tipo-mani';
import { CalTipoPeriodoCal } from '../models/anagrafiche/calendario/cal-tipo-periodo-cal';
import { CalTipoSpConf } from '../models/anagrafiche/calendario/cal-tipo-sp-conf';
import { CalTipoStampaCal } from '../models/anagrafiche/calendario/cal-tipo-stampa-cal';
import { CalTipoStampaMani } from '../models/anagrafiche/calendario/cal-tipo-stampa-mani';
import { GenAmbito } from '../models/anagrafiche/gen-ambito';
import { GenAnomalia } from '../models/anagrafiche/gen-anomalia';
import { GenAnomaliaStato } from '../models/anagrafiche/gen-anomalia-stato';
import { GenCartellino } from '../models/anagrafiche/gen-cartellino';
import { GenClasse } from '../models/anagrafiche/gen-classe';
import { GenGruppoFci } from '../models/anagrafiche/gen-gruppo-fci';
import { GenQualifica } from '../models/anagrafiche/gen-qualifica';
import { GenQualificaClasse } from '../models/anagrafiche/gen-qualifica-classe';
import { GenLang } from '../models/anagrafiche/gen-resource';
import { GenUnitaMisura } from '../models/anagrafiche/gen-unita-misura';
import { CalDefTipiOperazione } from '../models/calendario/cal-def-tipi-operazione';
import { CalPrvSpCaratteristica } from '../models/calendario/cal-prv-sp-caratteristica';
import { CalTipoAmbSvolgMani } from '../models/calendario/cal-tipo-amb-svolg-mani';
import { CalTipoGiudSupreme } from '../models/calendario/cal-tipo-giud-supreme';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from '../models/data-search';
import { IEntityList } from '../models/entity';
import { EduAnaSezione } from '../models/formazione/edu-ana-sezione';
import { EduCiclo } from '../models/formazione/edu-ciclo';
import { EduCicloAnno } from '../models/formazione/edu-ciclo-anno';
import { EduCorsoCredito } from '../models/formazione/edu-corso-credito';
import { EduEvento } from '../models/formazione/edu-evento';
import { EduModalitaSvolgimento } from '../models/formazione/edu-modalita-svolgimento';
import { EduOrganizzatore } from '../models/formazione/edu-organizzatore';
import { EduRuolo } from '../models/formazione/edu-ruolo';
import { EduStato } from '../models/formazione/edu-stato';
import { EduStatoCorsista } from '../models/formazione/edu-stato-corsista';
import { EduTipoCorso } from '../models/formazione/edu-tipo-corso';
import { EduTipoSuperamento } from '../models/formazione/edu-tipo-superamento';
import { GeoComune } from '../models/geo/geo-comune';
import { GeoComuneCap } from '../models/geo/geo-comune-cap';
import { GeoNazione } from '../models/geo/geo-nazione';
import { GeoProvincia } from '../models/geo/geo-provincia';
import { GeoRegione } from '../models/geo/geo-regione';
import { GenRelazione } from '../models/gestione/gen-relazione';
import { GenTipoLogEvento } from '../models/gestione/gen-tipo-log-evento';
import { SecProfilo } from '../models/security/sec-profilo';
import { VeeRazze } from '../models/vee/vee-razze';
import { VuxGiudiciTipiSospensione } from '../models/vux/vux-giudici-tipi-sospensione';
import { VuxLingue } from '../models/vux/vux-lingue';
import { VuxSociDeleGruppi } from '../models/vux/vux-soci-dele-gruppi';
import { isNullOrUndefined } from '../utils/util';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {
  private cacheItems: { [key: string]: any } = {};
  private cacheObservable: { [key: string]: Observable<any> } = {};

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService
  ) { }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  getBaseData$<T>(routeUrl: string, dataSearch?: DataSearch): Observable<IEntityList<T>> {
    let url = routeUrl;

    if (!isNullOrUndefined(dataSearch)) {
      const queryStringParam = dataSearch.toQueryString();
      url = `${routeUrl}?${queryStringParam}`;
    }

    if (this.cacheItems[url]) {
      return of(this.cacheItems[url]);
    }
    else if (!this.cacheObservable[url]) {
      this.cacheObservable[url] = this.httpClient.request<IEntityList<T>>('GET', url).pipe(
        tap(objRes => this.cacheItems[url] = objRes),
        share(),
        finalize(() => this.cacheObservable[url] = null)
      );
    }

    return this.cacheObservable[url];
  }


  // Anagrafiche calendario
  getCalTipoCalList(): Observable<CalTipoCal[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoCal>(`${this.dataService.configSettings.restCalendarioUrl}/caltipocal`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalStatoCalList(): Observable<CalStatoCal[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('livello', SortMode.Asc), new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalStatoCal>(`${this.dataService.configSettings.restCalendarioUrl}/calstatoCal`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalTipoPeriodoCalList(): Observable<CalTipoPeriodoCal[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoPeriodoCal>(`${this.dataService.configSettings.restCalendarioUrl}/caltipoperiodocal`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalFamiRazzeList(): Observable<CalFamiRazze[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalFamiRazze>(`${this.dataService.configSettings.restCalendarioUrl}/calfamirazze`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalMotivoBloccoDataList(): Observable<CalMotivoBloccoData[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );
    return this.getBaseData$<CalMotivoBloccoData>(`${this.dataService.configSettings.restCalendarioUrl}/calmotivobloccodata`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalCalTipoStampaMani(): Observable<CalTipoStampaMani[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoStampaMani>(`${this.dataService.configSettings.restCalendarioUrl}/caltipostampamani`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalCalTipoStampaCal(): Observable<CalTipoStampaCal[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoStampaCal>(`${this.dataService.configSettings.restCalendarioUrl}/caltipostampacal`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalTipoSpConfList(): Observable<CalTipoSpConf[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoSpConf>(`${this.dataService.configSettings.restCalendarioUrl}/getcaltipospconf`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Anagrafiche manifestazione
  getCalCircuitoList(): Observable<CalCircuito[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalCircuito>(`${this.dataService.configSettings.restCalendarioUrl}/calcircuito`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalTipoManiList(): Observable<CalTipoMani[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoMani>(`${this.dataService.configSettings.restCalendarioUrl}/caltipomani`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalStatoManiList(): Observable<CalStatoMani[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('livello', SortMode.Asc), new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalStatoMani>(`${this.dataService.configSettings.restCalendarioUrl}/calstatomani`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalPrvSpCaratteristicaList(): Observable<CalPrvSpCaratteristica[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalPrvSpCaratteristica>(`${this.dataService.configSettings.restCalendarioUrl}/calprvspcaratteristica`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalDefRaggruppamentiList(): Observable<CalDefRaggruppamenti[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['ordAbilitazioniGiud'], ComparisonOperator.IsNotNull)],
      [new DataSortValue('ordAbilitazioniGiud', SortMode.Asc)]
    );

    return this.getBaseData$<CalDefRaggruppamenti>(`${this.dataService.configSettings.restCalendarioUrl}/caldefraggruppamenti`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalDefTipiOperazioneList(): Observable<CalDefTipiOperazione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalDefTipiOperazione>(`${this.dataService.configSettings.restCalendarioUrl}/caldeftipioperazione`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalTipoGiudSupremeList(): Observable<CalTipoGiudSupreme[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoGiudSupreme>(`${this.dataService.configSettings.restCalendarioUrl}/caltipogiudsupreme`, ds).pipe(
      map(data => data.entities)
    );
  }

  getCalTipoAmbSvolgManiList(): Observable<CalTipoAmbSvolgMani[]> {
    const ds = new DataSearch(
      [new DataSearchValue([true], ['visible'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<CalTipoAmbSvolgMani>(`${this.dataService.configSettings.restCalendarioUrl}/caltipoambsvolgmani`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Geo
  getGeoNazioneList(): Observable<GeoNazione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('nazioneDes', SortMode.Asc)]
    );

    return this.getBaseData$<GeoNazione>(`${this.dataService.configSettings.restCommonUrl}/GetGeoNazioneList`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGeoRegioneList(): Observable<GeoRegione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('regioneDes', SortMode.Asc)]
    );

    return this.getBaseData$<GeoRegione>(`${this.dataService.configSettings.restCommonUrl}/GetGeoRegioneList`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGeoProvinciaList(): Observable<GeoProvincia[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('provinciaDes', SortMode.Asc)]
    );

    return this.getBaseData$<GeoProvincia>(`${this.dataService.configSettings.restCommonUrl}/GetGeoProvinciaList`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGeoComuneList(): Observable<GeoComune[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('comuneDes', SortMode.Asc)]
    );

    return this.getBaseData$<GeoComune>(`${this.dataService.configSettings.restCommonUrl}/GetGeoComuneList`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGeoComuneCapList(): Observable<GeoComuneCap[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('cap', SortMode.Asc)]
    );

    return this.getBaseData$<GeoComuneCap>(`${this.dataService.configSettings.restCommonUrl}/GetGeoComuneCapList`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Vux
  getVuxSociDeleGruppiList(): Observable<VuxSociDeleGruppi[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('ragioneSociale', SortMode.Asc)]
    );

    return this.getBaseData$<VuxSociDeleGruppi>(`${this.dataService.configSettings.restCommonUrl}/vuxsocidelegruppi`, ds).pipe(
      map(data => data.entities)
    );
  }

  getVuxSociDeleGruppiSocSpecList(): Observable<VuxSociDeleGruppi[]> {
    const ds = new DataSearch(
      [new DataSearchValue(['SOC_SPEC'], ['codTipo'], ComparisonOperator.Equals)],
      [new DataSortValue('ragioneSociale', SortMode.Asc)]
    );

    return this.getBaseData$<VuxSociDeleGruppi>(`${this.dataService.configSettings.restCommonUrl}/vuxsocidelegruppi`, ds).pipe(
      map(data => data.entities)
    );
  }

  getVuxLingueList(): Observable<VuxLingue[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<VuxLingue>(`${this.dataService.configSettings.restCommonUrl}/vuxlingue`, ds).pipe(
      map(data => data.entities)
    );
  }

  getVuxGiudiciTipiSospensioneList(): Observable<VuxGiudiciTipiSospensione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<VuxGiudiciTipiSospensione>(`${this.dataService.configSettings.restCommonUrl}/vuxgiudicitipisospensione`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Vee
  getVeeRazzeSpecialiList(): Observable<VeeRazze[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('razzaDes', SortMode.Asc)]
    );

    return this.getBaseData$<VeeRazze>(`${this.dataService.configSettings.restCommonUrl}/veerazzespeciali`, ds).pipe(
      map(data => data.entities)
    );
  }

  getVeeRazzeList(): Observable<VeeRazze[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('razzaDes', SortMode.Asc)]
    );

    return this.getBaseData$<VeeRazze>(`${this.dataService.configSettings.restCommonUrl}/veerazze`, ds).pipe(
      map(data => data.entities)
    );
  }

  getVeeRazzePrincipaliList(): Observable<VeeRazze[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('razzaDes', SortMode.Asc)]
    );

    return this.getBaseData$<VeeRazze>(`${this.dataService.configSettings.restCommonUrl}/veerazzeprincipali`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Gen
  getGenUnitaMisuraList(): Observable<GenUnitaMisura[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenUnitaMisura>(`${this.dataService.configSettings.restCommonUrl}/genunitamisura`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenAnomaliaList(): Observable<GenAnomalia[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenAnomalia>(`${this.dataService.configSettings.restCommonUrl}/genanomalia`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenAnomaliaStatoList(): Observable<GenAnomaliaStato[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenAnomaliaStato>(`${this.dataService.configSettings.restCommonUrl}/genanomaliastato`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenGruppoFciList(): Observable<GenGruppoFci[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('id', SortMode.Asc)]
    );

    return this.getBaseData$<GenGruppoFci>(`${this.dataService.configSettings.restCommonUrl}/gengruppofci`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenClasseList(): Observable<GenClasse[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenClasse>(`${this.dataService.configSettings.restCommonUrl}/genclasse`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenClasseExpList(): Observable<GenClasse[]> {
    const ds = new DataSearch(
      [new DataSearchValue(['EXP'], ['piattaforma'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenClasse>(`${this.dataService.configSettings.restCommonUrl}/genclasse`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenClassePrvList(): Observable<GenClasse[]> {
    const ds = new DataSearch(
      [new DataSearchValue(['PRV'], ['piattaforma'], ComparisonOperator.Equals)],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenClasse>(`${this.dataService.configSettings.restCommonUrl}/genclasse`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenQualificaList(): Observable<GenQualifica[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenQualifica>(`${this.dataService.configSettings.restCommonUrl}/genqualifica`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenQualificaClasseList(): Observable<GenQualificaClasse[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('classeId', SortMode.Asc)]
    );

    return this.getBaseData$<GenQualificaClasse>(`${this.dataService.configSettings.restCommonUrl}/genqualificaclasse`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenCartellinoList(): Observable<GenCartellino[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenCartellino>(`${this.dataService.configSettings.restCommonUrl}/gencartellino`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenRelazioneList(): Observable<GenRelazione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenRelazione>(`${this.dataService.configSettings.restCommonUrl}/genrelazione`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenLangList(): Observable<GenLang[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenLang>(`${this.dataService.configSettings.restCommonUrl}/genlang`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenTipoLogEventoList(): Observable<GenTipoLogEvento[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenTipoLogEvento>(`${this.dataService.configSettings.restCommonUrl}/gentipologevento`, ds).pipe(
      map(data => data.entities)
    );
  }

  getGenAmbitoList(): Observable<GenAmbito[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<GenAmbito>(`${this.dataService.configSettings.restCommonUrl}/gentipologevento`, ds).pipe(
      map(data => data.entities)
    );
  }

  // Security
  getSecProfiloList(): Observable<SecProfilo[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<SecProfilo>(`${this.dataService.configSettings.restCommonUrl}/secprofilo`, ds).pipe(
      map(data => data.entities)
    );
  }


  // Altro
  getMesiAnnoList(): Observable<{ value: number, des: string }[]> {
    const months = Info.months('long').map((m, i) => {
      return { value: i, des: capitalize(m) }
    });

    return of(months);
  }

  // Formazione giudicici
  getEduAnaSezioneList(): Observable<EduAnaSezione[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduAnaSezione>(`${this.dataService.configSettings.restCommonUrl}/eduanasezione`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduCicloList(): Observable<EduCiclo[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduCiclo>(`${this.dataService.configSettings.restCommonUrl}/educiclo`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduCicloAnnoList(): Observable<EduCicloAnno[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('anno', SortMode.Asc)]
    );

    return this.getBaseData$<EduCicloAnno>(`${this.dataService.configSettings.restCommonUrl}/educicloanno`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduCorsoCreditoList(): Observable<EduCorsoCredito[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('descrizione', SortMode.Asc)]
    );

    return this.getBaseData$<EduCorsoCredito>(`${this.dataService.configSettings.restCommonUrl}/educorsocredito`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduEventoList(): Observable<EduEvento[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('nome', SortMode.Asc)]
    );

    return this.getBaseData$<EduEvento>(`${this.dataService.configSettings.restCommonUrl}/eduevento`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduModalitaSvolgimentoList(): Observable<EduModalitaSvolgimento[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduModalitaSvolgimento>(`${this.dataService.configSettings.restCommonUrl}/edumodalitasvolgimento`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduOrganizzatoreList(): Observable<EduOrganizzatore[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('descrizione', SortMode.Asc)]
    );

    return this.getBaseData$<EduOrganizzatore>(`${this.dataService.configSettings.restCommonUrl}/eduorganizzatore`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduRuoloList(): Observable<EduRuolo[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduRuolo>(`${this.dataService.configSettings.restCommonUrl}/eduruolo`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduStatoList(): Observable<EduStato[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduStato>(`${this.dataService.configSettings.restCommonUrl}/edustato`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduStatoCorsistaList(): Observable<EduStatoCorsista[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduStatoCorsista>(`${this.dataService.configSettings.restCommonUrl}/edustatocorsista`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduTipoCorsoList(): Observable<EduTipoCorso[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduTipoCorso>(`${this.dataService.configSettings.restCommonUrl}/edutipocorso`, ds).pipe(
      map(data => data.entities)
    );
  }

  getEduTipoSuperamentoList(): Observable<EduTipoSuperamento[]> {
    const ds = new DataSearch(
      [],
      [new DataSortValue('des', SortMode.Asc)]
    );

    return this.getBaseData$<EduTipoSuperamento>(`${this.dataService.configSettings.restCommonUrl}/edutiposuperamento`, ds).pipe(
      map(data => data.entities)
    );
  }

  // getEduCorsoAnaSezioneList(): Observable<EduCorsoAnaSezione[]> {
  //   const ds = new DataSearch(
  //     [],
  //     [new DataSortValue('anaSezioneDes', SortMode.Asc)]
  //   );

  //   return this.getBaseData$<EduCorsoAnaSezione>(`${this.dataService.configSettings.restCommonUrl}/educorsoanasezione`, ds).pipe(
  //     map(data => data.entities)
  //   );
  // }

}
