import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CalTipoMani } from 'src/app/models/anagrafiche/calendario/cal-tipo-mani';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { GeoNazione } from 'src/app/models/geo/geo-nazione';
import { VuxGiudiciImpegniEsteri } from 'src/app/models/vux/vux-giudici-impegni-esteri';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { ListExportService } from 'src/app/services/list-export.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertSortMetaArrayToDataSortValueArray, dateFormat, dateFormatPrNg } from 'src/app/utils/util';

@Component({
  selector: 'app-giudice-detail-impegni-esteri',
  templateUrl: './giudice-detail-impegni-esteri.component.html',
  styleUrls: ['./giudice-detail-impegni-esteri.component.scss'],
  providers: [TitleCasePipe]
})
export class GiudiceDetailImpegniEsteriComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  entities$: Observable<VuxGiudiciImpegniEsteri[]>;
  dataForm: FormGroup;
  basePaginatorFirst: number = 0;
  basePaginatorRows: number = 10;
  paginatorFirst: number = this.basePaginatorFirst;
  paginatorRows: number = this.basePaginatorRows;
  dataSortValues: DataSortValue[];
  paginatorRowsTot: number;
  dataSearch: DataSearch;
  baseMultiSortMeta: SortMeta[] = [
    { field: 'nrImpegno', order: SortMode.Asc }
  ];
  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];
  elaboration: boolean = false;
  advancedSearch: boolean = false;
  titleCasePipe = new TitleCasePipe();
  calTipoManiList$: Observable<CalTipoMani[]>;
  geoNazioneList$: Observable<GeoNazione[]>;
  modalListExport: { visible: boolean, data: any } = { visible: false, data: undefined };
  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: false },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'nrImpegno,tipoManiDes,nazioneLabel,localita', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataInizio', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'dataFine', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'tipoManiId', searchFields: 'tipoManiId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'nazioneId', searchFields: 'nazioneId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'localita', searchFields: 'localita', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
  ];

  @ViewChild('dataTable') dataTable: Table;

  private subscription: Subscription;

  @Input() giudiceId: number;
  @Input() dataInizioLimite: Date;
  @Input() dataFineLimite: Date;

  @Input() tabIndex: number;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private listExportService: ListExportService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudiceDetailImpegniEsteri.translateSuffixs ?? ['generic.']);

    // Filtri
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

    this.dataForm = this.fb.group({
      giudiceId: this.giudiceId,
      dataInizioLimite: this.dataInizioLimite,
      dataInizio: null,
      dataFineLimite: this.dataFineLimite,
      dataFine: null,
      simpleSearch: null,
      tipoManiId: null,
      nazioneId: null,
      localita: null
    });

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.elaboration = true;

    this.entities$ = this.dataService.searchElements<VuxGiudiciImpegniEsteri>(`${this.dataService.configSettings.restCommonUrl}/vuxgiudiciimpegniesteri`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });

    // Carico le liste per la ricerca avanzata
    if (this.advancedSearch) {
      // Tipi mani
      if (!this.calTipoManiList$) {
        this.calTipoManiList$ = this.baseDataService.getCalTipoManiList();
      }
      // Nazioni
      if (!this.geoNazioneList$) {
        this.geoNazioneList$ = this.baseDataService.getGeoNazioneList();
      }
    }
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.dataForm.get(controlName)?.reset(defaultValue);
  }

  modalListExportShow(): void {
    this.listExportService.openModal('VuxGiudiciImpegniEsteri', this.dataSearch, 'Vux');
  }

  modalListExportHide(): void {
    this.modalListExport.data = undefined;
    this.modalListExport.visible = false;
  }
}
