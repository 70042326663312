import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppManagerService } from '../services/app-manager.service';
import { buildFromResultMessage, MessageLevel } from '../models/message';


@Injectable()
export class ResultResponseInterceptor implements HttpInterceptor {

  constructor(
    private appManagerService: AppManagerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map(
          (httpEvent) => {
            return httpEvent;
          }
        ),
        catchError((errorResponse: HttpErrorResponse) => {
          const messageData = buildFromResultMessage(errorResponse.error, MessageLevel.Error);
          this.appManagerService.showMessage(messageData);

          // http error handled(message shown) globally by interceptor
          // so set error to null
          //return throwError(null);
          return throwError(errorResponse);
        })
      );
  }

}
