import { Entity } from "../entity";

export class GenNota extends Entity {
  ambitoId: number;
  extId: number;
  notaTipoId: number;
  oggetto: string;
  nota: string

  notaTipoCod: string;
  notaTipoDes: string;
  extTableName: string;
  userInsEmailDes: string;
}