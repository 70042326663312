import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { VuxGiudiciSospensioni } from 'src/app/models/vux/vux-giudici-sospensioni';
import { VuxGiudiciTipiSospensione } from 'src/app/models/vux/vux-giudici-tipi-sospensione';
import { BaseDataService } from 'src/app/services/base-data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertSortMetaArrayToDataSortValueArray, dateFormat, dateFormatPrNg } from 'src/app/utils/util';
import { DataService } from '../../../../../services/data.service';

@Component({
  selector: 'app-giudice-detail-sospensioni',
  templateUrl: './giudice-detail-sospensioni.component.html',
  styleUrls: ['./giudice-detail-sospensioni.component.scss'],
  providers: [TitleCasePipe]
})
export class GiudiceDetailSospensioniComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  entities$: Observable<VuxGiudiciSospensioni[]>;
  dataForm: FormGroup;
  basePaginatorFirst: number = 0;
  basePaginatorRows: number = 10;
  paginatorFirst: number = this.basePaginatorFirst;
  paginatorRows: number = this.basePaginatorRows;
  dataSortValues: DataSortValue[];
  paginatorRowsTot: number;
  dataSearch: DataSearch;
  baseMultiSortMeta: SortMeta[] = [
    { field: 'dataInizioSospensione', order: SortMode.Asc },
    { field: 'dataFineSospensione', order: SortMode.Asc },
    { field: 'tipoSospensioneDes', order: SortMode.Asc }
  ];
  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];
  vuxGiudiciTipiSospensioneList$: Observable<VuxGiudiciTipiSospensione[]>;
  elaboration: boolean = false;
  advancedSearch: boolean = false;
  titleCasePipe = new TitleCasePipe();
  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'tipoSospensioneDes,note', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'dataInizio', searchFields: 'dataInizioSospensione', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataFine', searchFields: 'dataFineSospensione', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'codTipoSospensione', searchFields: 'codTipoSospensione', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'flSospensioneIncorso', searchFields: 'flSospensioneIncorso', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'note', searchFields: 'note', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true }
  ];

  @ViewChild('dataTable') dataTable: Table;

  private subscription: Subscription;

  @Input() giudiceId: number;
  @Input() tabIndex: number;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudiceDetailSospensioni.translateSuffixs ?? ['generic.']);

    // Filtri
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

    this.dataForm = this.fb.group({
      giudiceId: this.giudiceId,
      simpleSearch: null,
      dataInizio: null,
      dataFine: null,
      codTipoSospensione: null,
      flSospensioneIncorso: false,
      note: null
    });


    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.elaboration = true;

    this.entities$ = this.dataService.searchElements<VuxGiudiciSospensioni>(`${this.dataService.configSettings.restCommonUrl}/vuxgiudicisospensioni`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ giudiceId: this.giudiceId });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.dataForm.reset({ giudiceId: this.giudiceId });

    // Carico le liste per la ricerca avanzata
    if (this.advancedSearch) {
      // Motivi sospensione
      if (!this.vuxGiudiciTipiSospensioneList$) {
        this.vuxGiudiciTipiSospensioneList$ = this.baseDataService.getVuxGiudiciTipiSospensioneList().pipe(
          map(value => value.map(v => {
            v.des = this.titleCasePipe.transform(v.des);
            return v;
          }))
        );
      }
    }
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.dataForm.get(controlName)?.reset(defaultValue);
  }
}
