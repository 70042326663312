import { AbstractControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SortMeta } from "primeng/api";
import { TipoCalCod } from "../models/anagrafiche/calendario/cal-tipo-cal";
import { TipoStampaManiCod } from "../models/anagrafiche/calendario/cal-tipo-stampa-mani";
import { DataSortValue } from "../models/data-search";
import { EntityManagerGrant, FieldGrant, checkGrant } from "../models/entity-config";

export const dateFormat = "dd/MM/yyyy";
export const dateFormatPrNg = "dd/mm/yy";
export const dateTimeFormat = "dd/MM/yyyy HH:mm";
export const dateTimeFormatWithSec = "dd/MM/yyyy HH:mm:ss";

export const mailregex = /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export const dateRegex = /^(0[1-9]|[12][0-9]|3[01]|)(\/|-)(0[1-9]|1[1,2])(\/|-)(19|20)\d{2}|(19|20)\d{2}(\/|-)(0[1-9]|1[1,2])(\/|-)(0[1-9]|[12][0-9]|3[01]|)/;

export const pwStrengthRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;

export const stampeNecessaitanoGiuria: string[] = [
  TipoStampaManiCod.DistanzeKmGiudiciEsposizione,
  TipoStampaManiCod.DistanzeKmGiudiciProva,
  TipoStampaManiCod.RatificaGiudiciEsposizione,
  TipoStampaManiCod.RatificaGiudiciProva
];

export const gestioneFinalizzazioneList: any[] = [
  {
    cod: 'I',
    label: 'generic.gestionefinalizzazione.i',
    display: "",
    disabled: false
  },
  {
    cod: 'E',
    label: 'generic.gestionefinalizzazione.e',
    display: "",
    disabled: false
  }
];

export const tipoRadunoList: any[] = [
  {
    cod: 'STD',
    label: 'generic.tipoRadunoList.std',
    display: "",
    disabled: false
  },
  {
    cod: 'ITA',//RAZZE ITALIANE
    label: 'generic.tipoRadunoList.ita', 
    display: "",
    disabled: false
  },
  {
    cod: 'RSA',//RSA
    label: 'generic.tipoRadunoList.rsa', 
    display: "",
    disabled: false
  }
];

export const esenzioneList: any[] = [
  {
    cod: 'NO',
    label: 'generic.esenzionelist.no',
    display: "",
    disabled: false
  },
  {
    cod: 'PSA',//RAZZE ITALIANE
    label: 'generic.esenzionelist.psa',
    display: "",
    disabled: false
  }
];

export function isNullOrUndefined<T>(val?: T | undefined | null): boolean {
  return (val === null || val === undefined);
}

export function isValidValue<T>(val?: T | undefined | null): boolean {
  return (val === undefined || val === null) ? false : (typeof val === 'string' ? (val.length === 0 ? false : true) : (typeof val === 'number' ? (val === 0 ? false : true) : true));
}

/**
 * Compare two value of type string, number or Date
 * and return a result base on the value of parameter isAsc
 *
 * @param a first value
 * @param b second value
 * @param isAsc true if compare a vs b; false if compare b vs a
 */
export function compare(val1: number | string | Date, val2: number | string | Date, sortMode: number): number {
  if (val1 instanceof Date && val2 instanceof Date) {
    return (val1.getTime() < val2.getTime() ? -1 : (val1.getTime() === val2.getTime() ? 0 : 1)) * sortMode;
  }

  if (typeof val1 === 'string' && typeof val2 === 'string') {
    return val1.localeCompare(val2) * sortMode;
  }

  return (val1 < val2 ? -1 : (val1 === val2 ? 0 : 1)) * sortMode;
}

/**
 *  Get enum items as a list of label/value used in select/option field
 */
export function getEnumLabelValueList(pEnum: any, except: any = undefined): any[] {
  let result: any[] = [];

  var regex = new RegExp('^[^0-9]+$'); // '^\\w+$'
  Object.keys(pEnum).filter(key => key.match(regex)).forEach(key => {
    if (except == undefined || except != pEnum[key]) {
      let elem = { label: key, value: pEnum[key] };
      result.push(elem);
    }
  });

  return result;
}

/**
 * Get description of item searched by value
 *
 * @param value id of element to search for
 */
export function lookupEnum(pEnum: any, pValue: number | string): string {
  let result: string;

  const keys = Object.keys(pEnum);

  let key: string;
  for (let i = 0; i < keys.length; i++) {
    key = keys[i];
    if (pEnum[key] === pValue) {
      result = key;
      break;
    }
  }


  // Object.keys(pEnum).forEach(key => {
  //   if (pEnum[key] === pValue) {
  //     result = key;
  //   }
  // });

  return result;
}

/**
 *
 * @param list list of items
 * @param pFieldCode name of the field that is the code of the item
 * @param pValueCode value of the code field to match
 * @param pFieldShow name of the field of the item to show
 */
export function getListDescription(list: any[], pFieldCode: string, pValueCode: number | string, pFieldShow: string): string | undefined {
  if (isNullOrUndefined(list) || isNullOrUndefined(pFieldCode) || isNullOrUndefined(pValueCode) || isNullOrUndefined(pFieldShow))
    return undefined;

  let item: any = list.find(p => p[pFieldCode] === pValueCode);
  if (isNullOrUndefined(item))
    return undefined;

  return item[pFieldShow];
}

/**
 * Convert data as base64 string to blob
 *
 * @param strBase64 base64 string data
 * @param contentType file content type
 */
export function base64ToBlob(strBase64: string, contentType: string = ''): Blob {

  var byteCharacters = atob(strBase64);
  var byteArrays = [];

  let sliceSize = 512;
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    let byteArray;

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

/**
 * Exchange the value of "field" between the item "data1"
 * and the previous or next itme of the array "data"
 *
 * @param data array of items
 * @param data1 selected item to move up or down
 * @param field item comparison field
 * @param mode up or down
 */
export function moveUpDown(data: any[], data1: any, field: string, mode: number): any | undefined {
  if (isNullOrUndefined(data1)) return;

  // clone list of entities and make ascending sort
  let tmpData = [...data];
  tmpData.sort((data1, data2) => {
    return compare(data1[field], data2[field], 1);
  });

  // get entity selected index in the ordered list of entities
  let index = tmpData.findIndex(t => t.id === data1.id);

  // get previous entity
  let data2;

  if (mode === 1) {
    if (index < 1)
      return undefined;

    data2 = tmpData[index - 1];
  }
  else if (mode === -1) {
    if (index >= (tmpData.length - 1))
      return undefined;

    data2 = tmpData[index + 1];
  }
  else return undefined;

  // exchange the value of field between two entities
  let tmpVal = data2[field];
  data2[field] = data1[field];
  data1[field] = tmpVal;

  return { data1: data1, data2: data2 };
}

export const optionsSINO = [{ key: false, label: 'generic.no' }, { key: true, label: 'generic.si' }];
export const options10 = [{ key: 0, label: 'generic.no' }, { key: 1, label: 'generic.si' }];

// // Loads translations on *.ts files
// export function streamTranslate(translateService: TranslateService, keys: string | Array<string>, params?: any): Observable<any> {
//   return translateService.onLangChange.pipe(startWith({}), switchMap(() =>
//     params ? translateService.get(keys, params) : translateService.get(keys))
//   );
// }

/**
   * Merge properties in objSouce into object objDest
   * @param objDest
   * * @param objSouce
   */
export function mergeObject(objDest: any, objSouce: any, overwrite: boolean = true) {
  if (overwrite) {
    Object.keys(objSouce).forEach(key => objDest[key] = objSouce[key]);
  }
  else {
    Object.keys(objSouce).forEach(key => {
      if (isNullOrUndefined(objDest[key])) {
        objDest[key] = objSouce[key];
      }
    });
  }
}

/**
   * Return new object that is a clone of objSource
   * optionally with values copied from objUpd
   * @param objUpd
   */
export function cloneObject(objSource: any, objUpd?: any): any {
  const result = {};
  Object.keys(objSource).forEach(key => result[key] = objSource[key]);
  Object.keys(objUpd).forEach(key => result[key] = objUpd[key]);
  return result;
}

/**
 * Return an object that binds all the values of properties in fieldsBind.
 *
 * @param data source object
 * @param fieldsBind object written as key: value
 * where 'key' are the name of the field of detination object
 * and 'fieldsBind[key]' is the name of the field of source object
 * @returns
 */
export function dataBind(data: any, fieldsBind: any): any {
  let result = {};
  Object.keys(fieldsBind).forEach(key => result[fieldsBind[key]] = data[key]);
  if (Object.keys(result).length == 0) {
    return null;
  }
  if (Object.values(result).filter(val => !isNullOrUndefined(val)).length == 0) {
    return null;
  }
  return result;
}

/**
 * Return an object that binds all the values of properties in fieldsBind
 * in a reverse way than function'dataBind'.
 * If data is an array, the bind result for each fieldsBind
 * is a concatenation of that field value with character ','(comma) as separator
 *
 * @param data source object
 * @param fieldsBind object written as key: value
 * where 'fieldsBind[key]' are the name of the field of detination object
 * and 'key' is the name of the field of source object
 * @returns
 */
export function dataBindReverse(data: any | any[], fieldsBind: any): any {
  let result = {};
  if (data instanceof Array) {
    Object.keys(fieldsBind).forEach(key => result[key] = data.map(item => item[fieldsBind[key]]).join(','));
  }
  else {
    Object.keys(fieldsBind).forEach(key => result[key] = data[fieldsBind[key]]);
  }
  if (Object.keys(result).length == 0) {
    return null;
  }
  if (Object.values(result).filter(val => !isNullOrUndefined(val)).length == 0) {
    return null;
  }
  return result;
}


/**
   * Convert each field of type Date from string to Date object.
   * To perform a corret conversion the string value must be in UTC.
   * The result of conversion is a new Date object with the local TimeZone.
   *
   * @param data object to convert date fields
   * @param dateFields list of fields of type Date
   * @returns new object
   */
export function convertDateFieldToLocalDate(data: any, dateFields: string[]): any {
  const result = { ...data };
  dateFields.forEach(field => {
    if (result[field]) {
      result[field] = new Date(result[field]);
    }
  });
  return result;
}

/**
* Add the Timezone offset to the Date object
*
* @param date
* @returns
*/
export function addTimezoneOffsetToDate(date: Date): Date {
  const offset = date.getTimezoneOffset() / 60;
  date.setHours(-offset);
  return date;
}

/**
 * Convert each field of type Date from string to Date object.
 * To perform a corret conversion the string value must be in UTC.
 * The result of conversion is a new Date object with the local TimeZone.
 *
 * @param data list of entities to convert date fields
 * @param dateFields list of fields of type Date
 * @returns new array of entities
 */
export function convertDateFieldToLocalDateArray(data: any[], dateFields: string[]): any[] {
  const result = [...data];
  result.forEach(entity => {
    dateFields.forEach(field => {
      if (entity[field]) {
        entity[field] = new Date(entity[field]);
      }
    })
  });
  return result;
}

export function buildDataFromActivatedRoute(activatedRoute: ActivatedRoute): any {
  let result = undefined;
  let keys = Object.keys(activatedRoute.snapshot.params);
  if (keys.length > 0) {
    result = result ?? {};
    keys.forEach(key => {
      if (!isNullOrUndefined(activatedRoute.snapshot.params[key])) {
        result[key] = activatedRoute.snapshot.params[key];
      }
    });
  }
  keys = Object.keys(activatedRoute.snapshot.queryParams);
  if (keys.length > 0) {
    result = result ?? {};
    keys.forEach(key => {
      if (!isNullOrUndefined(activatedRoute.snapshot.queryParams[key])) {
        result[key] = activatedRoute.snapshot.queryParams[key];
      }
    });
  }
  return result;
}


export function buildQueryStringFromParams(params: { [k: string]: any; }): string {
  let keys = Object.keys(params);
  if (keys.length == 0) return null;

  return keys.filter(key => !isNullOrUndefined(params[key]))
    .map((t, index) => `${t}=${params[t]}`)
    .join("&");
}

export function convertDataSortValueArrayToSortMetaArray(dataSortValue: DataSortValue[]): SortMeta[] {
  if (!dataSortValue) return undefined;
  return dataSortValue.map((e) => { return { field: e.field, order: e.sortMode } });
}

export function convertSortMetaArrayToDataSortValueArray(sortMeta: SortMeta[]): DataSortValue[] {
  if (!sortMeta) return undefined;
  return sortMeta.map((e) => { return new DataSortValue(e.field, e.order) });
}


/**
 * Calculate the days of an event based on the start date.
 *
 * @param startDate start date of the event
 * @param durationDays duration of the event in days
 * @param locale the locale for convert the dates, if not provided is used the locale of the browser
 * @Param options for the date format, predefined for return the date in DD/MM/YYY
 * @returns new array of days
 */
export function getEventDays(startDate: Date, durationDays: number, options: {} = { day: '2-digit', month: '2-digit', year: 'numeric' }, locale?: string): {
  giorno: number, data: Date, label: string
}[] {
  const days = [];
  for (let i = 1; i <= durationDays; i++) {
    const date = new Date(new Date(startDate).setDate(startDate.getDate() + i - 1));

    days.push({
      giorno: i, data: date, label: `${i} - (${date.toLocaleDateString(locale, options)})`
    });
  }
  return days;
}


/**
 * Calculate the days of an event based on the start date.
 *
 * @param startDate start date of the event
 * @param durationDays duration of the event in days
 * @param locale the locale for convert the dates, if not provided is used the locale of the browser
 * @Param options for the date format, predefined for return the date in DD/MM/YYY
 * @returns new array of days
 */
export function getDaysArray(startDate: Date, endDate: Date): Date[] {
  const days = [];

  for (const dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
    days.push(new Date(dt));
  }

  return days;
}


/**
 * Returns if a date interval is not contains a blocked date.
 *
 * @param blockedDates arry of the blocked dates
 * @param startDate start date of the interval
 * @param endDate end date of the interval
 * @returns a boolean that indicates if the date inrterval not contains a blocked date
 */
export function isAllowedInterval(blockedDates: Date[], startDate: Date, endDate: Date): boolean {
  if (!startDate || !endDate) {
    return true;
  }

  const blockedDts = blockedDates.map(d => d.getTime());
  const intervalDts = [startDate.getTime()];
  let date = new Date(startDate);

  while (date < endDate) {
    date = new Date(date.setDate(date.getDate() + 1));
    intervalDts.push(date.getTime());
  }

  return intervalDts.every(d => !blockedDts.includes(d));
}

/**
 * Returns if a date is not contains a blocked date.
 *
 * @param blockedDates arry of the blocked dates
 * @param date date to check
 * @returns a boolean that indicates if the date is not a blocked date
 */
export function isAllowedDate(blockedDates: Date[], date: Date): boolean {
  if (!date) {
    return true;
  }
  const blockedDts = blockedDates.map(d => d.getTime());
  return !blockedDts.includes(date.getTime());
}

/**
 * Returns the first allowed date.
 *
 * @param blockedDates arry of the blocked dates
 * @param startDate start date of the interval
 * @param endDate end date of the interval
 * @returns a date not contained in the blocked dates
 */
export function getNextAllowedDate(blockedDates: Date[], startDate: Date = blockedDates[0], endDate: Date = blockedDates[blockedDates.length - 1]): Date | null {

  if (!startDate && !endDate) {
    return null;
  }

  const blockedDts = blockedDates.map(d => d.getTime());
  let date = new Date(startDate);

  while (blockedDts.includes(date.getTime()) && date < endDate) {
    date = new Date(date.setDate(date.getDate() + 1));
  }

  return blockedDts.includes(date.getTime()) ? null : date;
}

export type TabViewConf = { key: string, tipoCal?: TipoCalCod };

export function filterTabsViewConf(tabList: TabViewConf[], grants: EntityManagerGrant, tipoCal?: TipoCalCod, keys?: string[]): TabViewConf[] {
  return tabList.filter(t => (!tipoCal || t?.tipoCal === tipoCal) && checkGrant(grants, t.key, FieldGrant.read, true));
}

export function interpolateTranslation(text: string, data: Object): string {
  let result = text ?? '';
  if (data) {
    Object.keys(data).forEach(key => {
      result = result.replace(`{{${key}}}`, data[key])
    });
  }
  return result;
}

export function disableFieldConditionally(form: FormGroup, selector: string | string[], condition: boolean): void {
  if (condition) {
    form.get(selector).disable();
  }
  else {
    form.get(selector).enable();
  }
}

export function enableFieldConditionally(form: FormGroup, selector: string | string[], condition: boolean): void {
  disableFieldConditionally(form, selector, !condition);
}

export function setValueConditionally(form: FormGroup, selector: string | string[], condition: boolean, trueData: object, falseData?: object, emitEvent?: boolean): void {
  if (condition) {
    form.get(selector).setValue(trueData, { emitEvent: emitEvent ? true : false });
  }
  else if (falseData) {
    form.get(selector).setValue(falseData, { emitEvent: emitEvent ? true : false });
  }
}

export function patchValueConditionally(form: FormGroup, condition: boolean, trueData: object, falseData?: object, emitEvent?: boolean): void {
  if (condition) {
    form.patchValue(trueData, { emitEvent: emitEvent ? true : false });
  }
  else if (falseData) {
    form.patchValue(falseData, { emitEvent: emitEvent ? true : false });
  }
}

export function valueIsDate(val: string): boolean {
  return (dateRegex.test(val) && !(isNaN(new Date(val).getTime()) || isNaN(parseInt(val))));
}

// export function valueIsDate(val: any) {
//   if (typeof val != 'string' || val.includes('.')) {
//     return null;
//   }

//   const dt = new Date(val);

//   if (isNaN(dt.getTime()) || isNaN(parseInt(val))) {
//     return null;
//   }

//   return dt;
// }

export function resetSearchFieldConditionally(data: unknown[], control: AbstractControl, dataKey: string = 'id'): void {
  const val = control.value;
  const list = data.map(v => v[dataKey]);

  if ((Array.isArray(val) && val.reduce((exists, value) => exists = list.includes(value) ? exists : true, false)) || (!Array.isArray(val) && !list.includes(val))) {
    control.reset();
  }
}

export function openBase64File(data: string, fileName: string): void {
  const file = base64ToBlob(data);
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function replaceKeys(str: string, args: object) {
  if (!args) {
    return str;
  }

  Object.keys(args).forEach(key => {
    str = str.replace(`{{${key}}}`, valueIsDate(args[key]) ? new Date(args[key])?.toLocaleDateString() : args[key]);
  });

  return str;
}

export function getInsertPositionAlphabtical(valuesDes: string[], itemToAddDes: string): number {
  const values = valuesDes.map(v => { return { des: v, new: false }; });
  values.push({ des: itemToAddDes, new: true });

  return values.sort((a, b) => a.des.localeCompare(b.des)).findIndex(g => g.new);
}

export function parseEmailAddress(addressWithLabel: string): string {
  let temp = addressWithLabel.match(mailregex);
  return temp?.[0];
  //const regex = /(?<= <)(.+@.+\..+)(?=>)/i;
  //if (!regex.test(addressWithLabel)) {
  //  return addressWithLabel;
  //}
  //return addressWithLabel.match(regex)[0];
}

export function buildEmailAddressWithLabel(address: string, label?: string): string {
  return label ? `${label} <${address}>` : address
}

export type NazionalitaGiudici = 'ITA' | 'STR';

export const codManiAmmessiAspiranti: string[] = ['ESRE', 'ATNA'];

export function arrayEqualsProp(a: unknown[], b: unknown[], propName: string): boolean {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val[propName] == b[index][propName]);
}

export function convertDate(date: Date, separator: string = ''): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}${separator}${pad(month.toString())}${separator}${pad(day.toString())}`
}

export function pad(val: string): string {
  return val.length > 1 ? val : '0' + val;
}

