import { AppManagerRoutes } from "../app-manager-routes";
import { CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, Icons } from "./entity-config";

export class HomeEMC extends EntityManagerConfig {

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Security,
        label: 'menu.security.homecardmenu.label', title: 'menu.security.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.security
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Anagrafiche,
        label: 'menu.anagrafiche.homecardmenu.label', title: 'menu.anagrafiche.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.data
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Calendario,
        label: 'menu.calendario.homecardmenu.label', title: 'menu.calendario.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.calendar
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Normalizzazione,
        label: 'menu.normalizzazione.homecardmenu.label', title: 'menu.normalizzazione.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.user
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Giudici,
        label: 'menu.giudici.homecardmenu.label', title: 'menu.giudici.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.users
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudiceProfilo,
        label: 'menu.giudiceprofilo.homecardmenu.label', title: 'menu.giudiceprofilo.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.user
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.AnaClub,
        label: 'menu.anaclub.homecardmenu.label', title: 'menu.anaclub.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.globe
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Zeta,
        label: 'menu.zeta.homecardmenu.label', title: 'menu.zeta.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.data
      },
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.Manuali,
        label: 'menu.manuali.homecardmenu.label', title: 'menu.manuali.title',
        class: CmdFieldStyle.btnPrimary, icon: Icons.book
      },
    ];
  }
}

export class EMPTYEMC extends EntityManagerConfig {
  initFields() {
    this.cmdFields = [];
  }
}
