import { Entity } from "../entity";

export class GenAllegato extends Entity {
  ambitoId: number;
  extId: number;
  allegatoTipoId: number;
  nomeFile: string;
  estensione: string;
  sottocartella?: string;
  nota: string
  flNonVisibile: boolean;

  allegatoTipoCod: string;
  allegatoTipoDes: string;
  extTableName: string;

  attachment: string;
}
