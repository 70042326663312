import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActionEvent, ActionEventType } from 'src/app/models/action-event';
import { GenNota } from 'src/app/models/anagrafiche/gen-nota';
import { GenNotaTipo } from 'src/app/models/anagrafiche/gen-nota-tipo';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { IEntityList } from 'src/app/models/entity';
import { EntityManager, EntityManagerGrant } from 'src/app/models/entity-config';
import { MessageDataConfig, MessageDataFromConfig, ToastMessageData } from 'src/app/models/message';
import { SecUtente } from 'src/app/models/security/sec-utente';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { GenLogService } from 'src/app/services/genlog.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-gen-nota-list',
  templateUrl: './gen-nota-list.component.html',
  styleUrls: ['./gen-nota-list.component.scss']
})

export class GenNotaListComponent implements OnInit {
  dateFormat: string = dateFormat;
  T$: Observable<Resources>;
  noteList$: Observable<AbstractControl[]>;
  tipiNoteList$: Observable<GenNotaTipo[]>;
  dataForm: FormGroup;
  elaboration: boolean = false;
  subscription: Subscription;
  resourceList: Resources;
  utente: SecUtente;
  loadingSpinnerVisible: boolean = true;

  get noteListFa(): FormArray {
    return this.dataForm.get('noteList') as FormArray;
  }

  get noteListControls(): AbstractControl[] {
    return this.noteListFa.controls as AbstractControl[];
  }

  @Input() extTableName: string;
  @Input() extId: number;
  @Input() entityManager: EntityManager;
  @Input() tabIndex: number;
  @Input() grants: EntityManagerGrant;
  @Input() readOnly: boolean = false;

  @Output() isCompleted = new EventEmitter<boolean>();
  @Output() actionEvent = new EventEmitter<ActionEvent>();

  constructor(
    private appManagerService: AppManagerService,
    private dataService: DataService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private triggersService: TriggersService,
    private logService: GenLogService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.T$ = this.translateService.translatedItems$(['generic.', 'gennote.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Utente
    this.subscription.add(
      this.appManagerService.utente$.subscribe(
        (utente) => this.utente = utente
      )
    );

    // Costruisco il form
    this.dataForm = this.fb.group({
      noteList: this.fb.array([])
    });

    this.subscription.add(
      this.dataForm.valueChanges.subscribe(
        (data: any) => {
          this.emitIsCompleted();
        }
      )
    );

    // Carico i dati
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }

  }

  private loadData(): void {
    this.loadingSpinnerVisible = true;

    // Carico le note
    const ds = new DataSearch();
    ds.dataSortValues = [new DataSortValue('dtIns', SortMode.Asc)];
    ds.dataSearchValues = [
      new DataSearchValue([this.extTableName], ['extTableName'], ComparisonOperator.Equals),
      new DataSearchValue([this.extId.toString()], ['extId'], ComparisonOperator.Equals)
    ];

    this.noteList$ = this.dataService.searchElements<GenNota>(`${this.dataService.configSettings.restCommonUrl}/gennota`, ds).pipe(
      map(data => {
        this.noteListFa.clear();
        data.entities.forEach(this.addNotaFg, this);
        return this.noteListControls;
      }),
      tap(() => this.loadingSpinnerVisible = false)
    );

    // Carico i tipi di note
    this.tipiNoteList$ = this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/gennotatipobyexttablename`, `extTablename=${this.extTableName}`).pipe(
      map(data => data.entities)
    );
  }

  private buildNotaFg(nota: GenNota): FormGroup {
    return this.fb.group({
      id: nota.id,
      ambitoId: [nota.ambitoId, Validators.required],
      extId: [nota.extId, Validators.required],
      notaTipoId: [nota.notaTipoId, Validators.required],
      oggetto: nota.oggetto,
      nota: [nota.nota, Validators.required],
      userIns: nota.userIns,
      dtIns: nota.dtIns,
      userUpd: nota.userUpd,
      dtUpd: nota.dtUpd,
      notaTipoDes: nota.notaTipoDes,
      extTableName: [nota.extTableName, Validators.required],
      userInsEmailDes: nota.userInsEmailDes
    });
  }

  private addNotaFg(nota: GenNota): void {
    this.noteListFa.push(this.buildNotaFg(nota));
  }

  newNota(): void {
    const nota = new GenNota();
    nota.id = 0;
    nota.ambitoId = 0;
    nota.extId = this.extId;
    nota.extTableName = this.extTableName;
    nota.oggetto = '';
    nota.userInsEmailDes = this.utente.email;

    this.noteListFa.insert(0, this.buildNotaFg(nota));
  }

  save() {
    this.elaboration = true;
    const data = this.noteListFa.value as GenNota[];

    this.subscription.add(
      this.dataService.upsertElements<GenNota>(`${this.dataService.configSettings.restCommonUrl}/gennotaus`, data).subscribe({
        next: (data: IEntityList<GenNota>) => {
          this.noteListFa.clear();
          data.entities.forEach(this.addNotaFg, this)

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.elaboration = false;
          this.dataForm.markAsPristine();

          this.emitIsCompleted();
          this.emitSaveCompleted(true);

        },
        error: () => {
          this.elaboration = false;
          this.emitSaveCompleted(false);
        }
      })
    );
  }

  modalEntityLogShow(id: number): void {
    this.logService.openModalEntityLog('GenNota', id);
  }

  deleteNota(i: number): void {
    const messageData = new MessageDataFromConfig(MessageDataConfig.Delete, () => {
      this.deleteNotaInner(i);
    });

    this.appManagerService.showMessage(messageData);
  }

  private deleteNotaInner(i: number): void {
    const nota = this.noteListFa.at(i).value;
    if (nota.id) {
      this.elaboration = true;

      this.subscription.add(
        this.dataService.deleteElement<GenNota>(`${this.dataService.configSettings.restCommonUrl}/gennota`, nota.id).subscribe({
          next: (data: number) => {
            this.elaboration = false;
            if (data > 0) {
              this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.deletesuccessmsg')));

              this.noteListFa.removeAt(i);
              this.emitIsCompleted();
              this.emitDeleteEvent(true);
            }
          },
          error: () => {
            this.elaboration = false;

            this.emitDeleteEvent(false);
          }
        })
      );
    } else {
      this.noteListFa.removeAt(i);
    }
  }

  private emitSaveCompleted(saveCompleted: boolean, data?: any): void {
    this.actionEvent.emit({
      action: ActionEventType.save,
      done: saveCompleted,
      data: data
    });
  }

  private emitDeleteEvent(deleteCompleted: boolean, data?: any): void {
    this.actionEvent.emit({
      action: ActionEventType.delete,
      done: deleteCompleted,
      data: data
    });
  }

  private emitIsCompleted(): void {
    this.isCompleted.emit(this.isCompletedCheck());
  }

  private isCompletedCheck() {
    return this.dataForm.pristine;
  }

}
