<ng-template #pinMenuControl>
  <p-divider></p-divider>
  <div class="flex flex-row justify-content-end">
    <i [title]="translateItems | translateItem: menuSticky ? 'generic.disablestickymenu':'generic.enablestickymenu'"
      class="fa-solid fa-thumbtack mx-3 mt-1 mb-1 cursor-pointer"
      [ngClass]="{'text-primary':menuSticky,'text-600':!menuSticky,'rotate-45':menuSticky}"
      (click)="toggleMenuSticky($event)"></i>
  </div>
</ng-template>

<ng-container *ngIf="translatedItems$ | async as translateItems">
  <div *ngIf="menuSticky;else menuSidebar"
    class="ml-0 md:mr-3 hidden md:flex flex-column h-full justify-content-between bg-white p-3 h-full round-border shadow">
    <div class=" flex-grow-1 overflow-y-auto h-full">
      <p-panelMenu [transitionOptions]="'0ms'" [model]="menuItems"></p-panelMenu>
    </div>
    <ng-template *ngIf="!isXsmall" [ngTemplateOutlet]="pinMenuControl"></ng-template>
  </div>

  <ng-template #menuSidebar>
    <p-sidebar [(visible)]="menuExpanded" [modal]="false" [dismissible]="true" [closeOnEscape]="true"
      [showCloseIcon]="false">
      <div class="flex flex-column h-full justify-content-between ">
        <div class="flex-grow-1 overflow-y-auto h-full">
          <p-panelMenu [transitionOptions]="'0ms'" [model]="menuItems"></p-panelMenu>
        </div>
        <ng-template *ngIf="!isXsmall" [ngTemplateOutlet]="pinMenuControl"></ng-template>
      </div>
    </p-sidebar>
  </ng-template>
</ng-container>