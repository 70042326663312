/**
 * Enumerator of keys of action events
 */
export enum ActionEventType {
  save,
  delete,
  load
}

export type ActionEvent = {
  action: ActionEventType,
  done: boolean,
  data?: any
};
