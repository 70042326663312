import { AppManagerRoutes } from "../../app-manager-routes";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldTarget, EntityManagerConfig, } from "../entity-config";

export class GiudiciEMC extends EntityManagerConfig {
  cmdFields: CmdField[];
  id: string;

  initFields() {
    this.cmdFields = [
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudiciList, label: 'menu.giudicilist.label', title: 'menu.giudicilist.title'
      },

      // Corsi
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.EduCorsoList, label: 'menu.eduCorsoList.label', title: 'menu.eduCorsoList.title'
      },

      // Singolo giudice
      {
        key: CmdFieldKey.navigate, mode: CmdFieldMode.btn, target: CmdFieldTarget.menu, entityManagerInfo: AppManagerRoutes.GiudGiudiceDetail, label: 'menu.giudGiudiceDetail.label', title: 'menu.giudGiudiceDetail.title'
      },
    ];
  }
}


