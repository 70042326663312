<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm">
    <!-- Toolbar -->
    <div *ngIf="!readOnly" class="flex flex-row justify-content-end pb-2 sticky bg-white top-0 z-2">
      <div class="buttons-cnt">
        <button pButton type="button" class="p-button-sm" icon="fa-solid fa-plus" [disabled]="elaboration"
          [label]="T | translateItem: 'generic.add'" [title]="T | translateItem: 'generic.add'" (click)="newNota()"
          [ngClass]="{'p-button-primary': dataForm.pristine,'p-button-outlined': !dataForm.pristine}"></button>

        <button pButton type="button" [label]="T | translateItem: 'generic.save'"
          [title]="T | translateItem: 'generic.save'" class="p-button-sm" icon="fa-solid fa-floppy-disk"
          [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (click)="save()"
          [ngClass]="{'p-button-primary': !dataForm.pristine,'p-button-outlined': dataForm.pristine }"></button>
      </div>
    </div>

    <loading-spinner [showSpinner]="loadingSpinnerVisible">
      <ng-container *ngIf="noteList$ | async as noteList">
        <div *ngIf="noteList?.length > 0; else emptylist" formArrayName="noteList" class="striped-fieldsets">
          <div *ngFor="let nota of noteList; let i = index let count = count">
            <p-fieldset>
              <ng-template pTemplate="header">
                {{nota.get('id').value === 0 ? (T | translateItem: 'gennote.notaTitleNew' ) : ((T | translateItem:
                'gennote.notaTitle':({dtIns:nota.get('dtIns').value | date:
                dateFormat,userInsEmailDes:nota.get('userInsEmailDes').value})))}}
              </ng-template>
              <ng-template pTemplate="content">
                <!-- Edit -->
                <ng-container *ngIf="!readOnly; else output">
                  <div [formGroupName]="i" class="formgrid grid">
                    <!-- <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                      <label class="text-sm">{{T | translateItem: 'gennote.userInsEmailDes'}}</label>
                      <span>
                        <span class="block font-medium mt-1">{{nota.get('userInsEmailDes').value}}</span>
                      </span>
                    </span> -->

                    <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                      <label class="text-sm required" [class.required]="!nota.get('id').value">{{T | translateItem:
                        'gennote.tipoNota'}}</label>
                      <span *ngIf="nota.get('id').value; else tipoControl;">
                        <span class="block font-medium mt-1">{{nota.get('notaTipoDes').value}}</span>
                      </span>
                      <ng-template #tipoControl>
                        <p-dropdown [options]="tipiNoteList$ | async" formControlName="notaTipoId"
                          [placeholder]="T | translateItem: 'gennote.tipoNota'" optionValue="id" optionLabel="des"
                          class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'">
                        </p-dropdown>
                      </ng-template>
                    </span>

                    <span class="field col-12 sm:col-6 md:col-8 lg:col-10">
                      <label class="text-sm">{{T | translateItem: 'gennote.oggetto'}}</label>
                      <input pInputTextarea formControlName="oggetto"
                        [placeholder]="T | translateItem: 'gennote.oggetto'" class="inputfield p-inputtext-sm">
                    </span>

                    <span class="field col-12">
                      <label class="text-sm required">{{T | translateItem: 'gennote.nota'}}</label>
                      <textarea pInputTextarea formControlName="nota" [placeholder]="T | translateItem: 'gennote.nota'"
                        class="inputfield"></textarea>
                    </span>
                  </div>
                  <div class="flex flex-row justify-content-end">
                    <div class="buttons-cnt">
                      <button *grantVisible="grants; key 'viewlog'" pButton type="button"
                        class="p-button-outlined p-button-sm" (click)="modalEntityLogShow(nota.get('id').value)"
                        [disabled]="elaboration || !nota.get('id').value" icon="fa-solid fa-magnifying-glass-chart"
                        [title]="T | translateItem: 'generic.viewlog'"></button>

                      <button pButton type="button" class="p-button-sm p-button-outlined p-button-danger"
                        [disabled]="elaboration" (click)="deleteNota(i)" [title]="T | translateItem: 'generic.delete'"
                        icon="fa-solid fa-trash"></button>
                    </div>
                  </div>
                </ng-container>

                <!-- ReadOnly -->
                <ng-template #output>
                  <div class="formgrid grid">
                    <!-- <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                      <label class="text-sm">{{T | translateItem: 'gennote.userInsEmailDes'}}</label>
                      <span>
                        <span class="block font-medium mt-1">{{nota.get('userInsEmailDes').value}}</span>
                      </span>
                    </span> -->

                    <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                      <label class="text-sm required">{{T | translateItem:
                        'gennote.tipoNota'}}</label>
                      <span class="block font-medium mt-1">{{nota.get('notaTipoDes').value}}</span>
                    </span>

                    <span class="field col-12 sm:col-6 md:col-8 lg:col-10">
                      <label class="text-sm">{{T | translateItem: 'gennote.oggetto'}}</label>
                      <span class="block font-medium mt-1">{{nota.get('oggetto').value}}</span>
                    </span>

                    <span class="field col-12">
                      <label class="text-sm required">{{T | translateItem: 'gennote.nota'}}</label>
                      <span class="block font-medium mt-1">{{nota.get('nota').value}}</span>
                    </span>
                  </div>
                  <div class="flex flex-row justify-content-end">
                    <div class="buttons-cnt">
                      <button *grantVisible="grants; key 'viewlog'" pButton type="button"
                        class="p-button-outlined p-button-sm" (click)="modalEntityLogShow(nota.get('id').value)"
                        [disabled]="elaboration || !nota.get('id').value" icon="fa-solid fa-magnifying-glass-chart"
                        [title]="T | translateItem: 'generic.viewlog'"></button>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </p-fieldset>
          </div>
        </div>

        <ng-template #emptylist>
          <p class="font-medium text-center p-3 text-600">
            {{T | translateItem: 'generic.listEmpty'}}
          </p>
        </ng-template>

      </ng-container> </loading-spinner>
  </form>
</ng-container>