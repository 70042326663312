<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm" novalidate class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
    <div class="flex-grow-1">
      <div class="formgrid grid row-gap-2">
        <!-- Date search -->
        <ng-template #dateSearch let-controlName="controlName" let-placeholderKey="placeholderKey"
          let-defaultValue="defaultValue" let-minDate="minDate" let-maxDate="maxDate">
          <div class="p-inputgroup">
            <p-calendar [formControlName]="controlName" [showIcon]="true" [dateFormat]="dateFormatPrNg"
              [firstDayOfWeek]="1" styleClass="multiselect-inputgroup" class="inputfield p-inputtext-sm"
              [placeholder]="T | translateItem: placeholderKey" [minDate]="minDate" [maxDate]="maxDate">
            </p-calendar>
            <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
              (click)="resetFilterControl(controlName, defaultValue)"></button>
          </div>
        </ng-template>

        <span class="col-12 sm:col-6 md:col-4 lg:col-2">
          <ng-container
            *ngTemplateOutlet="dateSearch; context: {controlName: 'dataInizio', placeholderKey: 'giudicicertificati.dataInizio'}"></ng-container>
        </span>

        <span class="col-12 sm:col-6 md:col-4 lg:col-2">
          <ng-container
            *ngTemplateOutlet="dateSearch; context: {controlName: 'dataFine', placeholderKey: 'giudicicertificati.dataFine'}"></ng-container>
        </span>

        <span class="col-12 sm:col-6 md:col-4 lg:col-2 flex">
          <p-checkbox formControlName="flCertificatoValido"
            [label]="T | translateItem: 'giudicicertificati.certificatoValido'" [value]="true" [binary]="true"
            class="inputfield">
          </p-checkbox>
        </span>
      </div>
    </div>

    <div class="buttons-cnt flex">
      <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
        [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
        [disabled]="elaboration || !dataForm.valid">
      </button>

      <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
        [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
        (click)="resetSearch()" [disabled]="elaboration">
      </button>
    </div>
  </form>

  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" responsiveLayout="stack" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" pSortableColumn="dataInizio">
          {{T | translateItem: 'giudicicertificati.dataInizio'}}
          <p-sortIcon field="dataInizio"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataFine">
          {{T | translateItem: 'giudicicertificati.dataFine'}}
          <p-sortIcon field="dataFine"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="flCertificatoScaduto">
          {{T | translateItem: 'giudicicertificati.certificatoScaduto'}}
          <p-sortIcon field="flCertificatoScaduto"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicicertificati.dataInizio'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.dataInizio | date: dateFormat}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicicertificati.dataFine'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.dataFine | date: dateFormat}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudicicertificati.certificatoScaduto'}}
          </span>
          <span *ngIf="rowData.flCertificatoScaduto" class="state-badge danger-background">{{T | translateItem:
            'giudicicertificati.certificatoScaduto'}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{T | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
