import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Observable } from "rxjs";
import { EntityServiceInfo, IEntityService, compareEntity } from "../services/entity.service";
import { isNullOrUndefined, lookupEnum } from "../utils/util";
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue } from "./data-search";
import { IEntity } from "./entity";

export const FIELD_EXT_PREFIX: string = "_x_";


/**
 * Enumerator of grants of command and form field
 */
export enum FieldGrant {
  /** FormField: hidden; CmdField: hidden */
  none = 0,
  /** FormField: read; CmdField: allowed */
  read = 1, // 2^0
  /** FormField: write; CmdField: - */
  write = 2, // 2^1
  /** FormField: print; CmdField: - */
  print = 4, // 2^2
}

/**
 * Enumerator of keys of command field
 */
export enum CmdFieldKey {
  none,
  load,
  add,
  edit,
  delete,
  save,
  print,
  select,
  back,
  navigate,
  cancel,
  up,
  down,
  search,
  reset,
  toggle,
  set,
  clear,
  /** entity manager list + search */
  modalList,
  /** entity manager list-editable + search */
  modalListEditable,
  /** entity manager list + search */
  mngList,
  /** entity manager list-editable + search */
  mngListEditable,
  /** open in new browser window */
  openBrowserWindow,
  navigateGuid
}

/**
 * Enumerator of mode of command field

*/
export enum CmdFieldMode {
  none,
  /** command rendered as button */
  btn,
  /** command rendered as link (href) */
  lnk,
  /** command rendered as tab panel */
  tab
}

/**
 * Enumerator of targets of command field
 */
export enum CmdFieldTarget {
  none,
  menu,
  containerTools,
  containerHeader,
  containerBody,
  containerFooter,
  listCaption,
  listHeader,
  listBody,
  listFooter,
  listSearch,
  listHeaderMenu,
  detailHeader,
  detailBody,
  detailFooter,
  viewHeader,
  viewBody,
  viewFooter,
  tab,
  accordion
}

/**
 * Event triggered by command field
 */
export interface CmdEvent {
  field: CmdField | FormField;
  data?: any | IEntity | IEntity[];
}

/**
 * Descriptor fo command field
 */
export interface CmdField {
  /** identifier of field */
  key: CmdFieldKey;
  /** how to render the command (UI component to use) */
  mode: CmdFieldMode;
  /** where the command appears */
  target: CmdFieldTarget;
  /** command type (i.e. 'submit') */
  type?: string;
  /** value displayed as label */
  label?: string;
  /** value displayed as title */
  title?: string;
  /** name of the icon */
  icon?: string;
  /** css class */
  class?: string;
  /** EntityManagerInfo to refer to */
  entityManagerInfo?: EntityManagerInfo;
  /** refer to the property of the entity */
  fieldRef?: string;
  /** bind the values between generic object (data) and the entity */
  fieldsBind?: any;
  /** bind the search values */
  fieldsBindSearch?: any;
  /** if true, it means the app should go to the previuos page after click */
  backAfterClick?: boolean;
  /** list of generic attributes */
  attributes?: any,
  /** state of the command (used for example to togggle between basic and advanced search ) */
  state?: any;
  // /** callback function */
  // hEvent?: (data?: any) => any;
}

/**
 * Enumerator of type of form field
 */
export enum FormFieldType {
  none,
  string,
  stringEmpty,
  stringCompose,
  number,
  boolean,
  date,
  file,
  object,
  enum,
  stateBadge,
  yesNo
}

/**
 * Enumerator of html type of form field
 */
export enum FormFieldTypeHtml {
  hidden,
  readonly,
  view,
  text,
  textarea,
  editor,
  number,
  password,
  radio,
  checkbox,
  select,
  date,
  file,
  autocomplete,
  modalList,
  modalListEditable,
  divider,
  htmlView
}

export interface FormField {
  /** identifier of field */
  key: string;
  /** type value  */
  type: FormFieldType;
  /** type of html component use to render the field */
  typeHtml?: FormFieldTypeHtml;
  /** field label value */
  label?: string;
  /** field placeholder value */
  placeholder?: string;
  /** prefix of translation */
  translatePrefix?: string;
  /** css class */
  class?: string;
  /** mark the field as read-only */
  readonly?: boolean;
  /** mark the field as sortable */
  sortable?: boolean;
  /** descriptor used by certain UI component such as select, checkbox, etc. */
  options?: FormFieldOptions;
  /** list of Reactive Form validators */
  validators?: ValidatorFn | ValidatorFn[];
  /** formatting information descriptor */
  format?: FormFieldFormat;
  /** callback function when field value changes */
  hChange?: (event: any) => any;
  /** callback function triggered when file is deselected */
  hFileDeselect?: (event: any) => any;
  /** callback function triggered when downlad is requested */
  hFileDownload?: (event: any) => any;
}

/**
 * Descriptor used by certain UI component such as select, checkbox, etc.
 * to dispay selction data
 */
export interface FormFieldOptions {
  /** multiple selection mode */
  multiselect?: boolean;
  /** data such as options to be rendered */
  data?: any[] | Observable<any>; // select options
  /** property of data used as key value */
  dataKey?: string; // select option value
  /** property of data used as label value */
  dataLabel?: string; // select option description
  /** if a translation is needed, this is the translation prefix */
  dataTranslatePrefix?: string;
  /** extension file list (separated by comma i.e. '.xls,.xlsx') */
  fileAccept?: string;
  /** key of field used to store the path of the file */
  filePathKey?: string;
  /** list of properties of entity used to search (separated by comma i.e. 'codice,descrizione') */
  searchFields?: string; //
  /** search comparison operator */
  searchCompOp?: ComparisonOperator;
  /** EntityManagerInfo to refer to (used to open related EntityManager) */
  entityManagerInfo?: EntityManagerInfo;
  /** bind fields between generic object (data) and the entity */
  fieldsBind?: any;
  /** bind fields used in the search */
  fieldsBindSearch?: any;
  /** bind fields used to create new entity */
  fieldsBindNew?: any;
  /** value displayed as label */
  label?: string;
  /** value displayed as title */
  title?: string;
  /** name of the icon */
  icon?: string;
  /** css class  */
  class?: string;
  /** css class  prefix */
  classPrefix?: string;
  /** min allowed value */
  min?: number;
  /** max allowed value */
  max?: number;
}

/**
 * Formatting information descriptor
 */
export interface FormFieldFormat {
  /** transformation/string pattern applied  */
  format?: string;
  /** render the value as icon */
  icon?: { [value: string]: string };
  /** class state badge */
  stateBadge?: { [value: string]: string };
  /** yes no badge */
  yesNo?: { [value: string]: string };
}


export interface IEntityManagerConfig {
  readonly id: string;
  readonly name: string;
  readonly section: string;
  readonly entityServices: { [name: string]: IEntityService<IEntity> };
  readonly textComponent: { [key: string]: string };
  readonly cmdFields: CmdField[];
  readonly listFields: FormField[];
  readonly detailFields: FormField[];
  readonly searchFields: FormField[];
  readonly searchAdvancedFields: FormField[];
  readonly loadLazy: boolean;
  readonly paginatorRows: number;
  readonly dataSortValue: DataSortValue[];
  readonly translateSuffixs: string[];
  readonly attributes: any;
  readonly entity: any;
  readonly clearCache: boolean;
  readonly newInstanceFields: string[];
  readonly formControl: AbstractControl;

  dispose(): void;
  getSection(): string;
  // getInstance(data?: any): IEntity;
  setEntity(entity: any);
  getEntity(): any;
  getEntityService(name: string): IEntityService<IEntity>;
  isFormFieldVisible(field: FormField, args: any): boolean;
  isFormFieldDisabled(field: FormField, args: any): boolean;
  isCmdFieldVisible(field: CmdField, args: any): boolean;
  isCmdFieldDisabled(field: CmdField, args: any): boolean;
  getCmdFields(fieldTarget?: CmdFieldTarget, fieldMode?: CmdFieldMode): CmdField[];
  getCmdField(fieldTarget: CmdFieldTarget, fieldMode: CmdFieldMode, fieldKey: CmdFieldKey): CmdField;
  getDetailFields(args: any): FormField[];
  toggleField(field: CmdField, state1: any, state2: any, label1: string, label2: string);
  onCmd(event: CmdEvent): boolean;
  getClearCache(): boolean;
  getNewInstanceFields(): string[];
  setFormControl(control: AbstractControl);
}

/**
 * Contains the properties used to model and manage an entity.
 * These properties include entity type information
 * and other information used to interact with the UI
 */
export abstract class EntityManagerConfig implements IEntityManagerConfig {
  /** identitier */
  id: string;
  /** name/type of entity, it is used as an alias to the IEntityService used to comunicate with API REST */
  name: string;
  /** section identifier (if null the section identifier is the value of attribute 'id') */
  section: string;
  /** list of IEntityService injected when the class is instantiated */
  entityServices: { [name: string]: IEntityService<IEntity> };
  /** object to contains the texts to be displayed */
  textComponent: { [key: string]: string };
  /** list of commands rendered as UI component  */
  cmdFields: CmdField[] = [];
  /** list of attributes of entity to be displayed (generally in a table)  */
  listFields: FormField[] = [];
  /** list of attributes of entity used to build the insert or update form  */
  detailFields: FormField[] = [];
  /** list of attributes of entity used to build the search (basic) form  */
  searchFields: FormField[] = [];
  /** list of attributes of entity used to build the search (advance) form  */
  searchAdvancedFields: FormField[] = [];
  /** how data is loaded, filtered and paginated; default true (filtered and paginated on the server) */
  loadLazy: boolean = true;
  /** number of rows displayed per page */
  paginatorRows: number;
  /** list of properties of entity used to order the rows of list */
  dataSortValue: DataSortValue[] = [];
  /** object used to store generic attribute */
  attributes: any = {};
  /** list of translation suffixes */
  translateSuffixs: string[];
  /** */
  entity: any;
  /** clear search filter cache when leave component */
  clearCache: boolean;
  /** fields use to call service to retrieve new instance data (data used to build new entity instance eg. add/new in list or editableList) */
  newInstanceFields: string[] = [];
  /** form control injected from EntityManager */
  formControl: AbstractControl;

  constructor(
    entityManagerInfo: EntityManagerInfo,
    entityServices: { [name: string]: IEntityService<IEntity> } = {},
    entity?: any
  ) {
    this.id = entityManagerInfo.id;
    this.name = entityManagerInfo.name;
    this.section = entityManagerInfo.section;
    this.translateSuffixs = entityManagerInfo.translateSuffixs;
    this.entityServices = entityServices;
    this.entity = entity;
    this.init();
    this.initFields();
  }

  protected init(): void { };
  protected initFields(): void { };
  protected initFormControl(): void { };
  dispose(): void { };

  /**
  * Return the section identitfier used to get the list of grants
  */
  getSection(): string {
    return this.section ?? this.id;
  }

  // /**
  //  * Return an instance of entity filled with tha values in data
  //  *
  //  * @param data contains the value for each property of entity that needs to be set
  //  * @returns instance of entity
  //  */
  // getInstance(data?: any): IEntity {
  //   let result = new Entity();
  //   if (data) {
  //     Object.keys(data).forEach(key => {
  //       result[key] = data[key];
  //     });
  //   }
  //   return result;
  // }

  /**
   * Set entity
   *
   * @param entity entity
   * @returns
   */
  setEntity(entity: any) {
    this.entity = entity;
  }

  /**
   * Return entity
   * @returns entity
   */
  getEntity(): any {
    return this.entity;
  }

  /**
   * Search filter cache must be deleted when leave component
   *
   * @returns true if search filter cache must be deleted; false otherwise
   */
  getClearCache(): boolean {
    return this.clearCache;
  }

  /**
   * Fields use to call service to retrieve new instance data
   * Data is used to build new entity instance, eg. add/new in list or editableList
   *
   * @returns list of field used to query the service
   */
  getNewInstanceFields(): string[] {
    return this.newInstanceFields;
  }

  /**
   * Return the EntityService for the specified name
   *
   * @param name name of EntityService
   * @returns instance of EntityService
   */
  getEntityService(name: string): IEntityService<IEntity> {
    return this.entityServices[name];
  }

  // /**
  //  * Return true if the form field can be displayed, false otherwise
  //  *
  //  * @param field field to be evaluated
  //  * @param args arguments used to evaluation
  //  * @returns boolean
  //  */
  isFormFieldVisible(field: FormField, args: any): boolean {
    return true;
  }

  /**
   * Return true if the form field is disabled, false otherwise
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isFormFieldDisabled(field: FormField, args: any): boolean {
    return false;
  }

  /**
   * Return true if the command field can be displayed, false otherwise
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isCmdFieldVisible(field: CmdField, args: any): boolean {
    let result: boolean;

    switch (field.key) {
      case CmdFieldKey.save:
      case CmdFieldKey.cancel:
        result = isNullOrUndefined(args?.editing) ? true : args.editing;
        break;

      case CmdFieldKey.edit:
      case CmdFieldKey.select:
      case CmdFieldKey.delete:
      case CmdFieldKey.up:
      case CmdFieldKey.down:
      case CmdFieldKey.modalListEditable:
      case CmdFieldKey.navigate:
        result = isNullOrUndefined(args?.editing) ? true : !args.editing;
        break;

      default:
        result = true;
        break;
    }

    return result;
    // return result && (isNullOrUndefined(args?.visible) ? true : args.visible);
  }

  /**
   * Return true if the command field is disabled, false otherwise
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isCmdFieldDisabled(field: CmdField, args: any): boolean {
    let result = false;

    switch (field.key) {
      case CmdFieldKey.delete:
      case CmdFieldKey.print:
        result = args?.entitiesSelCount <= 0 || args?.dataNull;
        break;

      case CmdFieldKey.up:
      case CmdFieldKey.down:
        result = (args?.entitiesCount == 1 || args?.entitiesSelCount != 1)
          && (isNullOrUndefined(args?.dataNull) ? false : args?.dataNull);
        break;

      case CmdFieldKey.add:
      case CmdFieldKey.edit:
      case CmdFieldKey.modalListEditable:
        result = isNullOrUndefined(args?.dataNull) ? false : args?.dataNull;
        break;

      case CmdFieldKey.save:
        result = !(args?.formValid ?? false) || (args?.formPristine ?? false);
        break;

      default:
        result = false;
        break;
    }

    return result || (isNullOrUndefined(args?.disabled) ? false : args.disabled);
  }

  /**
   * Return the list of command fields filtering by target or mode
   *
   * @param fieldTarget target of field
   * @param fieldMode mode of field
   * @returns list of command fields
   */
  getCmdFields(fieldTarget?: CmdFieldTarget, fieldMode?: CmdFieldMode): CmdField[] {
    return this.cmdFields.filter(t =>
      (isNullOrUndefined(fieldTarget) || t.target == fieldTarget)
      && (isNullOrUndefined(fieldMode) || t.mode == fieldMode)
    );
  }

  /**
   * Return the list of command fields filtering by target, mode or key
   *
   * @param fieldTarget target of field
   * @param fieldMode mode of field
   * @param fieldKey key of field
   * @returns list of command fields
   */
  getCmdField(fieldTarget: CmdFieldTarget, fieldMode: CmdFieldMode, fieldKey: CmdFieldKey): CmdField {
    return this.cmdFields.find(t => t.mode == fieldMode && t.target == fieldTarget && t.key == fieldKey);
  }

  /**
   * Return the list of detail fields
   *
   * @param args args to evaluate to decide if the field could be used
   * @returns
   */
  getDetailFields(args?: any): FormField[] {
    return this.detailFields;
  }

  // /**
  //  * Return detail field by key
  //  *
  //  * @param key key of field
  //  * @returns detail field
  //  */
  // getDetailField(key: string): FormField {
  //   return this.detailFields.find(field => field.key === key);
  // }

  /**
   * Toggles state and text of command field,
   * it is used for example to toggle between basic search and advanced search
   *
   *
   * @param field
   * @param state1
   * @param state2
   * @param label1
   * @param label2
   */
  toggleField(field: CmdField, state1: any, state2: any, label1: string, label2: string) {
    let result: boolean;
    if ((typeof state1 === 'function') || (typeof state1 === 'object')) {
      result = compareEntity<any>(field.state, state1);
    }
    else {
      result = (field.state === state1);
    }

    if (result) {
      field.state = state2;
      field.label = label2;
      field.title = label2;
    }
    else {
      field.state = state1;
      field.label = label1;
      field.title = label1;
    }
  }

  /**
   * Trigger the click on the CmdField.
   * This method can be implemented in the derived class
   * to performs a custom logic.
   *
   * @param event contains the field that trigger the event and optionally the related data
   * @returns false if the event does not be propagated forward; true otherwise
   */
  onCmd(event: CmdEvent): boolean {
    switch (event.field.key) {
      case CmdFieldKey.toggle:
        let field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
        this.toggleField(field, true, false, 'generic.searchAdvanced', 'generic.searchBasic');
        // event will be propagate forward
        return false;

      default:
        // event will be propagate forward
        return false;
    }
  }

  /**
   * Inject the Form Control
   *
   * @param control
   */
  setFormControl(control: AbstractControl) {
    this.formControl = control;
    this.initFormControl();
  }

}

/**
 * Manager of entites based on an instance of EntityManagerConfig.
 * This is the specifc instance used to manage the entity
 * and contains the properties to know how to do it
 */
export class EntityManager {
  /** instance of EntityManagerConfig */
  private config: IEntityManagerConfig;
  /** identifier */
  id: string;
  /** identifier */
  section: string;
  /** url relative to urlRoot */
  url: string;
  /** grants used to display/hide and enable/disable fields */
  grants: EntityManagerGrant;
  /** data used to search */
  private entitySearch: any;
  /** how data is loaded, filtered and paginated; default true (filtered and paginated on the server) */
  loadLazy: boolean;
  /** list of properties of entity used to order the rows of list */
  sortValues: DataSortValue[] = [];
  /** number of the first record of tha page displayed  */
  paginatorFirst: number = 0;
  /** number of rows displayed per page */
  paginatorRows: number = 0;
  /** advanced search enabled */
  advancedSearch: boolean = false;
  /** */
  isCompleted: () => boolean;


  constructor(config: IEntityManagerConfig, grants: EntityManagerGrant, url: string) {
    this.config = config;
    this.id = config.id;
    this.paginatorRows = config.paginatorRows;
    this.loadLazy = config.loadLazy;
    this.grants = grants;
    this.url = url;
  }

  dispose(): void {
    this.config.dispose();
  };

  /**
   * Initializer
   *
   * @param data values to be set
   */
  init(data: any) {
    this.entitySearch = data?.entitySearch ?? this.entitySearch;
    this.paginatorFirst = data?.paginatorFirst ?? this.paginatorFirst;
    this.paginatorRows = data?.paginatorRows ?? this.paginatorRows;
    this.advancedSearch = data?.advancedSearch ?? this.advancedSearch;
    this.loadLazy = data?.loadLazy ?? this.loadLazy;
    this.sortValues = data?.sortValues ?? this.sortValues;

    const field = this.getCmdField(CmdFieldTarget.listSearch, CmdFieldMode.btn, CmdFieldKey.toggle);
    if (field) {
      this.config.toggleField(field, this.advancedSearch, !this.advancedSearch, 'generic.searchBasic', 'generic.searchAdvanced');
    }
  }

  // /**
  //  * Return the main information as object
  //  *
  //  * @returns
  //  */
  // public toJSON() {
  //   return {
  //     id: this.id,
  //     urlRoot: this.urlRoot,
  //     url: this.url,
  //     parentUrl: this.parentUrl,
  //     // entity: this.entity,
  //     // entitySearch: this.entitySearch,
  //     paginatorFirst: this.paginatorFirst,
  //     paginatorRows: this.paginatorRows,
  //     loadLazy: this.loadLazy,
  //     sortValues: this.sortValues
  //   };
  // }

  /** get an object that contains values regarding list filter and rappresentation */
  getFilterData(): any {
    return {
      paginatorFirst: this.paginatorFirst,
      paginatorRows: this.paginatorRows,
      advancedSearch: this.advancedSearch,
      entitySearch: this.entitySearch,
      sortValues: this.sortValues
    }
  }

  /**
   * Return the name/type of entity
   */
  get name(): string {
    return this.config.name;
  }

  /**
   * Return the list of transaltion suffixes
   */
  get translateSuffixs(): string[] {
    return this.config.translateSuffixs;
  }

  /**
  * Return the section identitfier
  */
  getSection(): string {
    return this.config.getSection();
  }

  /**
   * Return the EntityService for the specified name
   *
   * @param name
   * @returns
   */
  getEntityService(name: string = this.config.name): IEntityService<IEntity> {
    return this.config.getEntityService(name);
  }

  /**
   * Set the entity
   *
   * @param entity entity instance
   */
  setEntity(data: any) {
    this.config.setEntity(data);
  }

  /**
   * Return the entity instance
   *
   * @returns entity instance
   */
  getEntity(): any {
    return this.config.getEntity();
  }

  /**
   * Set the entity use in the search
   *
   * @param entitySearch entity instance
   */
  setEntitySearch(entitySearch: any) {
    this.entitySearch = entitySearch;
  }

  /**
   * Return the entity use in the search
   *
   * @returns entity instance
   */
  getEntitySearch(): any {
    return this.entitySearch ?? this.getEntity();
  }

  /**
   * Return true if the command field can be displayed, false otherwise
   * The evalution also includes grants
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isCmdFieldVisible(field: CmdField, args: any, allowNull: boolean = true): boolean {
    let result = this.config.isCmdFieldVisible(field, args);
    result = result && checkGrant(this.grants, getCmdFieldKeyString(field), FieldGrant.read, allowNull);
    return result;
  }

  /**
   * Return true if the command field is disabled, false otherwise
   * The evalution also includes grants
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isCmdFieldDisabled(field: CmdField, args: any): boolean {
    let result = this.config.isCmdFieldDisabled(field, args);
    return result;
  }

  // /**
  //  * Return true if the form field can be displayed, false otherwise
  //  * The evalution also includes grants
  //  *
  //  * @param field field to be evaluated
  //  * @param args arguments used to evaluation
  //  * @returns boolean
  //  */
  isFormFieldVisible(field: FormField, args?: any, allowNull: boolean = true): boolean {
    let result = this.config.isFormFieldVisible(field, args);
    result = result && checkGrant(this.grants, field.key, FieldGrant.read, allowNull);
    return result;
  }

  /**
   * Return true if the form field is disabled, false otherwise
   * The evalution also includes grants
   *
   * @param field field to be evaluated
   * @param args arguments used to evaluation
   * @returns boolean
   */
  isFormFieldDisabled(field: FormField, args?: any, allowNull: boolean = true): boolean {
    let result = this.config.isFormFieldDisabled(field, args);
    result = result || (!checkGrant(this.grants, field.key, FieldGrant.write, allowNull));
    return result;
  }

  /**
   * Return the list of command fields filtering by target or mode
   * The evalution also includes grants
   *
   * @param fieldTarget target of field
   * @param fieldMode mode of field
   * @returns list of command fields
   */
  getCmdFields(fieldTarget?: CmdFieldTarget, fieldMode?: CmdFieldMode, allowNull: boolean = true): CmdField[] {
    return this.config.getCmdFields(fieldTarget, fieldMode)
      .filter(field => {
        return checkGrant(this.grants, getCmdFieldKeyString(field), FieldGrant.read, allowNull)
      });
  }

  /**
   * Return the list of command fields filtering by target, mode or key
   * The evalution also includes grants
   *
   * @param fieldTarget target of field
   * @param fieldMode mode of field
   * @param fieldKey key of field
   * @returns list of command fields
   */
  getCmdField(fieldTarget: CmdFieldTarget, fieldMode: CmdFieldMode, fieldKey: CmdFieldKey, allowNull: boolean = true): CmdField {
    let result = this.config.getCmdField(fieldTarget, fieldMode, fieldKey);
    if (result && checkGrant(this.grants, getCmdFieldKeyString(result), FieldGrant.read, allowNull)) {
      return result;
    }
    return null;
  }

  /**
   * Return the list of fields used to display the entity content
   * The evalution also includes grants
   *
   * @returns list of form fields
   */
  getListFields(allowNull: boolean = true): FormField[] {
    return this.config.listFields.filter(field => checkGrant(this.grants, field.key, FieldGrant.read, allowNull));
  }

  /**
   * Return the list of fields used to build the entity insert/update form
   * The evalution also includes grants
   *
   * @returns list of form fields
   */
  getDetailFields(args?: any, allowNull: boolean = true): FormField[] {
    return this.config.getDetailFields(args).filter(field => checkGrant(this.grants, field.key, FieldGrant.read, allowNull));
  }

  /**
   * Return the list of fields used to build the basic search form
   * The evalution also includes grants
   *
   * @returns list of form fields
   */
  getSearchFields(allowNull: boolean = true): FormField[] {
    return this.config.searchFields.filter(field => checkGrant(this.grants, field.key, FieldGrant.read, allowNull));
  }

  /**
   * Return the field used to build the basic search form
   * The evalution also includes grants
   *
   * @param key field
   * @param allowNull
   * @returns
   */
  getSearchField(key: string, allowNull: boolean = true): FormField {
    return this.config.searchFields.find(field => field.key == key && checkGrant(this.grants, field.key, FieldGrant.read, allowNull));
  }

  /**
   * Return the list of fields used to build the advanced search form
   * The evalution also includes grants
   *
   * @returns list of form fields
   */
  getSearchAdvancedFields(allowNull: boolean = true): FormField[] {
    return this.config.searchAdvancedFields.filter(field => checkGrant(this.grants, field.key, FieldGrant.read, allowNull));
  }

  /**
   * Return the value referred to the field
   *
   * @param field information about the property to be get
   * @param data instance of object that contains the property
   * @returns value of property
   */
  getFormFieldValue(field: FormField, data: any, allowNull: boolean = true): any {
    if (isNullOrUndefined(data))
      return undefined;
    switch (field.type) {
      case FormFieldType.stringCompose:
        let values: any[] = [];
        field.key.split(",").map(fKey => {
          let value = undefined;
          // if fkey contains a deep field (obj.refObj.field), this check does not work!!!
          if (checkGrant(this.grants, fKey, FieldGrant.read, allowNull)) {
            value = data;
            fKey.split(".").forEach(t => value = value?.[t]);
          }
          values.push(value ?? "");
        });

        let result = field.format.format;
        values.forEach((value, index) => result = result.replace(`{{p${index}}}`, value));
        return result;

      default:
        let val = { ...data };
        field.key.split('.').forEach(fKey => val = val?.[fKey]);
        if (isNullOrUndefined(val)) {
          val = data[field.key];
        }
        return val;

    }
  }

  /**
   * Set information about order mode
   *
   * @param fieldKey key of entity property
   * @param order order mode (ASC, DESC)
   * @returns
   */
  setSort(fieldKey: string | string[], order: number) {
    let field: FormField = this.config.listFields.find(t => t.key === fieldKey);
    if (isNullOrUndefined(field)) {
      return;
    }
    this.sortValues = [new DataSortValue(field.key, order)];
  }

  /**
   * Return information about order mode
   *
   * @returns instance of DataSortValue that dscribe the order mode
   */
  getSort(): DataSortValue {
    return this.sortValues?.[0];
  }

  /**
   * Set paginator information
   *
   * @param paginatorFirst number of first row of the page dispalyed
   * @param paginatorRows number of rows dispalyed per page
   */
  setPaginator(paginatorFirst: number, paginatorRows: number) {
    this.paginatorFirst = paginatorFirst;
    this.paginatorRows = paginatorRows;
  }

  /**
   * Build DataSearch instance based on data
   *
   * @param data instance of object
   * @returns instance of DataSearch
   */
  getDataSearch(): DataSearch { // getDataSearch(data: any): DataSearch {
    const data = this.entitySearch;

    let searchFieldsFlat;
    if (this.advancedSearch) {
      searchFieldsFlat = this.config.searchAdvancedFields.reduce((res, field) => res.concat(field), []);
    }
    else {
      searchFieldsFlat = this.config.searchFields.reduce((res, field) => res.concat(field), []);
    }

    let dataSearch = new DataSearch();

    if (this.config.loadLazy) {
      dataSearch.pageFirst = this.paginatorFirst;
      dataSearch.pageSize = this.paginatorRows;
    }
    else {
      dataSearch.pageFirst = 0;
      dataSearch.pageSize = 0;
    }

    dataSearch.dataSortValues = this.sortValues.length > 0 ? this.sortValues : this.config.dataSortValue;

    if (!isNullOrUndefined(data)) {
      searchFieldsFlat.forEach(field => {
        let val = data;
        //field.key.split(".").map(field => val = val[field]);
        field.key.split(".").map(field => {
          if (val instanceof Array) {
            val = val.map(t => t[field]);
          }
          else {
            val = val?.[field]
          }
        });

        if (!isNullOrUndefined(val)) {
          const fields = field?.options?.searchFields?.split(",") ?? [field.key];
          if (val instanceof Array) {
            dataSearch.dataSearchValues.push(new DataSearchValue(val, fields, field.options.searchCompOp));
          }
          else {
            dataSearch.dataSearchValues.push(new DataSearchValue([val], fields, field.options.searchCompOp));
          }
          // dataSearch.dataSearchValues.push(new DataSearchValue([val.toString()], fields, field.options.searchCompOp));
        }
      });
    }

    return dataSearch;
  }

  /**
   * Return the text referred to the key
   *
   * @param key key of text
   * @returns text as string
   */
  getTextComponent(key: "listHeader" | "windowListHeader" | "windowDetailHeader"): string {
    if (!this.config.textComponent?.[key]) {
      return "";
    }
    return this.config.textComponent[key];
  }

  /**
   * Trigger the click on the CmdField.
   * This method can be implemented in the derived class
   * to performs a custom logic.
   *
   * @param event contains the field that trigger the event and optionally the related data
   * @returns false if the event does not be propagated forward; true otherwise
   */
  onCmd(event: CmdEvent): boolean {
    return this.config.onCmd(event);
  }

  /**
   * Convert each field of type FormFieldType.date from string to Date object.
   * To perform a corret conversion the string value must be in UTC.
   * The result of conversion is a new Date object with the local TimeZone.
   *
   * @param entity
   * @returns
   */
  convertDateFieldToLocalDate(entity: IEntity): IEntity {
    const result = { ...entity };
    const dateFields = this.getListFields().filter(field => field.type == FormFieldType.date);
    dateFields.forEach(field => {
      if (result[field.key]) {
        result[field.key] = new Date(result[field.key]);
      }
    });
    return result;
  }

  /**
   * Convert each field of type FormFieldType.date from string to Date object.
   * To perform a corret conversion the string value must be in UTC.
   * The result of conversion is a new Date object with the local TimeZone.
   *
   * @param entities list of entities to convert date fields for
   * @returns new array of entities
   */
  convertDateFieldToLocalDateArray(entities: IEntity[]): IEntity[] {
    const result = [...entities];
    const dateFields = this.getListFields().filter(field => field.type == FormFieldType.date);
    result.forEach(entity => {
      dateFields.forEach(field => {
        if (entity[field.key]) {
          entity[field.key] = new Date(entity[field.key]);
        }
      })
    });
    return result;
  }

  /**
   * Search filter cache must be deleted when leave component
   *
   * @returns true if search filter cache must be deleted; false otherwise
   */
  getClearCache(): boolean {
    return this.config.getClearCache();
  }

  /**
   * Fields use to call service to retrieve new instance data
   * Data is used to build new entity instance, eg. add/new in list or editableList
   *
   * @returns list of field used to query the service
   */
  getNewInstanceFields(): string[] {
    return this.config.getNewInstanceFields();
  }

  getFormMode(): number {
    return this.getEntity()?.id ? FormModeType.edit : FormModeType.insert;
  }

  setFormControl(control: AbstractControl) {
    this.config.setFormControl(control);
  }

}

/**
 * Descriptor used to refer to an instance of EntityManager
 * All properties are used as parameters to instantiate the EntityManager
 * It is also used as descriptor of single menu item.
 */
export interface EntityManagerInfo {
  /** id of EntityManager */
  id: string;
  /** name of EntityService */
  name: string;
  /** parent (used to refer to menu item parent) */
  parent?: EntityManagerInfo;
  /** section identifier (if null the section identifier is the value of attribute 'id') */
  section?: string;
  /** type of EntityManagerConfig to used */
  entityType?: any;
  /** list of IEntityService injected when the class is instantiated */
  entityServices?: EntityServiceInfo[];
  /** url relative to urlRoot */
  url?: string;
  /** list of translation suffixes */
  translateSuffixs?: string[];
  /** list of params added to url as query string */
  queryParams?: {
    [k: string]: any;
  };
  /** defines if the url must be prepended with the parent url */
  prependParentOnUrl?: boolean;

  // params?: string[];

  // grantid?: string[];
}

export interface EntityManagerGrant {
  [key: string]: FieldGrant;
  // cmdFieldGrants: { [key: string]: FieldGrant };
  // formFieldGrants: { [key: string]: FieldGrant };
}

export function getCmdFieldKeyString(field: CmdField): string {
  let result: string = undefined;

  switch (field.key) {
    case CmdFieldKey.navigate:
    case CmdFieldKey.mngListEditable:
      result = field.entityManagerInfo?.section ?? field.entityManagerInfo?.id;
      break;
    default:
      result = lookupEnum(CmdFieldKey, field.key);
      break;
  }

  if (result) {
    result = result.toLowerCase();
  }

  return result;
}

export function checkGrant(entityManagerGrant: EntityManagerGrant, fieldKey: string, fieldGrant: FieldGrant, allowNull: boolean): boolean {
  let grant = entityManagerGrant?.[fieldKey];
  return isNullOrUndefined(grant) ? allowNull : (grant & fieldGrant) == fieldGrant;
}

export const CmdFieldStyle = {
  btnPrimary: 'p-button-sm',
  btnPrimaryDanger: 'p-button-sm p-button-danger',
  btnSecondary: 'p-button-sm p-button-outlined',
  btnSecondaryDanger: 'p-button-sm p-button-outlined p-button-danger',
};

export const Icons = {
  plus: 'fa-solid fa-plus',
  reset: 'fa-solid fa-filter-circle-xmark',
  filters: 'fa-solid fa-filter',
  edit: 'fa-solid fa-pencil',
  calendar: 'fa-solid fa-calendar-days',
  search: 'fa-solid fa-search',
  save: 'fa-solid fa-floppy-disk',
  back: 'fa-solid fa-chevron-left',
  delete: 'fa-solid fa-trash',
  security: 'fa-solid fa-shield-halved',
  data: 'fa-solid fa-table',
  checkmark: 'fa-solid fa-circle-check',
  cross: 'fa-solid fa-circle-xmark',
  globe: 'fa-solid fa-globe',
  select: 'fa-solid fa-circle-check',
  print: 'fa-solid fa-print',
  user: 'fa-solid fa-user',
  tag: 'fa-solid fa-tags',
  lookup: 'fa-solid fa-arrow-up-right-from-square',
  detail: 'fa-solid fa-file-lines',
  settings: 'fa-solid fa-gear',
  help: 'fa-solid fa-circle-question',
  checkmarkGreen: 'fa-solid fa-circle-check success',
  crossRed: 'fa-solid fa-circle-xmark danger',
  inbox: 'fa-solid fa-inbox',
  envelope: 'fa-solid fa-envelope',
  envelopeOpen: 'fa-solid fa-envelope-open',
  send: 'fa-solid fa-paper-plane',
  load: 'fa-solid fa-arrow-rotate-right',
  userSecurity: 'fa-solid fa-user-shield',
  userCheck: 'fa-solid fa-user-check',
  nodes: 'fa-solid fa-circle-nodes',
  reply: 'fa-solid fa-reply',
  bell: 'fa-solid fa-bell',
  message: 'fa-solid fa-message',
  users: 'fa-solid fa-users',
  thumbsUp: 'fa-solid fa-thumbs-up',
  thumbsDown: 'fa-solid fa-thumbs-down',
  list: 'fa-solid fa-list-ul',
  graduate: 'fa-solid fa-user-graduate',
  book: 'fa-solid fa-book'
};

export const FormFieldStyle = {
  default: 'col-12 sm:col-6 md:col-4 lg:col-2',
  full: 'col-12'
};

export enum FormModeType {
  insert,
  edit,
  readOnly
}
