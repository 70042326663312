import { Validators } from "@angular/forms";
import { AppManagerRoutes } from "src/app/app-manager-routes";
import { optionsSINO } from "src/app/utils/util";
import { ComparisonOperator, DataSearch, DataSortValue, SortMode } from "../data-search";
import { Entity } from "../entity";
import { CmdField, CmdFieldKey, CmdFieldMode, CmdFieldStyle, CmdFieldTarget, EntityManagerConfig, FormFieldStyle, FormFieldType, FormFieldTypeHtml, Icons } from "../entity-config";

export class GenClasse extends Entity {
  cod: string;
  mesiDa?: number;
  mesiA?: number;
  ord: number;
  prezzoCatalogo?: number;
  ordineStampaCatalogo: number;
  flValidaPerChIta: boolean;
  flAssegnazionePerTipoRegCane: boolean;
  flAbilitaCreazioneCoppie: boolean;
  flAbilitaCreazioneGruppi: boolean;
  flAbilitaGiudiceRaggruppamento: boolean;
  flEscludiDaPassaClasseCampioni: boolean;
  flBloccaMesi: boolean;
  flConsenteCampionatoSociale: boolean;
  flClasseTecnica: boolean;
  flEsenteFeeCaneSingolo: boolean;
  abilitaM: boolean;
  abilitaF: boolean;
  piattaforma: string;
  denominazioniClasse: string;
  dataInizioEsenzFeeCaneSingolo?: Date;
  dataFineEsenzFeeCaneSingolo?: Date;

  lang: string;
  des: string;
}

export class GenClasseLang extends Entity {
  classeId: number;
  lang: string;
  des: string;
}

export class GenClasseEMC extends EntityManagerConfig {
  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {
      windowListHeader: `${this.name}.windowlistheader`,
      windowInsertHeader: `${this.name}.windowinsertheader`,
      windowEditHeader: `${this.name}.windoweditheader`
    };

    this.dataSortValue = [new DataSortValue('cod', SortMode.Asc)];
  }

  initFields() {
    this.cmdFields = [
      // Tools
      {
        key: CmdFieldKey.openBrowserWindow,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.containerTools,
        title: 'generic.help',
        icon: Icons.help,
        class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenHelpLang
      },

      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary,
      },
      {
        key: CmdFieldKey.toggle,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchBasic',
        title: 'generic.searchBasic',
        icon: Icons.filters,
        class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // List
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        entityManagerInfo: AppManagerRoutes.GenClasseNew,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        entityManagerInfo: AppManagerRoutes.GenClasseEdit,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.modalListEditable,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.translations',
        icon: Icons.globe,
        class: CmdFieldStyle.btnSecondary,
        entityManagerInfo: AppManagerRoutes.GenClasseLangList,
        fieldsBind: { id: 'classeId' },
        fieldsBindSearch: { id: 'classeId' }
      },
      // {
      //   key: CmdFieldKey.delete,
      //   mode: CmdFieldMode.btn,
      //   target: CmdFieldTarget.listBody,
      //   title: 'generic.delete',
      //   icon: Icons.delete,
      //   class: CmdFieldStyle.btnSecondaryDanger
      // },

      // Detail
      {
        key: CmdFieldKey.back,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.detailHeader,
        label: 'generic.back',
        title: 'generic.back',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.detailHeader,
        label: 'generic.save',
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        sortable: true,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod',
        type: FormFieldType.string,
        label: `${this.name}.cod`,
        sortable: true
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden
      },
      {
        key: 'cod',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.readonly,
        label: `${this.name}.cod`,
        placeholder: `${this.name}.cod`,
        validators: [Validators.required, Validators.maxLength(3)],
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'mesiDa',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.mesiDa`,
        placeholder: `${this.name}.mesiDa`,
        class: FormFieldStyle.default
      },
      {
        key: 'mesiA',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.mesiA`,
        placeholder: `${this.name}.mesiA`,
        class: FormFieldStyle.default
      },
      {
        key: 'prezzoCatalogo',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.prezzoCatalogo`,
        placeholder: `${this.name}.prezzoCatalogo`,
        class: FormFieldStyle.default
      },
      {
        key: 'ordineStampaCatalogo',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.number,
        label: `${this.name}.ordineStampaCatalogo`,
        placeholder: `${this.name}.ordineStampaCatalogo`,
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flValidaPerChIta',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flValidaPerChIta`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flAssegnazionePerTipoRegCane',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flAssegnazionePerTipoRegCane`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flAbilitaCreazioneCoppie',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flAbilitaCreazioneCoppie`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flAbilitaCreazioneGruppi',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flAbilitaCreazioneGruppi`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flAbilitaGiudiceRaggruppamento',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flAbilitaGiudiceRaggruppamento`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flEscludiDaPassaClasseCampioni',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flEscludiDaPassaClasseCampioni`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flBloccaMesi',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flBloccaMesi`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flConsenteCampionatoSociale',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flConsenteCampionatoSociale`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flClasseTecnica',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flClasseTecnica`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      },
      {
        key: 'flEsenteFeeCaneSingolo',
        type: FormFieldType.boolean,
        typeHtml: FormFieldTypeHtml.radio,
        label: `${this.name}.flEsenteFeeCaneSingolo`,
        options: { data: optionsSINO, dataKey: 'key', dataLabel: 'label' },
        validators: [Validators.required],
        class: FormFieldStyle.default
      }
    ];

    this.searchFields = [
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'cod,des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.full
      }
    ];

    this.searchAdvancedFields = [
      {
        key: 'cod',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.cod`,
        options: {
          searchFields: 'cod',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      }
    ];
  }
}

export class GenClasseLangModalEMC extends EntityManagerConfig {

  fLoad: CmdField = { key: CmdFieldKey.load, mode: CmdFieldMode.btn, target: CmdFieldTarget.none }

  init() {
    this.paginatorRows = 10;
    this.loadLazy = true;
    this.textComponent = {};
    this.dataSortValue = [new DataSortValue('des', SortMode.Asc)];

    const ds: DataSearch = new DataSearch();
    ds.dataSortValues = [new DataSortValue('id', SortMode.Asc)];

    this.attributes['genlang'] = this.getEntityService('genlang').searchEntitiesAsync(this.fLoad, ds);
  }

  initFields() {
    this.cmdFields = [
      // List search
      {
        key: CmdFieldKey.search,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        type: 'submit',
        label: 'generic.search',
        title: 'generic.search',
        icon: Icons.search,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.reset,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchReset',
        title: 'generic.searchReset',
        icon: Icons.reset,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.toggle,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listSearch,
        label: 'generic.searchBasic',
        title: 'generic.searchBasic',
        icon: Icons.filters,
        class: CmdFieldStyle.btnSecondary,
        state: false
      },

      // Editable
      {
        key: CmdFieldKey.add,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listHeader,
        label: 'generic.add',
        title: 'generic.add',
        icon: Icons.plus,
        class: CmdFieldStyle.btnPrimary
      },
      {
        key: CmdFieldKey.edit,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.edit',
        icon: Icons.edit,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.delete,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.delete',
        icon: Icons.delete,
        class: CmdFieldStyle.btnSecondaryDanger
      },
      {
        key: CmdFieldKey.cancel,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.cancel',
        icon: Icons.back,
        class: CmdFieldStyle.btnSecondary
      },
      {
        key: CmdFieldKey.save,
        mode: CmdFieldMode.btn,
        target: CmdFieldTarget.listBody,
        title: 'generic.save',
        icon: Icons.save,
        class: CmdFieldStyle.btnPrimary
      }
    ];

    this.listFields = [
      {
        key: 'lang',
        type: FormFieldType.string,
        label: `${this.name}.lang`,
        sortable: true
      },
      {
        key: 'des',
        type: FormFieldType.string,
        label: `${this.name}.des`,
        sortable: true
      }
    ];

    this.detailFields = [
      {
        key: 'id',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'dtIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'userIns',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'classeId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        label: `${this.name}.lang`,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
        },
        validators: [Validators.required],
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        label: `${this.name}.des`,
        validators: [Validators.required],

      },
    ];

    this.searchFields = [
      {
        key: 'classeId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'classeId',
          searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'search',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.simpleSearchPlaceholder`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.full
      },
    ];

    this.searchAdvancedFields = [
      {
        key: 'classeId',
        type: FormFieldType.number,
        typeHtml: FormFieldTypeHtml.hidden,
        options: {
          searchFields: 'classeId',
          searchCompOp: ComparisonOperator.Equals
        }
      },
      {
        key: 'lang',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.select,
        placeholder: `${this.name}.lang`,
        options: {
          data: this.attributes['genlang'],
          dataKey: 'lang',
          dataLabel: 'des',
          searchFields: 'lang',
          searchCompOp: ComparisonOperator.Equals,
        },
        class: FormFieldStyle.default
      },
      {
        key: 'des',
        type: FormFieldType.string,
        typeHtml: FormFieldTypeHtml.text,
        placeholder: `${this.name}.des`,
        options: {
          searchFields: 'des',
          searchCompOp: ComparisonOperator.Contains,
        },
        class: FormFieldStyle.default
      },
    ];
  }
}
