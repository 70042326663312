import { Component, HostBinding, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-field-multiselect',
  templateUrl: './search-field-multiselect.component.html',
  styleUrls: ['./search-field-multiselect.component.scss']
})
export class SearchFieldMultiselectComponent {
  private opt: unknown[];

  @Input()
  set options(value: unknown[]) {
    this.opt = value ?? [];
    this.virtualScroll = this.opt.length > 100
  };
  get options(): unknown[] {
    return this.opt;
  };

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  optionValue: string = 'id';

  @Input()
  optionLabel: string = 'des';

  @Input()
  placeholder: string = '';

  @Input()
  selectedItemsLabel: string = '';

  @Input()
  maxSelectedLabels: number = 1;

  @Input()
  virtualScroll: boolean = false;

  @Input()
  showToggleAll: boolean = true;

  @Input()
  defaultValue: [] = [];

  @Input()
  styleClass: string = 'search-field-size-base';

  @HostBinding('class') get class() {
    return this.styleClass;
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.formGroup.get(controlName)?.reset(defaultValue);
  }
}
