<ng-container *ngIf="T$ | async as T">
  <ng-container
    *ngIf="abilitazioniGiud$ | async | filterbypar : filterEnabled || null : 'flAbilitato' as abGrGiud; else loading">
    <!-- Toolbar -->
    <div class="flex flex-row justify-content-between pb-2 sticky bg-white top-0 z-2">
      <div class="my-auto">
        <span *ngIf="flAllRounder" class="warning">
          <i class="fa-solid fa-star mr-2" [title]="T | translateItem: 'giudici.allrounder'"></i>{{T | translateItem:
          'giudici.allrounder'}}
        </span>
      </div>

      <div class="buttons-cnt">
        <button pButton type="button" class="p-button-sm " (click)="toggleFilter()"
          [title]="T | translateItem: filterEnabled ? 'giudici.filtraAbilitazioniPossedute' : 'giudici.filtraTutteAbilitazioni'"
          [label]="T | translateItem: filterEnabled ? 'giudici.filtraAbilitazioniPossedute' : 'giudici.filtraTutteAbilitazioni'"
          [icon]="filterEnabled ? 'fa fa-filter' : 'fa fa-filter-circle-xmark'"
          [ngClass]="{'p-button-primary':filterEnabled,'p-button-outlined':!filterEnabled}"></button>
      </div>
    </div>
    <div *ngIf="abGrGiud.length > 0; else noAbilitazioni" class="gen-list-cnt">
      <div class="grid mt-0">
        <!-- Selettore gruppi -->
        <div class="hidden md:block md:col-3">
          <div *ngFor="let gr of abGrGiud; let i = index; let last = last"
            class="flex flex-row align-items-center justify-content-between p-3 gap-2 sidebar-button"
            [class.mb-2]="!last"
            [ngClass]="{'badge-outlined-gray': !gr.flAbilitato && gruppoSel?.cod !== gr.cod,'badge-outlined-primary': gr.flAbilitato && gruppoSel?.cod !== gr.cod,'badge-primary': gruppoSel?.cod === gr.cod, 'cursor-pointer': !gr.flNoAbilitazioniDetail, 'pointer-events-none': gr.flNoAbilitazioniDetail}"
            (click)="selectGroup(gr)">
            <div class="flex align-items-center gap-2">
              <i class="fa fa-circle-check"
                [ngClass]="{'gray': !gr.flAbilitato, 'primary': gr.flAbilitato && !gr.flAbilitazioneCompleta && gruppoSel?.cod != gr.cod, 'success': gr.flAbilitato && gr.flAbilitazioneCompleta}"
                [pTooltip]="T | translateItem: (gr.flAbilitazioneCompleta ? 'giudici.abilitazioneCompleta' : gr.flAbilitato ? 'giudici.abilitazioneParziale' : 'giudici.noAbilitazione')"></i>
              <span class="font-medium">{{gr.des}}
              </span>
            </div>
            <i *ngIf="!gr.flNoAbilitazioniDetail" class="fa-solid fa-chevron-right"
              [class.primary]="gruppoSel?.cod != gr.cod"></i>
          </div>
        </div>

        <!-- Selettore gruppimobile -->
        <div class="col-12 md:hidden">
          <p-dropdown [options]="abGrGiud" optionLabel="des" class="p-inputtext-sm mobile-giudice-selector"
            [ngModel]="gruppoSel" [placeholder]="T | translateItem: 'giudici.selezionaElementoLista'"
            (onChange)="onChangeGiudiceDropdown($event)" optionDisabled="flNoAbilitazioniDetail">
            <ng-template let-gr pTemplate="item">
              <div class="flex align-items-center gap-2">
                <i class="fa fa-circle-check"
                  [ngClass]="{'gray': !gr.flAbilitato, 'primary': gr.flAbilitato && !gr.flAbilitazioneCompleta, 'success': gr.flAbilitato && gr.flAbilitazioneCompleta}"
                  [pTooltip]="T | translateItem: (gr.flAbilitazioneCompleta ? 'giudici.abilitazioneCompleta' :'giudici.abilitazioneParziale')"></i>
                <span class="font-medium">
                  {{gr.des}}
                </span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>

        <!-- Abilitazioni -->
        <div class=" col-12 md:col-9">

          <!-- Seleziona gruppo -->
          <p *ngIf="!gruppoSel" class="font-medium text-center p-3 text-primary">
            {{T | translateItem: 'giudici.selezionaElementoLista'}}
          </p>

          <!-- Nessuna abilitazione -->
          <p *ngIf="gruppoSel && !gruppoSel?.abilitazioniDetailList?.length"
            class="font-medium text-center p-3 text-600">
            {{T | translateItem: 'giudici.abilitazioniListEmpty'}}
          </p>

          <!-- Abilitazioni -->
          <div *ngIf="gruppoSel && gruppoSel.abilitazioniDetailList?.length" class="gen-list-cnt">
            <div
              *ngFor="let ab of gruppoSel.abilitazioniDetailList | filterbypar : filterEnabled || null : 'flAbilitato'; let last = last"
              [class.mb-2]="!last" class="gruppo">
              <div class="flex justify-content-between gap-2">
                <div class="flex align-items-center gap-2 gen-cnt">
                  <i class="fa fa-circle-check" [ngClass]="{'gray': !ab.flAbilitato, 'success': ab.flAbilitato}"
                    [pTooltip]="T | translateItem: (ab.flAbilitato ? 'giudici.abilitato' :'giudici.nonAbilitato')"></i>
                  <span class="font-medium">
                    {{ab.des}}
                  </span>
                </div>
                <div>
                  <span *ngIf="ab.flAspirante; else esperto" class="badge-outlined-warning text-sm">
                    {{T | translateItem: 'giudici.aspirante'}}
                  </span>

                  <ng-template #esperto>
                    <span *ngFor="let abTipo of ab.abilitazioniTipoList; let last = last" [class.mr-2]="!last"
                      class="badge-outlined-info text-sm text-primary">
                      {{abTipo | titlecase}}
                    </span>
                  </ng-template>
                </div>
              </div>
              <ng-container *ngIf="ab.subAbilitazioni.length > 0">
                <p-divider></p-divider>
                <div *ngFor="let subAb of ab.subAbilitazioni;let last = last"
                  class="flex justify-content-between gap-2 align-items-center" [class.mb-2]="!last">
                  <div>{{subAb.des}}</div>

                  <div class="flex gap-3 align-items-center">
                    <span *ngIf="ab.flAspirante" class="badge-outlined-warning text-sm">
                      {{T | translateItem: 'giudici.aspirante' }}
                    </span>
                    <span>{{subAb.dataAbilitazione| date: dateFormat}}</span>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noAbilitazioni>
      <p class="font-medium text-center p-3 text-600">
        {{T | translateItem: 'giudici.abilitazioniListEmpty'}}
      </p>
    </ng-template>

    <!-- <p-accordion *ngIf=" abGrGiud.length> 0; else noAbilitazioni">
                    <p-accordionTab *ngFor="let gr of abGrGiud; let i = index; let count = count">
                      <ng-template pTemplate="header">
                        <div class="flex align-items-center gap-2">
                          <i class="fa fa-circle-check"
                            [ngClass]="{'gray': !gr.flAbilitazioneCompleta, 'success': gr.flAbilitazioneCompleta}"
                            [pTooltip]="T | translateItem: (gr.flAbilitazioneCompleta ? 'giudici.abilitazioneGruppoCompleta' :'giudici.abilitazioneGruppoParziale')"></i>
                          <span class="text-primary font-medium">
                            <ng-container *ngIf="gr.gruppoFciId">{{gr.gruppoFciId}} - </ng-container> {{gr.des}}
                          </span>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <div *ngFor="let ab of gr.abilitazioniRazzeList; let last = last" [class.mb-2]="last">
                          <div class="flex align-items-center gap-2 ml-4">
                            <i class="fa fa-circle-check"
                              [ngClass]="{'gray': !ab.flAbilitato, 'success': ab.flAbilitato}"
                              [pTooltip]="T | translateItem: (ab.flAbilitato ? 'giudici.abilitatoRazza' :'giudici.nonAbilitatoRazza')"></i>
                            <span>
                              {{ab.razzaDes}}
                            </span>
                          </div>
                          <p-divider *ngIf="!last"></p-divider>
                        </div>
                      </ng-template>
                    </p-accordionTab>
                    </p-accordion>

                    <ng-template #noAbilitazioni>
                      <p class="font-medium text-center p-3 text-600">
                        {{T | translateItem: 'giudici.abilitazioniListEmpty'}}
                      </p>
                    </ng-template> -->
  </ng-container>

  <!-- Loading -->
  <ng-template #loading>
    <p class="font-medium text-center p-3 text-600">
      <i class="fa fa-2x fa-spin fa-spinner"></i>
    </p>
  </ng-template>
</ng-container>
