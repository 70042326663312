import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { GenClasse } from 'src/app/models/anagrafiche/gen-classe';
import { GiudiceImpegnoManiExpRazzaDto } from 'src/app/models/calendario/dto/giudice-impegno-mani-exp-razza-dto';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { VeeRazze } from 'src/app/models/vee/vee-razze';
import { BaseDataService } from 'src/app/services/base-data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertSortMetaArrayToDataSortValueArray, dateFormat, dateFormatPrNg } from 'src/app/utils/util';
import { DataService } from '../../../../../services/data.service';

@Component({
  selector: 'app-giudice-detail-impegni-mani-exp-razza',
  templateUrl: './giudice-detail-impegni-mani-exp-razza.component.html',
  styleUrls: ['./giudice-detail-impegni-mani-exp-razza.component.scss']
})
export class GiudiceDetailImpegniManiExpRazzaComponent implements OnInit, OnDestroy {
  dateFormatPrNg: string = dateFormatPrNg
  dateFormat: string = dateFormat;
  T$: Observable<Resources>;
  entities$: Observable<GiudiceImpegnoManiExpRazzaDto[]>;
  dataForm: FormGroup;
  veeRazzeList$: Observable<VeeRazze[]>;
  genClasseList$: Observable<GenClasse[]>;
  basePaginatorFirst: number = 0;
  basePaginatorRows: number = 10;
  paginatorFirst: number = this.basePaginatorFirst;
  paginatorRows: number = this.basePaginatorRows;
  dataSortValues: DataSortValue[];
  paginatorRowsTot: number;
  dataSearch: DataSearch;
  baseMultiSortMeta: SortMeta[] = [
    { field: 'giorno', order: SortMode.Asc },
    { field: 'gruppoFciId', order: SortMode.Asc },
    { field: 'razzaDes', order: SortMode.Asc }
  ];
  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];
  elaboration: boolean = false;
  advancedSearch: boolean = false;
  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'maniId', searchFields: 'caleManiId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'giorno,razzaDes,classeDes,genere', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'maniId', searchFields: 'caleManiId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'giorno', searchFields: 'giorno', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'data', searchFields: 'data', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'razzaId', searchFields: 'razzaId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'classeId', searchFields: 'classeId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true }
  ];

  @ViewChild('dataTable') dataTable: Table;

  private subscription: Subscription;

  @Input() giudiceId: number;
  @Input() maniId: number;
  @Input() tabIndex: number;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudiceDetailImpegniManiExpRazza.translateSuffixs ?? ['generic.']);

    // Filtri
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

    this.dataForm = this.fb.group({
      giudiceId: this.giudiceId,
      maniId: this.maniId,
      simpleSearch: null,
      giorno: null,
      data: null,
      razzaId: null,
      classeId: null
    });

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.elaboration = true;

    this.entities$ = this.dataService.searchElements<GiudiceImpegnoManiExpRazzaDto>(`${this.dataService.configSettings.restCalendarioUrl}/giudiceimpegnomaniexprazza`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ giudiceId: this.giudiceId, maniId: this.maniId });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.dataForm.reset({ giudiceId: this.giudiceId, maniId: this.maniId });

    // Carico le liste per la ricerca avanzata
    if (this.advancedSearch) {
      // Tipi razze
      if (!this.veeRazzeList$) {
        this.veeRazzeList$ = this.baseDataService.getVeeRazzeList();
      }

      // Classi
      if (!this.genClasseList$) {
        this.genClasseList$ = this.baseDataService.getGenClasseList();
      }
    }
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.dataForm.get(controlName)?.reset(defaultValue);
  }
}
