<div *ngIf="(theme !== 'attachPin')" id="default">

  <input type="file" id="sel{{id}}" style="display: none" (change)="onChange($event)" [title]="selectFileText"
         name="files[]" [attr.multiple]="multiple ? '' : null" />
  <div *ngIf="theme == 'dragNDrop'" id="dragNDrop" [ngClass]="(hideSelectBtn && hideResetBtn) ? null : 'dragNDropBtmPad'" class="dragNDrop">
    <div style="position:relative;">
      <div id="div1" class="div1 afu-dragndrop-box" (drop)="drop($event)" (dragover)="allowDrop($event)">
        <p class="afu-dragndrop-text">{{dragAndDropText}}</p>
      </div>

      <div class="flex align-items-center flex-wrap">
        <div class="flex align-items-center justify-content-center mr-2">
          <button type="button" pButton class='p-button-outlined p-button-sm' (click)="attachpinOnclick()"
                  icon="fa-solid fa-paperclip" [title]="attachPinText">
          </button>
        </div>
        <div class="flex align-items-center justify-content-center mr-2">
          <p class="constraints-info afu-constraints-info">({{formatsAllowed}}) {{sizeLimitText}} - {{(convertSize(maxSize *1024000))}}</p>
        </div>
      </div>
    </div>
  </div>

  <label for="sel{{id}}" class="btn btn-primary btn-sm afu-select-btn" *ngIf="!hideSelectBtn">{{selectFileText}}<span *ngIf="multiple">s</span></label>
  <button class="btn btn-info btn-sm resetBtn afu-reset-btn" (click)="resetFileUpload()" *ngIf="!hideResetBtn">{{resetText}}</button>
  <br *ngIf="!hideSelectBtn">

  <!--Selected file list-->
  <div class="flex align-items-center flex-wrap afu-valid-file" *ngFor="let sf of selectedFiles;let i=index">
    <div class="flex align-items-center justify-content-center mr-1">
      <p class="textOverflow"><span class="text-primary">{{sf.name}}</span></p>
    </div>
    <div class="flex align-items-center justify-content-center mr-1">
      <strong>({{convertSize(sf.size)}})</strong>
    </div>
    <div class="flex align-items-center justify-content-center mr-1">
      <a role="button" (click)="removeFile(i,'sf')" *ngIf="uploadClick"><i class="fa fa-times"></i></a>
    </div>
    <div class="flex align-items-center justify-content-center mr-1 progress padMarg afu-progress-bar" *ngIf="singleFile && progress">
      <span class="progress-bar progress-bar-success" role="progressbar" [ngStyle]="{'width':(progress[sf.name].progress | async)+'%'}">{{progress[sf.name].progress | async}}%</span>
    </div>
  </div>
  <!--Invalid file list-->
  <div class="flex align-items-center flex-wrap afu-invalid-file" *ngFor="let na of notAllowedList;let j=index">
    <div class="flex align-items-center justify-content-center mr-1">
      <p class="md:col8 textOverflow"><span>{{na['fileName']}}</span></p>
    </div>
    <div class="flex align-items-center justify-content-center mr-1">
      <strong>({{na['fileSize']}})</strong>
    </div>
    <div class="flex align-items-center justify-content-center mr-1">
      <a class="delFileIcon" role="button" (click)="removeFile(j,'na')" *ngIf="uploadClick">&nbsp;<i class="fa fa-times"></i></a>
    </div>
    <div class="flex align-items-center justify-content-center mr-1">
      <p>{{na['errorMsg']}}</p>
    </div>
  </div>

  <p *ngIf="uploadMsg" class="{{uploadMsgClass}} + afu-upload-status">{{uploadMsgText}}</p>
  <div *ngIf="!singleFile && progress">
    <div class="progress col-4 padMarg afu-progress-bar">
      <span *ngIf="progress" class="progress-bar progress-bar-success" role="progressbar" [ngStyle]="{'width':(progress[file.name].progress | async)+'%'}">{{progress[file.name].progress | async}}%</span>
    </div>
  </div>
  <!--<button class="btn btn-success afu-upload-btn" type="button" (click)="uploadFiles()" [disabled]=!uploadBtn>{{uploadBtnText}}</button>-->
  
</div>
