import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    AbstractSecurityStorage, AuthInterceptor, AuthModule, DefaultLocalStorageService,
    OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader
} from 'angular-auth-oidc-client';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConfigSettings, DataService } from '../services/data.service';


export const stsHttpAuthLoaderFactory = (httpClient: HttpClient, dataService: DataService) => {
    const config$ = httpClient.get<any>(`${environment.restCommConfigUrl}/config`)
    .pipe(
        map((configSettings: any) => {
            dataService.configSettings = configSettings;
            return buildOpenIdConfiguration(configSettings);          
        })
    );
    return new StsConfigHttpLoader(config$);
};

export function buildOpenIdConfiguration(configSettings: ConfigSettings): OpenIdConfiguration
{
    const origin = window.location.origin;
    const config: OpenIdConfiguration = {
        authority: configSettings.restIdentityUrl,
        // configId: 'encimainapp',
        clientId: 'encimainapp',
        scope: 'openid profile email offline_access common_api calendario_api',
        responseType: 'code',
        redirectUrl: `${origin}/callback`,
        postLoginRoute: '/',
        postLogoutRedirectUri: `${origin}/`,
        unauthorizedRoute: '/unauthorized',
        autoUserInfo: true,
        secureRoutes: [`${configSettings.restIdentityUrl}/`, `${configSettings.restCommonUrl}/`, `${configSettings.restCalendarioUrl}/`],
        silentRenew: true,
        useRefreshToken: true,
    };
    return config;
}

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: stsHttpAuthLoaderFactory,
                deps: [ HttpClient, DataService ]
            }
        })
    ],
    providers: [
        { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    exports: [AuthModule]
})
export class AuthConfigModule {
}
