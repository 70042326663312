
/**
 * FileUpload object, contains info about file to upload
 *
 *
 */
export interface IEntity {
  isFormData: boolean;
  id: number;
  userIns?: string;
  dtIns?: Date;
  userUpd?: string;
  dtUpd?: Date;
}

export class Entity implements IEntity {
  isFormData: boolean = false;
  id: number = 0;
  userIns?: string;
  dtIns?: Date;
  userUpd?: string;
  dtUpd?: Date;
}

export interface IEntityList<T> {
  entities: T[];
  numRowsTot: number;
}
