<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm">
    <!-- Toolbar -->
    <div *ngIf="!readOnly" class="flex flex-row justify-content-end pb-2 sticky bg-white top-0 z-2">
      <div class="buttons-cnt">
        <button pButton type="button" class="p-button-sm" icon="fa-solid fa-plus" [disabled]="elaboration"
          [label]="T | translateItem: 'generic.add'" [title]="T | translateItem: 'generic.add'" (click)="newAllegato()"
          [ngClass]="{'p-button-primary': dataForm.pristine,'p-button-outlined': !dataForm.pristine}"></button>

        <button pButton type="button" [label]="T | translateItem: 'generic.save'"
          [title]="T | translateItem: 'generic.save'" class="p-button-sm" icon="fa-solid fa-floppy-disk"
          [disabled]="elaboration || dataForm.pristine || !dataForm.valid" (click)="save()"
          [ngClass]="{'p-button-primary': !dataForm.pristine,'p-button-outlined': dataForm.pristine }"></button>
      </div>
    </div>

    <loading-spinner [showSpinner]="loadingSpinnerVisible">
      <div *ngIf="allegatiListControls?.length > 0; else emptylist" formArrayName="allegatiList"
        class="striped-fieldsets">
        <div *ngFor="let allegato of allegatiListControls; let i = index let count = count">
          <p-fieldset [toggleable]="true" [collapsed]="allegato.get('id').value > 0">
            <ng-template pTemplate="header">
              <div class="flex align-items-center flex-wrap">
                <div class="flex align-items-center justify-content-center">
                  <button *ngIf="allegato.get('id').value" pButton type="button"
                    class="p-button-outlined p-button-sm mr-2"
                    (click)="downloadAttach(allegato.value); $event.stopPropagation();"
                    [disabled]="elaboration || !allegato.pristine" icon="fa-solid fa-download"
                    [title]="T | translateItem: 'attachments.download'"></button>
                </div>
                <div class="flex align-items-center justify-content-center">
                  <span>{{allegato.get('id').value === 0 ? (T | translateItem: 'genallegato.allegatoTitleNew' ) :
                    ((T | translateItem: 'genallegato.allegatoTitle') + (' ' +
                    (allegato.get('nomeFile').value)))}}</span>
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="content">
              <!-- Edit -->
              <ng-container *ngIf="!readOnly; else output">
                <div [formGroupName]="i" class="formgrid grid">
                  <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                    <label class="text-sm required">{{T | translateItem: 'genallegato.tipoallegato'}}</label>
                    <p-dropdown [options]="tipiAllegatiList$ | async" formControlName="allegatoTipoId"
                      [placeholder]="T | translateItem: 'genallegato.tipoallegato'" optionValue="id" optionLabel="des"
                      class="inputfield p-inputtext-sm" [emptyMessage]="T | translateItem: 'generic.dropdownEmpty'">
                    </p-dropdown>
                  </span>

                  <span class="field col-12 sm:col-6 md:col-8 lg:col-10">
                    <label class="text-sm">{{T | translateItem: 'genallegato.nota'}}</label>
                    <input pInputTextarea formControlName="nota" [placeholder]="T | translateItem: 'genallegato.nota'"
                      class="inputfield p-inputtext-sm">
                  </span>

                  <span class="field col-12">
                    <label class="text-sm required">{{T | translateItem: 'genallegato.allegato'}}</label>
                    <angular-file-uploader [config]="afuConfig" (DropComplete)="DropComplete($event)" [Id]="extId"
                      [Index]="i" [displaypinlabel]="T | translateItem: 'attachments.displaypinlabel'"
                      [uploadbtnlabel]="T | translateItem: 'attachments.uploadbtnlabel'"
                      [draganddroplabel]="T | translateItem: 'attachments.draganddroplabel'"
                      [sizelimitlabel]="T | translateItem: 'attachments.sizelimitlabel'"
                      [resetlabel]="T | translateItem: 'attachments.resetlabel'"
                      [selectfilelabel]="T | translateItem: 'attachments.selectfilelabel'"
                      [invalidformatlabel]="T | translateItem: 'attachments.invalidformatlabel'"
                      [invalidsizelabel]="T | translateItem: 'attachments.invalidsizelabel'"
                      [uploadsuccesslabel]="T | translateItem: 'attachments.uploadsuccesslabel'"
                      [uploadfaillabel]="T | translateItem: 'attachments.uploadfaillabel'">
                    </angular-file-uploader>
                  </span>
                </div>
                <div class="flex flex-row justify-content-end">
                  <div class="buttons-cnt">
                    <button *grantVisible="grants; key 'viewlog'" pButton type="button"
                      class="p-button-outlined p-button-sm" (click)="modalEntityLogShow(allegato.get('id').value)"
                      [disabled]="elaboration || !allegato.get('id').value" icon="fa-solid fa-magnifying-glass-chart"
                      [title]="T | translateItem: 'generic.viewlog'"></button>

                    <button pButton type="button" class="p-button-sm p-button-outlined p-button-danger"
                      [disabled]="elaboration" (click)="deleteAllegato(i)" [title]="T | translateItem: 'generic.delete'"
                      icon="fa-solid fa-trash"></button>
                  </div>
                </div>
              </ng-container>

              <!-- ReadOnly -->
              <ng-template #output>
                <div class="formgrid grid">
                  <span class="field col-12 sm:col-6 md:col-4 lg:col-2">
                    <label class="text-sm required">{{T | translateItem: 'genallegato.tipoAllegato'}}</label>
                    <span class="block font-medium mt-1">{{allegato.get('allegatoTipoDes').value}}</span>
                  </span>

                  <span class="field col-12 sm:col-6 md:col-8 lg:col-10">
                    <label class="text-sm">{{T | translateItem: 'genallegato.nota'}}</label>
                    <span class="block font-medium mt-1">{{allegato.get('nota').value}}</span>
                  </span>

                  <span class="field col-12">
                    <label class="text-sm required">{{T | translateItem: 'genallegato.allegato'}}</label>
                  </span>
                </div>
                <div class="flex flex-row justify-content-end">
                  <div class="buttons-cnt">
                    <button *grantVisible="grants; key 'viewlog'" pButton type="button"
                      class="p-button-outlined p-button-sm" (click)="modalEntityLogShow(allegato.get('id').value)"
                      [disabled]="elaboration || !allegato.get('id').value" icon="fa-solid fa-magnifying-glass-chart"
                      [title]="T | translateItem: 'generic.viewlog'"></button>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </p-fieldset>
        </div>
      </div>

      <ng-template #emptylist>
        <p class="font-medium text-center p-3 text-600">
          {{T | translateItem: 'generic.listEmpty'}}
        </p>
      </ng-template>
    </loading-spinner>
  </form>
</ng-container>