import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuItem, SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { TipoCalCod } from 'src/app/models/anagrafiche/calendario/cal-tipo-cal';
import { CalTipoMani } from 'src/app/models/anagrafiche/calendario/cal-tipo-mani';
import { GiudiceImpegnoManiExpDto } from 'src/app/models/calendario/dto/giudice-impegno-mani-exp-dto';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { EntityManagerGrant } from 'src/app/models/entity-config';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { ListExportService } from 'src/app/services/list-export.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertSortMetaArrayToDataSortValueArray, dateFormat, dateFormatPrNg } from 'src/app/utils/util';
import { DataService } from '../../../../../services/data.service';

@Component({
  selector: 'app-giudice-detail-impegni-mani-exp',
  templateUrl: './giudice-detail-impegni-mani-exp.component.html',
  styleUrls: ['./giudice-detail-impegni-mani-exp.component.scss']
})
export class GiudiceDetailImpegniManiExpComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  dateFormatPrNg: string = dateFormatPrNg;
  T$: Observable<Resources>;
  resourceList: Resources;
  entities$: Observable<GiudiceImpegnoManiExpDto[]>;
  dataForm: FormGroup;
  basePaginatorFirst: number = 0;
  basePaginatorRows: number = 10;
  paginatorFirst: number = this.basePaginatorFirst;
  paginatorRows: number = this.basePaginatorRows;
  dataSortValues: DataSortValue[];
  paginatorRowsTot: number;
  dataSearch: DataSearch;
  baseMultiSortMeta: SortMeta[] = [
    { field: 'dataInizio', order: SortMode.Asc },
    { field: 'dataFine', order: SortMode.Asc }
  ];
  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];
  calTipoManiList$: Observable<CalTipoMani[]>;
  elaboration: boolean = false;
  advancedSearch: boolean = false;
  modalListExport: { visible: boolean, data: any } = { visible: false, data: undefined };
  grants$: Observable<EntityManagerGrant>;
  exportMenuItems: MenuItem[];
  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: false },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'tipoManiDes,luogoLabel', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'giudiceId', searchFields: 'giudiceId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'dataInizioLimite', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataInizio', searchFields: 'dataInizio', type: SearchFieldType.date, searchCompOp: ComparisonOperator.GreaterThanOrEqual, advancedSearch: true },
    { key: 'dataFineLimite', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'dataFine', searchFields: 'dataFine', type: SearchFieldType.date, searchCompOp: ComparisonOperator.LessThanOrEqual, advancedSearch: true },
    { key: 'tipoManiId', searchFields: 'tipoManiId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'luogoLabel', searchFields: 'luogoLabel', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'flGiudiceAssegnato', searchFields: 'flGiudiceAssegnato', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true }
  ];

  @ViewChild('dataTable') dataTable: Table;

  private subscription: Subscription;

  @Input() giudiceId: number;
  @Input() maniId: number;
  @Input() dataInizioLimite: Date;
  @Input() dataFineLimite: Date;

  @Input() tabIndex: number;

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private listExportService: ListExportService,
    private appManagerService: AppManagerService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudiceDetailImpegniManiExp.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Recupera le grants di questa sezione
    this.grants$ = this.appManagerService.getGrants(AppManagerRoutes.GiudiceDetailImpegniManiExp.section ?? AppManagerRoutes.GiudiceDetailImpegniManiExp.id);

    // Filtri
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

    this.dataForm = this.fb.group({
      giudiceId: this.giudiceId,
      dataInizioLimite: this.dataInizioLimite,
      dataInizio: null,
      dataFineLimite: this.dataFineLimite,
      dataFine: null,
      simpleSearch: null,
      tipoManiId: null,
      luogoLabel: null,
      flGiudiceAssegnato: false
    });

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.elaboration = true;

    this.entities$ = this.dataService.searchElements<GiudiceImpegnoManiExpDto>(`${this.dataService.configSettings.restCalendarioUrl}/giudiceimpegnomaniexp`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  toggleSearch(): void {
    this.advancedSearch = !this.advancedSearch;
    this.dataForm.reset({ giudiceId: this.giudiceId, dataInizioLimite: this.dataInizioLimite, dataFineLimite: this.dataFineLimite });

    // Carico le liste per la ricerca avanzata
    if (this.advancedSearch) {
      // Tipi manifestazioni
      if (!this.calTipoManiList$) {
        this.calTipoManiList$ = this.baseDataService.getCalTipoManiList().pipe(
          map(value => value.filter(v => v.tipoCalCod === TipoCalCod.esposizioni))
        );
      }
    }
  }

  resetFilterControl(controlName: string, defaultValue: [] | null = null): void {
    this.dataForm.get(controlName)?.reset(defaultValue);
  }

  modalListExportShow(entityName: string): void {
    this.listExportService.openModal(entityName, this.dataSearch, 'Dto');
  }

  modalListExportHide(): void {
    this.modalListExport.data = undefined;
    this.modalListExport.visible = false;
  }

  setExportMenuItems(): void {
    const menuItems = [
      {
        label: this.translateService.translate(this.resourceList, 'giudiciimpegnimaniexp.exportImpegniRazze'), title: this.translateService.translate(this.resourceList, 'giudiciimpegnimaniexp.exportImpegniRazze'), icon: 'fa-solid fa-file-arrow-down', command: () => { this.modalListExportShow('GiudiceImpegnoManiExpRazzaExportDto') }
      },
      {
        label: this.translateService.translate(this.resourceList, 'giudiciimpegnimaniexp.exportImpegniRaggruppamenti'), title: this.translateService.translate(this.resourceList, 'giudiciimpegnimaniexp.exportImpegniRaggruppamenti'), icon: 'fa-solid fa-file-arrow-down', command: () => { this.modalListExportShow('GiudiceImpegnoManiExpRaggruppamentoExportDto') }
      }
    ];

    this.exportMenuItems = menuItems;
  }
}
