import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { Observable, isObservable, of } from "rxjs";
import { catchError, map, startWith } from "rxjs/operators";
import { CalCaleManiGiud } from "../models/calendario/cal-cale-mani-giud";
import { GiornoDto } from "../models/calendario/dto/giudice-impegno-giorno-dto";
import { CmdField, EntityManager, FormField } from "../models/entity-config";
import { EntityRefresh } from "../services/entity.service";
import { Resources, TranslateService } from "../services/translate.service";
import { addTimezoneOffsetToDate, compare, isNullOrUndefined, lookupEnum } from "../utils/util";

@Pipe({
  name: 'replaceNull'
})
export class ReplaceNullPipe implements PipeTransform {
  transform(value: string, replaceString: string): string {
    if (!value) {
      return replaceString;
    }
    return value;
  }
}

@Pipe({
  name: 'enumDesc'
})
export class EnumDescriptionPipe implements PipeTransform {
  transform(value: number | string, args?: any): string | undefined {
    let pEnum: string;
    if (isNullOrUndefined(args))
      return undefined;

    pEnum = args.pEnum;
    return lookupEnum(pEnum, value);
  }
}

// @Pipe({
//   name: 'entityInfo'
// })
// export class EntityInfoPipe implements PipeTransform {

//   transform(msg: string, args?: any): string {
//     if (isNullOrUndefined(msg) || msg.length === 0)
//       return undefined;

//     let entityConfig: EntityConfig = args;
//     let infoArgs = entityConfig.getEntityInfoArgs(entityConfig.entity);
//     let result = msg;
//     Object.keys(infoArgs).forEach(key => result = result.replace(`{{${key}}}`, infoArgs[key]));
//     return result;
//   }
// }

// @Pipe({
//   name: 'customInfo'
// })
// export class CustomInfoPipe implements PipeTransform {

//   transform(msg: string, args?: any): string {
//     if (isNullOrUndefined(msg) || msg.length === 0)
//       return undefined;

//     let entityConfig: EntityConfig = args.listDetail;
//     if (isNullOrUndefined(entityConfig))
//       return '';

//     let data: any = args.data;
//     if (isNullOrUndefined(data))
//       return '';

//     let infoArgs = entityConfig.getEntityInfoArgs(data);
//     let result = msg;
//     Object.keys(infoArgs).forEach(key => result = result.replace(`{{${key}}}`, infoArgs[key]));
//     return result;
//   }
// }

@Pipe({
  name: 'refreshing'
})
export class RefreshingPipe implements PipeTransform {
  transform(entityRefresh: EntityRefresh): boolean {
    return entityRefresh ? entityRefresh.refreshing : false;
  }
}

@Pipe({
  name: 'cmdFields'
})
export class CmdFieldsPipe implements PipeTransform {
  transform(entityManager: EntityManager, args?: any): CmdField[] | undefined {
    if (isNullOrUndefined(entityManager))
      return undefined;

    if (isNullOrUndefined(args))
      return undefined;

    let fieldTarget = args.fieldTarget;
    let fieldType = args.fieldType;
    return entityManager.getCmdFields(fieldTarget, fieldType);
  }
}

@Pipe({
  name: 'cmdField'
})
export class CmdFieldPipe implements PipeTransform {
  transform(entityManager: EntityManager, args?: any): CmdField | undefined {
    if (isNullOrUndefined(entityManager))
      return undefined;

    if (isNullOrUndefined(args))
      return undefined;

    let fieldTarget = args.fieldTarget;
    let fieldType = args.fieldType;
    let fieldKey = args.fieldKey;

    if (isNullOrUndefined(fieldTarget) || isNullOrUndefined(fieldType) || isNullOrUndefined(fieldKey))
      return undefined;

    return entityManager.getCmdField(fieldTarget, fieldType, fieldKey);
  }
}

@Pipe({
  name: 'isCmdFieldActive'
})
export class IsCmdFieldActivePipe implements PipeTransform {
  transform(entityRefresh: EntityRefresh, field: CmdField): string {
    if (!entityRefresh) return '';
    return entityRefresh.event === field ? 'is-loading' : '';
  }
}

@Pipe({
  name: 'isCmdFieldDisabled'
})
export class IsCmdFieldDisabledPipe implements PipeTransform {
  transform(entityManager: EntityManager, field: CmdField, args?: any): boolean {
    return entityManager.isCmdFieldDisabled(field, args);
  }
}

@Pipe({
  name: 'isCmdFieldVisible'
})
export class IsCmdFieldVisiblePipe implements PipeTransform {
  transform(entityManager: EntityManager, field: CmdField, args?: any): boolean {
    return entityManager.isCmdFieldVisible(field, args);
  }
}

@Pipe({
  name: 'isFormFieldVisible'
})
export class IsFormFieldVisiblePipe implements PipeTransform {
  transform(entityManager: EntityManager, field: FormField, args?: any): boolean {
    return entityManager.isFormFieldVisible(field, args);
  }
}

@Pipe({
  name: 'translateItem'
})
export class TranslateItemPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(translateItems: Resources, key: string, args?: any): string {
    return this.translateService.translate(translateItems, key, args);
  }
}

// @Pipe({
//   name: 'formFieldValue'
// })
// export class FormFieldValuePipe implements PipeTransform {

//   transform(entityManager: EntityManager, args?: any): string {
//     if (isNullOrUndefined(entityManager))
//       return undefined;

//     if (isNullOrUndefined(args))
//       return undefined;

//     let field: FormField = args.field;
//     let data = args.data;

//     if (isNullOrUndefined(field) || isNullOrUndefined(data))
//       return undefined;

//     return entityManager.getFormFieldValue(field, data);
//   }
// }

// @Pipe({
//   name: 'hasCmdField'
// })
// export class HasCmdFieldPipe implements PipeTransform {
//   transform(entityManager: EntityManager, args?: any): boolean {
//     if (isNullOrUndefined(entityManager))
//       return undefined;

//     if (isNullOrUndefined(args))
//       return undefined;

//     let fieldType = args.fieldType;
//     let fieldTarget = args.fieldTarget;
//     let fieldKey = args.fieldKey;

//     if (isNullOrUndefined(fieldType) || isNullOrUndefined(fieldTarget))
//       return undefined;

//     return entityManager.hasCmdField(fieldType, fieldTarget, fieldKey);
//   }
// }

@Pipe({
  name: 'distinctKey'
})
export class DistinctKeyPipe implements PipeTransform {
  transform(data: any[], args?: any): string[] | number[] | undefined {
    if (isNullOrUndefined(data) || data.length === 0)
      return undefined;

    if (isNullOrUndefined(args))
      return undefined;

    let key: string = args.key;

    return [...new Set(data.map(t => t[key]))];
  }
}

@Pipe({
  name: 'filterKey'
})
export class FilterKeyPipe implements PipeTransform {
  transform(data: any[], args?: any): any[] | undefined {
    if (isNullOrUndefined(data) || data.length === 0)
      return undefined;

    if (isNullOrUndefined(args))
      return undefined;

    let key: string = args.key;
    let val: string | number = args.val;
    return data.filter(t => t[key] === val);
  }
}

@Pipe({
  name: 'filterKeyForm'
})
export class FilterKeyFormPipe implements PipeTransform {
  transform(data: any[], args?: any): any[] | undefined {
    if (isNullOrUndefined(data) || data.length === 0)
      return undefined;

    if (isNullOrUndefined(args))
      return undefined;
    return data.filter(t => t.value[args.key] === args.val);
  }
}

@Pipe({
  name: 'sortKey'
})
export class SortKeyPipe implements PipeTransform {
  transform(data: any[], key?: string, sortMode = 1) {
    if (!key) {
      return data.sort((data1, data2) => {
        return compare(data1, data2, sortMode)
      });
    }
    else {
      return data.sort((data1, data2) => {
        return compare(data1[key], data2[key], sortMode);
      });
    }
  }
}

@Pipe({
  name: 'YesNo'
})
export class YesNoPipe implements PipeTransform {
  transform(value: number | string | boolean, args?: any): string {
    return this.getYesNo(value);
  }

  getYesNo(value: number | string | boolean, args?: any): string {
    let format: string = '';
    if (!isNullOrUndefined(args)) {
      format = args.format;
    }

    let result: string = (value === 1 || value === '1') ? 'Si' : 'No';
    if ('UC' === format)
      result = result.toLowerCase();
    if ('LC' === format)
      result = result.toUpperCase()

    return result;
  }
}

@Pipe({
  name: 'isObservable'
})
export class IsObservablePipe implements PipeTransform {
  transform(value: Observable<any> | any): boolean {
    return isObservable(value);
  }
}

// @Pipe({
//   name: 'fillString'
// })
// export class FillStringPipe implements PipeTransform {
//   transform(str: string, args?: any): string {
//     if (!str || !args)
//       return undefined;

//     if(args.length == 0)
//       return str;

//     const pattern: RegExp = new RegExp('{{(\w\d_-)}}', 'gi');
//     const arr = str.split(pattern);

//     // let arrPattern: RegExp[] = [];
//     // strHighlight.split(" ").forEach(t => arrPattern.push(new RegExp(t, 'gi')));
//     // if(arrPattern.length > 0){
//     //   arrPattern.forEach(pattern => result = result.replace(pattern, `<span class="highlight">${pattern.source}</span>`));
//     // }

//     return str;
//   }
// }

@Pipe({
  name: 'isRequiredField'
})
export class IsRequiredFieldPipe implements PipeTransform {
  constructor() {
  }

  transform(field: FormField): boolean {
    if (Array.isArray(field?.validators)) {
      return field?.validators.some((v) => { return v.name === 'required'; });
    }
    else {
      return field?.validators?.name === 'required';
    }
  }
}

@Pipe({
  name: 'countNull'
})
export class CountNullPipe implements PipeTransform {
  transform(items: any[], par: string): any {
    if (!items || !par) {
      return items;
    }
    return items.filter(item => !item[par]).length;
  }
}

@Pipe({
  name: 'filterbypar',
  pure: false
})
export class FilterByParPipe implements PipeTransform {
  transform(items: any[], filter: any, par: string): any {
    if (!items || filter === null || filter === undefined || !par) {
      return items;
    }
    return items.filter(item => item[par] == filter);
  }
}

@Pipe({
  name: 'filterbymultiplepar',
  pure: false
})
export class FilterByMultipleParPipe implements PipeTransform {
  transform(items: any[], filters: { filter: string, par: string }[], emptyIfNoMatchingAllFilters: boolean = false): any {

    if ((!items || filters === null || filters === undefined || filters.some(f => f.par === null || f.par === undefined)) && emptyIfNoMatchingAllFilters) {
      return [];
    }

    if ((!items || filters === null || filters === undefined) && !emptyIfNoMatchingAllFilters) {
      return items;
    }

    return items.filter(item => filters.every(f => item[f.filter] == f.par));
    //filters.reduce((m, f) => item[f.par] == f.filter, false)
  }
}

@Pipe({
  name: 'includes',
  pure: false
})
export class IncludesPipe implements PipeTransform {
  transform(items: any[], val: any): boolean {
    if (!items || !val === null || val === undefined) {
      return false;
    }
    return items.includes(val);
  }
}

@Pipe({
  name: 'filterallstring',
  pure: false
})
export class FilterAllStringPipe implements PipeTransform {
  transform(items: any[], filter: string): any {
    if (!items || !filter) {
      return items;
    }
    var ret = items.filter(o => {
      for (var key in o) {
        if (o.hasOwnProperty(key) && (typeof o[key] === 'string' || o[key] instanceof String)) {
          if (o[key].toLowerCase().includes(filter.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
    return ret;
  }
}

// @Pipe({
//   name: 'filterformcontrols',
//   pure: false
// })
// export class FilterFormControlsPipe implements PipeTransform {
//   transform(items: any[], filters: { col: string, colType: string, value: any, propName?: string }[]): any {
//     return items.filter(o => {
//       return filters.every(f => {
//         if (f.colType === 'array' && Array.isArray(f.value)) {
//           if (f.value.length === 0) {
//             return true;
//           }

//           return f.value.reduce((acc, v) => {
//             const found = o.controls[f.col].value.findIndex(t => (f.propName && t[f.propName] === v[f.propName]) || (!f.propName && t === v));
//             return acc = found >= 0 || acc;
//           }, false);
//         }
//         else if (f.colType === 'array') {
//           if (!f.value) {
//             return true;
//           }
//           return o.controls[f.col].value.includes(f.value);
//         }
//         else if (f.colType === 'boolean') {
//           if (!f.value) {
//             return true;
//           }
//           return o.controls[f.col].value && f.value;
//         }
//         else {
//           if (!f.value) {
//             return true;
//           }

//           return o.controls[f.col].value.toLowerCase().includes(f.value.toLowerCase());
//         }
//       });
//     });
//   }
// }

// @Pipe({
//   name: 'filterformcontrols',
//   pure: false
// })
// export class FilterFormControlsPipe implements PipeTransform {
//   transform(items: any[], filter: any, col: string): any {
//     if (!items || !filter) {
//       return items;
//     }
//     var ret = items.filter(o => {
//       //for (var ctrl in o.controls) {
//       if (o.controls[col].value.toLowerCase().includes(filter.toLowerCase())) {
//         return true;
//       }
//       //}
//       return false;
//     });
//     return ret;
//   }
// }

@Pipe({
  name: 'blockedorfixeddate',
  pure: false
})
export class BlockedOrFixedDatePipe implements PipeTransform {
  transform(date: { year: number, month: number, day: number }, blockedDates: { date: Date, dateNum: number, label: string, selectable: boolean }[], fixedDates: { date: Date, dateNum: number, label: string, selectable: boolean }[]): { label: string, type: string, selectable: boolean } | null {
    const dt = addTimezoneOffsetToDate(new Date(date.year, date.month, date.day)).getTime();
    let found = blockedDates.find(d => d.dateNum === dt);

    if (found) {
      return { label: found?.label, type: 'blocked', selectable: found?.selectable };
    }

    found = fixedDates.find(d => d.dateNum === dt);

    return found ? { label: found?.label, type: 'fixed', selectable: found?.selectable } : null;
  }
}

@Pipe({
  name: 'filterformcontrolcallbak',
  pure: false
})
export class FilterFormControlsCallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
    if (!items || !callback) {
      return items;
    }
    return items.filter(item => callback(item));
  }
}

@Pipe({
  name: 'cacxByManiTypeCode'
})
export class CacxByManiTypeCodePipe implements PipeTransform {
  transform(maniTypeCode: string): string {
    switch (maniTypeCode?.toUpperCase()) {
      case 'AMNA':
      case 'AMIN':
        return 'generic.cacil';
      case 'ESIN':
      case 'ESNA':
      case 'ESRE':
      case 'RANA':
        return 'generic.cacib'
      case 'AGNA':
      case 'AGIN':
        return 'generic.caciag'
      case 'OBNA':
      case 'OBIN':
        return 'generic.caciob'
      default:
        return 'generic.cacit';
    }
  }
}

@Pipe({
  name: 'noValuePlaceholder'
})
export class NoValuePlaceholderPipe implements PipeTransform {
  transform(value: unknown): unknown {
    return typeof value === 'boolean' ? value : value || '-';
  }
}

@Pipe({
  name: 'formatData'
})
export class FormatDataPipe implements PipeTransform {
  transform(args: object, pattern: string = '{{des}} ({{cod}})'): string {
    let result = pattern;

    if (args) {
      Object.keys(args).forEach(key => {
        result = result.replace(`{{${key}}}`, args[key] ?? '-');
      });
    }

    return result;
  }
}

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {
  transform(str: string, length: number = 100, withEllipses: boolean = true): string {
    const ellipses = ' ...';
    const stringMaxLength = length - (withEllipses ? ellipses.length : 0);

    return str?.length > length ? (str.substring(0, stringMaxLength) + (withEllipses ? ellipses : '')) : str;
  }
}

@Pipe({
  name: 'giorniToString'
})
export class GiorniToStringPipe implements PipeTransform {
  transform(giorni: GiornoDto[], dateFormat: string, locale: string = 'en-US'): string {
    const datePipe = new DatePipe(locale);

    if (!giorni || giorni.length === 0) {
      return null;
    }

    return giorni.map(g => `(${g.giorno}) ${datePipe.transform(new Date(g.data), dateFormat)}`).join(', ');
  }
}

@Pipe({
  name: 'subscribeWithLoading',
})
export class SubsscribeWithLoadingPipe implements PipeTransform {
  transform(val) {
    return isObservable(val)
      ? val.pipe(
        map((value: any) => ({
          loading: value.type === 'start',
          value: value.type ? value.value : value
        })),
        startWith({ loading: true }),
        catchError(error => of({ loading: false, error }))
      )
      : val;
  }
}

@Pipe({
  name: 'setDisabledField'
})
export class SetDisabledFieldPipe implements PipeTransform {
  transform(list: unknown[], valueFiled: string, values: number[], valuesValueField, currentValue: number): unknown[] {
    if (!values) {
      return list;
    }

    list.forEach((it) => it['disabled'] = values.some(v => v[valuesValueField] === it[valueFiled] && currentValue !== it[valueFiled]));

    return list;
  }
}

@Pipe({
  name: 'setDisabledFieldGiudRagg'
})
export class SetDisabledFieldGiudRaggPipe implements PipeTransform {
  transform(list: CalCaleManiGiud[], tipoManiCod: string, raggruppamentoId: number, raggruppamentoCod: string): CalCaleManiGiud[] {
    return list.map((it) => (
      {
        ...it, disabled: tipoManiCod !== 'ESRE' &&
          (!it.flDisponibilePerRagg
            || (it.giudiceNazioneCod == 'ITA' && (it.ringAbilitati.find(x => x.raggruppamentoId == raggruppamentoId && !x.flRingAbilitato) || !it.ringAbilitati.find(x => x.raggruppamentoId == raggruppamentoId)))
            || (it.giudiceNazioneCod != 'ITA' && raggruppamentoCod == 'RAZZEITAINRICONO') //todo: quando saranno gestite le abilitazioni degli straniere deve scomparire
          )
      })
    );
  }
}

@Pipe({
  name: 'editableDropdown'
})
export class EditableDropdownPipe implements PipeTransform {
  transform(options: unknown[], optionName: string): unknown[] {
    return (options ?? []).map(o => o[optionName]);
  }
}

@Pipe({
  name: 'sumItems'
})
export class SumItemsPipe implements PipeTransform {
  transform(items: unknown[], par: string): number {
    if (!items || !par) {
      return 0;
    }

    return items.map(item => Number(item[par])).reduce((sum, val) => sum + val, 0);
  }
}

@Pipe({
  name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {
  transform(json: string): any {
    if (!json) {
      return;
    }

    return JSON.parse(json);
  }
}


@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform(items: unknown[], par: string): unknown[] {
    if (!items || !par) {
      return [];
    }

    return items.map(item => item[par]);
  }
}

@Pipe({
  name: 'some'
})
export class SomePipe implements PipeTransform {
  transform(items: unknown[], par: string, val: string | number | boolean): boolean {
    if (!items || !par) {
      return false;
    }

    return items.some(item => item[par] === val);
  }
}
