import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Inject, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject, Observable, forkJoin } from 'rxjs';
@Component({
  selector: "angular-file-uploader",
  templateUrl: "./angular-file-uploader.component.html",
  styleUrls: ["./angular-file-uploader.component.css"]
})
export class AngularFileUploaderComponent implements OnInit, OnChanges {
  @Input() config: any = {};
  @Input() Id: number = 0;
  @Input() Index: number = -3;

  @Input() displaypinlabel: string = "Select file...";
  @Input() uploadbtnlabel: string = "Upload";
  @Input() draganddroplabel: string = "Drag files here";
  @Input() sizelimitlabel: string = "Size limit";
  @Input() resetlabel: string = "Reset";
  @Input() selectfilelabel: string = "Select a file...";
  @Input() invalidformatlabel: string = "Invalid format";
  @Input() invalidsizelabel: string = "Invalid size";
  @Input() uploadsuccesslabel: string = "Upload succeded!";
  @Input() uploadfaillabel: string = "Upload failed!";

  @Output() ApiResponse = new EventEmitter();
  @Output() DropComplete = new EventEmitter();

  progress;
  theme: string;
  id: number;
  hideProgressBar: boolean;
  resetUpload: boolean;
  maxSize: number;
  uploadAPI: string;
  formatsAllowed: string;
  multiple: boolean;
  headers: any;
  hideResetBtn: boolean;
  hideSelectBtn: boolean;
  attachPinText: string;
  uploadBtnText: string;
  dragAndDropText: string;
  sizeLimitText: string;
  resetText: string;
  selectFileText: string;
  invalidFormatText: string;
  invalidSizeText: string;

  idDate: number = +new Date();
  reg: RegExp = /(?:\.([^.]+))?$/;
  selectedFiles: Array<any> = [];
  notAllowedList: Array<Object> = [];
  Caption: Array<string> = [];
  singleFile = true;
  progressBarShow = false;
  uploadBtn = false;
  uploadMsg = false;
  afterUpload = false;
  uploadClick = true;
  uploadMsgText: string;
  uploadSuccessMsgText: string;
  uploadFailMsgText: string;
  uploadMsgClass: string;
  percentComplete: number;
  file: any;

  constructor(private http: HttpClient) { }


  ngOnChanges(rst: SimpleChanges) {
    if (rst["config"]) {
      this.theme = this.config["theme"] || "";
      this.id =
        this.config["id"] ||
        parseInt((this.idDate / 10000).toString().split(".")[1]) +
        Math.floor(Math.random() * 20) * 10000;
      this.hideProgressBar = this.config["hideProgressBar"] || false;
      this.hideResetBtn = this.config["hideResetBtn"] || false;
      this.hideSelectBtn = this.config["hideSelectBtn"] || false;
      this.resetUpload = this.config["resetUpload"] || false;

      this.uploadBtnText = this.uploadbtnlabel;
      this.dragAndDropText = this.draganddroplabel;
      this.sizeLimitText = this.sizelimitlabel;
      this.resetText = this.resetlabel;
      this.selectFileText = this.selectfilelabel;
      this.invalidFormatText = this.invalidformatlabel;
      this.invalidSizeText = this.invalidsizelabel;
      this.uploadSuccessMsgText = this.uploadsuccesslabel;
      this.uploadFailMsgText = this.uploadfaillabel;
      this.attachPinText = this.displaypinlabel;

      this.maxSize = this.config["maxSize"] || 20;
      this.uploadAPI = this.config["uploadAPI"]["url"];
      this.formatsAllowed = this.config["formatsAllowed"] || "*";
      this.multiple = this.config["multiple"] || false;
      this.headers = this.config["uploadAPI"]["headers"] || {};

      //console.log("config: ", this.config);
      //console.log(this.config["maxSize"]);
      //console.log(this.headers);
      //console.log("rst: ", rst);
    }

    if (rst["resetUpload"]) {
      if (rst["resetUpload"].currentValue === true) {
        this.resetFileUpload();
      }
    }
  }

  ngOnInit() {
    //console.log("Id: ", this.id);
    this.resetUpload = false;
  }

  resetFileUpload() {
    this.selectedFiles = [];
    this.Caption = [];
    this.notAllowedList = [];
    this.uploadMsg = false;
    this.uploadBtn = false;
  }

  onChange(event: any) {
    this.notAllowedList = [];
    if (this.afterUpload || !this.multiple) {
      this.selectedFiles = [];
      this.Caption = [];
      this.afterUpload = false;
    }
    //FORMATS ALLOWED LIST
    //console.log("FORMATS ALLOWED LIST= "+this.formatsAllowed);
    //NO OF FORMATS ALLOWED
    let formatsCount: any;
    formatsCount = this.formatsAllowed.match(new RegExp("\\.", "g"));
    formatsCount = formatsCount ? formatsCount.length : 0;
    //console.log("NO OF FORMATS ALLOWED= "+formatsCount);
    //console.log("-------------------------------");

    //ITERATE SELECTED FILES
    let file: FileList;
    if (event.type == "drop") {
      file = event.dataTransfer.files;
    } else {
      file = event.target.files || event.srcElement.files;
    }
    //console.log(file);
    let currentFileExt: any;
    let ext: any;
    let frmtAllowed: boolean;
    for (let i = 0; i < file.length; i++) {
      //CHECK FORMAT
      //CURRENT FILE EXTENSION
      currentFileExt = this.reg.exec(file[i].name);
      currentFileExt = currentFileExt[1];
      //console.log(file[i].name);
      frmtAllowed = false;
      //FORMAT ALLOWED LIST ITERATE
      if (formatsCount == 0) {
        frmtAllowed = true;
      } else {
        for (let j = formatsCount; j > 0; j--) {
          ext = this.formatsAllowed.split(".")[j];
          //console.log("FORMAT LIST ("+j+")= "+ext.split(",")[0]);
          if (j == formatsCount) {
            ext = this.formatsAllowed.split(".")[j] + ",";
          } //check format
          if (currentFileExt.toLowerCase() == ext.split(",")[0]) {
            frmtAllowed = true;
          }
        }
      }

      if (frmtAllowed) {
        //console.log("FORMAT ALLOWED");
        //CHECK SIZE
        if (file[i].size > this.maxSize * 1024000) {
          //console.log("SIZE NOT ALLOWED ("+file[i].size+")");
          this.notAllowedList.push({
            fileName: file[i].name,
            fileSize: this.convertSize(file[i].size),
            errorMsg: this.invalidSizeText
          });
          continue;
        } else {
          //format allowed and size allowed then add file to selectedFile array
          this.selectedFiles.push(file[i]);
          var reader = new FileReader();
          reader.readAsDataURL(file[i]);
          reader.onload = () => this.DropComplete.emit({ index: this.Index, files: this.selectedFiles, attachment: reader.result });
        }
      } else {
        //console.log("FORMAT NOT ALLOWED");
        this.notAllowedList.push({
          fileName: file[i].name,
          fileSize: this.convertSize(file[i].size),
          errorMsg: this.invalidFormatText
        });
        continue;
      }
    }

    if (this.selectedFiles.length !== 0) {
      this.uploadBtn = true;
      if (this.theme == "attachPin") {
        this.uploadFiles();
      }
    } else {
      this.uploadBtn = false;
    }
    this.uploadMsg = false;
    this.uploadClick = true;
    this.percentComplete = 0;
    event.target.value = null;
  }

  uploadFiles() {
    // start the upload and save the progress map
    this.progress = this.uploadSelectedFiles();
    console.log(this.progress);
    for (const key in this.progress) {
      this.progress[key].progress.subscribe(val => console.log(val));
    }

    // convert the progress map into an array
    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    forkJoin(allProgressObservables).subscribe(end => {
      this.progressBarShow = false;
      this.uploadBtn = false;
      this.uploadMsg = true;
      this.afterUpload = true;
      this.progress = null;
    });
  }

  uploadSelectedFiles(): { [key: string]: Observable<number> } {
    //console.log(this.selectedFiles);
    // this will be the our resulting map
    const status = {};
    let i: any;
    this.progressBarShow = true;
    this.uploadClick = false;
    this.notAllowedList = [];
    let isError = false;

    // let xhr = new XMLHttpRequest();
    let formData = new FormData();
    formData.append("Id", this.Id.toString());

    for (i = 0; i < this.selectedFiles.length; i++) {
      if (this.Caption[i] == null || this.Caption[i] == undefined)
        this.Caption[i] = "file" + i;
      //Add DATA TO BE SENT
      formData.append(
        this.Caption[i],
        this.selectedFiles[i] /*, this.selectedFiles[i].name*/
      );
    }

    // create a new progress-subject for every file
    const progress = new Subject<number>();
    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest('POST', this.uploadAPI, formData, {
      reportProgress: true
    });
    let startTime = new Date().getTime();

    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        // calculate the progress percentage

        const percentDone = Math.round((100 * event.loaded) / event.total);
        // pass the percentage into the progress-stream
        progress.next(percentDone);
      } else if (event instanceof HttpResponse) {
        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        this.uploadMsgText = this.uploadSuccessMsgText;
        this.uploadMsgClass = "text-success lead";
        progress.complete();
        this.ApiResponse.emit(event);
        this.afterUpload = true;
      }
    });

    // Save every progress-observable in a map of all observables
    status[this.selectedFiles[i].name] = {
      progress: progress.asObservable()
    };

    if (i > 1) {
      this.singleFile = false;
    } else {
      this.singleFile = true;
    }

    // return the map of progress.observables
    return status;
  };

  removeFile(i: any, sf_na: any) {
    //console.log("remove file clicked " + i)
    if (sf_na == "sf") {
      this.selectedFiles.splice(i, 1);
      this.Caption.splice(i, 1);
    } else {
      this.notAllowedList.splice(i, 1);
    }

    if (this.selectedFiles.length == 0) {
      this.uploadBtn = false;
    }
    this.DropComplete.emit({ index: this.Index, files: this.selectedFiles });
  }

  convertSize(fileSize: number) {
    //console.log(fileSize + " - "+ str);
    return fileSize < 1024000
      ? (fileSize / 1024).toFixed(2) + " KB"
      : (fileSize / 1024000).toFixed(2) + " MB";
  }

  attachpinOnclick() {
    //console.log("ID: ", this.id);
    document.getElementById("sel" + this.id)!.click();
    //$("#"+"sel"+this.id).click();
  }

  drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    //console.log("drop: ", event);
    //console.log("drop: ", event.dataTransfer.files);
    this.onChange(event);
  }
  allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    //console.log("allowDrop: ",event)
  }


}

/* interface CONFIG {
  uploadAPI: string;
  multiple?: boolean;
  formatsAllowed?: string;
  maxSize?: number;
  id?: number;
  resetUpload?: boolean;
  theme?: string;
  hideProgressBar?: boolean;
 }
 */
