import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Resources, TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-alert-giud-widget',
  templateUrl: './alert-giud-widget.component.html',
  styleUrls: ['./alert-giud-widget.component.scss']
})

export class AlertGiudWidgetComponent implements OnInit {
  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(['generic.', 'alertgiudwidget.']);

  data$: Observable<unknown[]> = of([]);

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void { }
}
