<ng-container *ngIf="T$ | async as T">
  <ng-container *ngIf="advancedSearch; else searchFields">
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <!-- Multiselect search -->
          <ng-template #multiselectSearch let-controlName="controlName" let-options="options" ,
            let-optionValue="optionValue" let-optionLabel="optionLabel" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-showToggleAll="showToggleAll">
            <div class="p-inputgroup">
              <p-multiSelect [options]="options" [formControlName]="controlName" [optionLabel]="optionLabel ?? 'des'"
                [optionValue]="optionValue ?? 'id'" styleClass="multiselect-inputgroup"
                [placeholder]="T | translateItem: placeholderKey" class="inputfield multiselect-sm"
                [emptyMessage]="T | translateItem: 'generic.dropdownempty'" [maxSelectedLabels]="1"
                [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" [filter]="true"
                [autofocusFilter]="true" [resetFilterOnHide]="true" [virtualScroll]="options?.length > 120"
                [virtualScrollItemSize]="40" [showToggleAll]="showToggleAll ?? true">
              </p-multiSelect>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <!-- Date search -->
          <ng-template #dateSearch let-controlName="controlName" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-minDate="minDate" let-maxDate="maxDate">
            <div class="p-inputgroup">
              <p-calendar [formControlName]="controlName" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" styleClass="multiselect-inputgroup" class="inputfield p-inputtext-sm"
                [placeholder]="T | translateItem: placeholderKey" [minDate]="minDate" [maxDate]="maxDate">
              </p-calendar>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataInizio', placeholderKey: 'giudiciblocchiprv.dataInizio'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'dataFine', placeholderKey: 'giudiciblocchiprv.dataFine'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'tipoManiId', options: calTipoManiList$ | async,  optionValue:'id',  optionLabel:'des', placeholderKey: 'giudiciblocchiprv.tipoMani'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'prvSpConfigId', options: calTipoSpConfList$ | async,  optionValue:'id',  optionLabel:'des', placeholderKey: 'giudiciblocchiprv.tipoSottoprova'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="p-inputgroup">
              <input pInputText type="text" formControlName="note"
                [placeholder]="T | translateItem: 'giudiciblocchiprv.note'" class="inputfield p-inputtext-sm">
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl('note', null)"></button>
            </div>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2 flex">
            <p-checkbox formControlName="flBloccoIncorso" [label]="T | translateItem: 'giudiciblocchiprv.bloccoIncorso'"
              [value]="true" [binary]="true" class="inputfield">
            </p-checkbox>
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchBasic'" [label]="T | translateItem: 'generic.searchBasic'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-container>
  <ng-template #searchFields>
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <span class="col-12">
            <input pInputText type="text" formControlName="simpleSearch"
              [placeholder]="T | translateItem: 'giudiciblocchiprv.simpleSearchPlaceholder'"
              class="inputfield p-inputtext-sm">
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchAdvanced'" [label]="T | translateItem: 'generic.searchAdvanced'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-template>
  <!-- <div class="flex-grow-1 overflow-y-hidden">
    <div class="h-full overflow-y-auto"> -->
  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" responsiveLayout="stack" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" pSortableColumn="dataInizio">
          {{T | translateItem: 'giudiciblocchiprv.dataInizio'}}
          <p-sortIcon field="dataInizio"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataFine">
          {{T | translateItem: 'giudiciblocchiprv.dataFine'}}
          <p-sortIcon field="dataFine"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="tipoMani">
          {{T | translateItem: 'giudiciblocchiprv.tipoMani'}}
          <p-sortIcon field="tipoMani"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="prvSpConfigDes">
          {{T | translateItem: 'giudiciblocchiprv.tipoSottoprova'}}
          <p-sortIcon field="prvSpConfigDes"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="note">
          {{T | translateItem: 'giudiciblocchiprv.note'}}
          <p-sortIcon field="note"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="flBloccoIncorso">
          {{T | translateItem: 'giudiciblocchiprv.bloccoIncorso'}}
          <p-sortIcon field="flBloccoIncorso"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="p-column-title">{{T | translateItem: 'giudiciblocchiprv.dataInizio'}}</span>
          <span class="white-space-nowrap">{{rowData.dataInizio | date: dateFormat}}</span>
        </td>

        <td>
          <span class="p-column-title">{{T | translateItem: 'giudiciblocchiprv.dataFine'}}</span>
          <span class="white-space-nowrap">{{rowData.dataFine | date: dateFormat}}</span>
        </td>

        <td>
          <span class="p-column-title">{{T | translateItem:
            'giudiciblocchiprv.tipoMani'}}</span>{{rowData.tipoManiDes | titlecase}}
        </td>

        <td>
          <span class="p-column-title">{{T | translateItem:
            'giudiciblocchiprv.tipoSottoprova'}}</span>{{rowData.prvSpConfigDes | titlecase}}
        </td>

        <td>
          <span class="p-column-title">{{T | translateItem:
            'giudiciblocchiprv.note'}}</span>{{rowData.note | titlecase}}
        </td>

        <td>
          <span class="p-column-title">{{T | translateItem:
            'giudiciblocchiprv.bloccoIncorso'}}</span>
          <span *ngIf="rowData.flBloccoIncorso" class="state-badge danger-background">{{T | translateItem:
            'giudiciblocchiprv.bloccoIncorso'}}</span>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{T | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- </div>
  </div> -->
</ng-container>