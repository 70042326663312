<ng-container *ngIf="T$ | async as T">
  <ng-container *ngIf="advancedSearch; else searchFields">
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <!-- Date search -->
          <ng-template #dateSearch let-controlName="controlName" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue">
            <div class="p-inputgroup">
              <p-calendar [formControlName]="controlName" [showIcon]="true" [dateFormat]="dateFormatPrNg"
                [firstDayOfWeek]="1" styleClass="multiselect-inputgroup" class="inputfield p-inputtext-sm"
                [placeholder]="T | translateItem: placeholderKey">
              </p-calendar>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <!-- Multiselect search -->
          <ng-template #multiselectSearch let-controlName="controlName" let-options="options" ,
            let-optionValue="optionValue" let-optionLabel="optionLabel" let-placeholderKey="placeholderKey"
            let-defaultValue="defaultValue" let-showToggleAll="showToggleAll">
            <div class="p-inputgroup">
              <p-multiSelect [options]="options" [formControlName]="controlName" [optionLabel]="optionLabel ?? 'des'"
                [optionValue]="optionValue ?? 'id'" styleClass="multiselect-inputgroup"
                [placeholder]="T | translateItem: placeholderKey" class="inputfield multiselect-sm"
                [emptyMessage]="T | translateItem: 'generic.dropdownempty'" [maxSelectedLabels]="1"
                [selectedItemsLabel]="T | translateItem: 'generic.selecteditems'" [filter]="true"
                [autofocusFilter]="true" [resetFilterOnHide]="true" [virtualScroll]="options?.length > 120"
                [virtualScrollItemSize]="40" [showToggleAll]="showToggleAll ?? true">
              </p-multiSelect>
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl(controlName, defaultValue)"></button>
            </div>
          </ng-template>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <div class="p-inputgroup">
              <input pInputNumber type="number" formControlName="giorno"
                [placeholder]="T | translateItem: 'giudiciimpegnimaniexprazza.giorno'" [max]="100" [min]="1"
                class="inputfield p-inputtext-sm" inputStyleClass="w-full">
              <button type="button" pButton icon="fa-solid fa-xmark" class="p-button-outlined p-button-plain"
                (click)="resetFilterControl('giorno')"></button>
            </div>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="dateSearch; context: {controlName: 'data', placeholderKey: 'giudiciimpegnimaniexprazza.data'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'razzaId', options: veeRazzeList$ | async, optionValue:'id',  optionLabel:'razzaDes', placeholderKey: 'giudiciimpegnimaniexprazza.razza'}"></ng-container>
          </span>

          <span class="col-12 sm:col-6 md:col-4 lg:col-2">
            <ng-container
              *ngTemplateOutlet="multiselectSearch; context: {controlName: 'classeId', options: genClasseList$ | async, optionValue:'id',  optionLabel:'des', placeholderKey: 'giudiciimpegnimaniexprazza.classe'}"></ng-container>
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchBasic'" [label]="T | translateItem: 'generic.searchBasic'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #searchFields>
    <form [formGroup]="dataForm" novalidate
      class="flex flex-column md:flex-row md:flex-wrap column-gap-2 mb-3 row-gap-2">
      <div class="flex-grow-1">
        <div class="formgrid grid row-gap-2">
          <span class="col-12">
            <input pInputText type="text" formControlName="simpleSearch"
              [placeholder]="T | translateItem: 'giudiciimpegnimaniexprazza.simpleSearchPlaceholder'"
              class="inputfield p-inputtext-sm">
          </span>
        </div>
      </div>

      <div class="buttons-cnt flex">
        <button pButton type="submit" class="p-button-outlined p-button-sm" icon="fa-solid fa-search"
          [title]="T | translateItem: 'generic.search'" [label]="T | translateItem: 'generic.search'" (click)="search()"
          [disabled]="elaboration || !dataForm.valid">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter-circle-xmark"
          [title]="T | translateItem: 'generic.searchReset'" [label]="T | translateItem: 'generic.searchReset'"
          (click)="resetSearch()" [disabled]="elaboration">
        </button>

        <button pButton type="button" class="p-button-outlined p-button-sm" icon="fa-solid fa-filter"
          [title]="T | translateItem: 'generic.searchAdvanced'" [label]="T | translateItem: 'generic.searchAdvanced'"
          (click)="toggleSearch()" [disabled]="elaboration">
        </button>
      </div>
    </form>
  </ng-template>

  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="[10, 25, 50, 100]"
    (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)" [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" responsiveLayout="stack" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap" pSortableColumn="giorno">
          {{T | translateItem: 'giudiciimpegnimaniexprazza.giorno'}}
          <p-sortIcon field="giorno"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="data">
          {{T | translateItem: 'giudiciimpegnimaniexprazza.data'}}
          <p-sortIcon field="data"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="gruppoFciId">
          {{T | translateItem: 'giudiciimpegnimaniexprazza.gruppoFci'}}
          <p-sortIcon field="gruppoFciId"></p-sortIcon>
        </th>

        <th class="white-space-nowrap" pSortableColumn="razzaDes">
          {{T | translateItem: 'giudiciimpegnimaniexprazza.razza'}}
          <p-sortIcon field="razzaDes"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexprazza.giorno'}}
          </span>
          {{rowData.giorno}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexprazza.data'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.data | date: dateFormat}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexprazza.gruppoFci'}}
          </span>
          <span class="white-space-nowrap">
            {{rowData.gruppoFciLabel}}
          </span>
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'giudiciimpegnimaniexprazza.razza'}}
          </span>
          {{rowData.razzaDes}}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{T | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>