import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { MessageService } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './authorization/auth-config.module';
import { AutoLoginComponent } from './components/auto-login/auto-login.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageDataComponent } from './components/message-data/message-data.component';
import { ResultResponseInterceptor } from './interceptor/result-response.interceptor';
import { AlertGiudWidgetComponent } from './modules/home/alert-giud-widget/alert-giud-widget.component';
import { AnomalieWidgetComponent } from './modules/home/anomalie-widget/anomalie-widget.component';
import { CalendarioAnomalieWidgetComponent } from './modules/home/calendario-anomalie-widget/calendario-anomalie-widget.component';
import { CalendarioStatiWidgetComponent } from './modules/home/calendario-stati-widget/calendario-stati-widget.component';
import { HomeComponent } from './modules/home/home.component';
import { NotificheWidgetComponent } from './modules/home/notifiche-widget/notifiche-widget.component';
import { SharedModule } from './modules/shared.module';
import { PipesModule } from './pipes/pipes.module';
import { AppManagerService } from './services/app-manager.service';
import { BaseDataService } from './services/base-data.service';
import { DataService } from './services/data.service';
import { MessageAppService } from './services/message-app.service';
import { TranslateService } from './services/translate.service';

export let AppInjector: Injector;

// export const authConfigLoader = (httpClient: HttpClient, dataService: DataService) => {
//   let origin = window.location.origin;
//   const config$ = httpClient.get<any>(`${environment.restCommConfigUrl}/config`)
//     .pipe(
//       map((configSettings: any) => {
//         dataService.configSettings = configSettings;
//         return {
//           authority: configSettings.restIdentityUrl,
//           redirectUrl: `${origin}/`,
//           postLoginRoute: '/',
//           postLogoutRedirectUri: `${origin}/`,
//           clientId: 'encimainapp',
//           scope: 'openid profile email offline_access common_api calendario_api',
//           responseType: 'code',
//           silentRenew: true,
//           useRefreshToken: true,
//           // silentRenewUrl: `${origin}/silent.html`,

//           // maxIdTokenIatOffsetAllowedInSeconds: 600,
//           // useRefreshToken: true,
//           // issValidationOff: true,
//           // renewUserInfoAfterTokenRenew: true,
//           // autoUserInfo: true,
//           // configId?: string;
//           // authWellknownEndpointUrl?: string;
//           // authWellknownEndpoints?: AuthWellKnownEndpoints;
//           // autoUserinfo: false,
//           // startCheckSession: false,
//           // storage: localStorage,
//           // postLoginRoute: '/home',
//           // unauthorizedRoute: '/unauthorized',
//           // forbiddenRoute: '/forbidden',
//           // historyCleanupOff: true,
//           // logLevel: LogLevel.Debug,
//         };
//       })
//     );

//   return new StsConfigHttpLoader(config$.toPromise());
// };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AutoLoginComponent,
    MessageDataComponent,
    HeaderComponent,
    NotificheWidgetComponent,
    CalendarioAnomalieWidgetComponent,
    CalendarioStatiWidgetComponent,
    AlertGiudWidgetComponent,
    AnomalieWidgetComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    PipesModule,
    AuthConfigModule,
    // AuthModule.forRoot({
    //   loader: {
    //     provide: StsConfigLoader,
    //     useFactory: authConfigLoader,
    //     deps: [HttpClient, DataService],
    //   },
    // }),
    AppRoutingModule,
    ButtonModule,
    BadgeModule,
    ToastModule,
    ChipModule,
    TieredMenuModule,
    DividerModule,
    DropdownModule,
    OverlayPanelModule,
    DialogModule,
    ChartModule,
    TabViewModule,
    SharedModule,
    HttpClientJsonpModule
  ],
  providers: [
    // OidcConfigService,
    DataService,
    AppManagerService,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResultResponseInterceptor, multi: true },
    MessageService,
    TranslateService,
    BaseDataService,
    MessageAppService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [
    // AuthModule,
    MessageDataComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
