<ng-container *ngIf="T$ | async as T">
  <form [formGroup]="dataForm" novalidate>
    <app-list-search [(advancedSearch)]="advancedSearch" [elaboration]="elaboration"
      [searchButtonLabel]="T | translateItem: 'generic.search'"
      [searchButtonTooltip]="T | translateItem: 'generic.search'"
      [resetButtonLabel]="T | translateItem: 'generic.searchReset'"
      [resetButtonTooltip]="T | translateItem: 'generic.searchReset'"
      [toggleButtonAdvancedLabel]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonAdvancedTooltip]="T | translateItem: 'generic.searchAdvanced'"
      [toggleButtonSimpleLabel]="T | translateItem: 'generic.searchBasic'"
      [toggleButtonSimpleTooltip]="T | translateItem: 'generic.searchBasic'" (onSearch)="search()"
      (onReset)="resetSearch()">
      <!-- Advanced search -->
      <ng-container advanced>
        <app-search-field-multiselect [options]="profiliList$ | async" [formGroup]="dataForm" controlName="profiloId"
          [placeholder]="T | translateItem: 'genlogevento.profilo'"
          [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="utenteNomeCompleto"
          [placeholder]="T | translateItem: 'genlogevento.autore'" ngDefaultControl />

        <app-search-field-multiselect [options]="tipiLogEventoList$ | async" [formGroup]="dataForm"
          controlName="tipoLogEventoId" [placeholder]="T | translateItem: 'genlogevento.tipoLogEvento'"
          [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

        <app-search-field-multiselect [options]="ambitiList$ | async" [formGroup]="dataForm" controlName="ambitoId"
          [placeholder]="T | translateItem: 'genlogevento.ambito'"
          [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="entityLabel"
          [placeholder]="T | translateItem: 'genlogevento.entityLabel'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="userMessage"
          [placeholder]="T | translateItem: 'genlogevento.userMessage'" ngDefaultControl />

        <app-search-field-multiselect [options]="yesNo$ | async" [formGroup]="dataForm" controlName="flPresaVisione"
          [placeholder]="T | translateItem: 'genlogevento.presaVisione'" optionValue="key"
          [selectedItemsLabel]="T | translateItem: 'generic.selectedItems'" ngDefaultControl />

        <app-search-field-inputtext [formGroup]="dataForm" controlName="utentePresaVisioneEmail"
          [placeholder]="T | translateItem: 'genlogevento.utentePresaVisioneEmail'" ngDefaultControl />
      </ng-container>

      <!-- Simple search -->
      <ng-container simple>
        <div class="col-12">
          <input pInputText formControlName="simpleSearch"
            [placeholder]="T | translateItem: 'genlogevento.simpleSearchPlaceholder'" class="p-inputtext-sm" />
        </div>
      </ng-container>
    </app-list-search>
  </form>

  <p-table #dataTable [value]="entities$ | async" [totalRecords]="paginatorRowsTot" [lazy]="true" [paginator]="true"
    [first]="paginatorFirst" [rows]="paginatorRows" [rowsPerPageOptions]="rowsPerPageOptions" selectionMode="multiple"
    [(selection)]="entitiesSelected" (onLazyLoad)="onLazyLoad($event)" (onPage)="onPage($event)"
    [showCurrentPageReport]="true"
    [currentPageReportTemplate]="T | translateItem: paginatorRowsTot ? 'generic.listReport' : 'generic.listEmpty'"
    sortMode="multiple" [multiSortMeta]="multiSortMeta" breakpoint="960px"
    styleClass="p-datatable-striped p-datatable-md" paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap check-column">
          <p-tableHeaderCheckbox />
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataEvento">
          {{T | translateItem: 'genlogevento.dataEvento'}}
          <p-sortIcon field="dataEvento" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="profiloDes">
          {{T | translateItem: 'genlogevento.profilo'}}
          <p-sortIcon field="profiloDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="utenteNomeCompleto">
          {{T | translateItem: 'genlogevento.autore'}}
          <p-sortIcon field="profiloutenteNomeCompletoDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="tipoLogEventoDes">
          {{T | translateItem: 'genlogevento.tipoLogEvento'}}
          <p-sortIcon field="tipoLogEventoDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="ambitoDes">
          {{T | translateItem: 'genlogevento.ambito'}}
          <p-sortIcon field="ambitoDes" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="entityLabel">
          {{T | translateItem: 'genlogevento.entityLabel'}}
          <p-sortIcon field="entityLabel" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="userMessage">
          {{T | translateItem: 'genlogevento.userMessage'}}
          <p-sortIcon field="userMessage" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="flPresaVisione">
          {{T | translateItem: 'genlogevento.presaVisione'}}
          <p-sortIcon field="flPresaVisione" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="utentePresaVisioneEmail">
          {{T | translateItem: 'genlogevento.utentePresaVisioneEmail'}}
          <p-sortIcon field="utentePresaVisioneEmail" />
        </th>

        <th class="white-space-nowrap" pSortableColumn="dataPresaVisione">
          {{T | translateItem: 'genlogevento.dataPresaVisione'}}
          <p-sortIcon field="dataPresaVisione" />
        </th>

        <th *ngIf="!readOnly" class="white-space-nowrap buttons-column">
          <div class="buttons-cnt">
            <div>
              <p-menu #tableactionmenu [popup]="true" appendTo="body" [model]="actionMenuItems$ |async" />
              <app-button size="medium" [outlined]="true" icon="fa-solid fa-ellipsis-vertical"
                [title]="T | translateItem: 'generic.commands'" [disabled]="elaboration || !entitiesSelected?.length"
                (onClick)=" tableactionmenu.toggle($event)" *grantVisible="grants$ | async; key 'bulkactions'" />
            </div>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="check-column">
          <p-tableCheckbox [value]="rowData" />
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.dataEvento'}}
          </span>
          {{rowData.dataEvento | date: dateTimeFormat }}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.profilo'}}
          </span>
          {{rowData.profiloDes}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.autore'}}
          </span>
          {{rowData.utenteNomeCompleto | noValuePlaceholder}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.tipoLogEvento'}}
          </span>
          {{rowData.tipoLogEventoDes }}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.ambito'}}
          </span>
          {{rowData.ambitoDes}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.entityLabel'}}
          </span>
          {{rowData.entityLabel}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.userMessage'}}
          </span>
          {{rowData.userMessage | noValuePlaceholder}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.presaVisione'}}
          </span>
          <app-yes-no [value]="rowData.flPresaVisione"
            [labels]="{yes: T | translateItem: 'generic.si', no: T | translateItem: 'generic.no'}" />
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.utentePresaVisioneEmail'}}
          </span>
          {{rowData.utentePresaVisioneEmail | noValuePlaceholder}}
        </td>

        <td>
          <span class="p-column-title">
            {{T | translateItem: 'genlogevento.dataPresaVisione'}}
          </span>
          {{rowData.dataPresaVisione | date: dateTimeFormat | noValuePlaceholder}}
        </td>

        <td *ngIf="!readOnly" class="buttons-column">
          <div class="buttons-cnt">
            <app-button [outlined]="true" severity="success" icon="fa-solid fa-circle-check"
              [tooltip]="T | translateItem: 'genlogevento.prendiVisione'" (onClick)="prendiVisione([rowData.id])"
              [disabled]="elaboration || rowData.flPresaVisione" />

            <app-button *grantVisible="grants$ | async; key 'viewlog'" [outlined]="true"
              icon="fa-solid fa-magnifying-glass-chart" [tooltip]="T | translateItem: 'generic.viewlog'"
              (onClick)="modalEntityLogShow(rowData.tableName, rowData.rowId)" [disabled]="elaboration" />
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="100%" class="text-center danger">
          {{T | translateItem: 'generic.norecordsfound'}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>