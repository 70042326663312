<ng-container *ngIf="T$ | async as T">
  <div class="surface-50 border-1 border-300 border-solid h-full widget-card">
    <div class="surface-200 text-2xl font-medium text-primary p-3 header">
      {{T | translateItem: 'alertGiudWidget.titolo'}}
    </div>
    <div class="flex flex-column justify-content-center">
      <div class="px-4 flex-grow-1">
        <p class="font-medium text-center p-3 text-600">
          {{T | translateItem: 'generic.listEmpty'}}
        </p>
      </div>
    </div>
  </div>
</ng-container>