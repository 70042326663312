import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MenuItem, SortMeta } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, Subscription, combineLatest, map, tap } from 'rxjs';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { GenAmbito } from 'src/app/models/anagrafiche/gen-ambito';
import { CalCaleMani } from 'src/app/models/calendario/cal-cale-mani';
import { ComparisonOperator, DataSearch, DataSortValue, SearchFieldType, SearchFieldsConf, SortMode, buildDataSearch } from 'src/app/models/data-search';
import { EntityManager, EntityManagerGrant, FieldGrant, checkGrant } from 'src/app/models/entity-config';
import { GenLogEvento } from 'src/app/models/gestione/gen-log-evento';
import { GenTipoLogEvento } from 'src/app/models/gestione/gen-tipo-log-evento';
import { MessageData, MessageLevel, MessageMode, ToastMessageData } from 'src/app/models/message';
import { SecProfilo } from 'src/app/models/security/sec-profilo';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { BaseDataService } from 'src/app/services/base-data.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { GenLogService } from 'src/app/services/genlog.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { convertSortMetaArrayToDataSortValueArray, dateTimeFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-gen-log-evento-list',
  templateUrl: './gen-log-evento-list.component.html',
  styleUrls: ['./gen-log-evento-list.component.scss']
})
export class GenLogEventoListComponent implements OnInit, OnDestroy {
  entityManager: EntityManager = this.appManagerService.getEntityManager(this.activatedRoute);

  elaboration: boolean = false;

  dateTimeFormat: string = dateTimeFormat;

  // Entities
  entities$: Observable<GenLogEvento[]>;

  // Etichette
  T$: Observable<Resources> = this.translateService.translatedItems$(AppManagerRoutes.GenLogEventoList.translateSuffixs).pipe(
    tap(translateItems => this.resourceList = translateItems)
  );

  resourceList: Resources;

  // Sort
  baseMultiSortMeta: SortMeta[] = [
    { field: 'dataEvento', order: SortMode.Desc }
  ];

  multiSortMeta: SortMeta[] = [...this.baseMultiSortMeta];

  dataSortValues: DataSortValue[] = convertSortMetaArrayToDataSortValueArray(this.multiSortMeta);

  // List
  basePaginatorFirst: number = 0;

  basePaginatorRows: number = 10;

  paginatorFirst: number = this.basePaginatorFirst;

  paginatorRows: number = this.basePaginatorRows;

  paginatorRowsTot: number;

  rowsPerPageOptions: number[] = [10, 25, 50, 100];

  // Grants
  grants$: Observable<EntityManagerGrant> = this.appManagerService.getGrants(AppManagerRoutes.GenLogEventoList.section ?? AppManagerRoutes.GenLogEventoList.id);

  //Search
  dataForm: FormGroup;

  advancedSearch: boolean = false;

  dataSearch: DataSearch;

  searchFieldsConf: SearchFieldsConf[] = [
    { key: 'parentRowId', searchFields: 'parentRowId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: false },
    { key: 'simpleSearch', searchFields: 'profiloDes,utenteNomeCompleto,tipoLogEventoDes,ambitoDes,entityLabel,userMessage,utentePresaVisioneEmail', type: SearchFieldType.multiField, searchCompOp: ComparisonOperator.Contains, advancedSearch: false },
    { key: 'parentRowId', searchFields: 'parentRowId', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'profiloId', searchFields: 'profiloId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'utenteNomeCompleto', searchFields: 'utenteNomeCompleto', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'tipoLogEventoId', searchFields: 'tipoLogEventoId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'ambitoId', searchFields: 'ambitoId', type: SearchFieldType.multiValue, searchCompOp: ComparisonOperator.In, advancedSearch: true },
    { key: 'entityLabel', searchFields: 'entityLabel', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'userMessage', searchFields: 'userMessage', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true },
    { key: 'flPresaVisione', searchFields: 'flPresaVisione', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Equals, advancedSearch: true },
    { key: 'utentePresaVisioneEmail', searchFields: 'utentePresaVisioneEmail', type: SearchFieldType.singleValue, searchCompOp: ComparisonOperator.Contains, advancedSearch: true }
  ];

  profiliList$: Observable<SecProfilo[]> = this.baseDataService.getSecProfiloList();
  tipiLogEventoList$: Observable<GenTipoLogEvento[]> = this.baseDataService.getGenTipoLogEventoList();
  ambitiList$: Observable<GenAmbito[]> = this.baseDataService.getGenAmbitoList();

  yesNo$ = this.T$.pipe(
    map((translateItems) =>
      [
        { key: '1', des: this.translateService.translate(translateItems, 'generic.si') },
        { key: '0', des: this.translateService.translate(translateItems, 'generic.no') }
      ]
    )
  );

  // Selezione
  entitiesSelected: GenLogEvento[];

  actionMenuItems$: Observable<MenuItem[]> = combineLatest([
    this.grants$,
    this.T$
  ]).pipe(
    map(([grants, translateItems]: [grants: EntityManagerGrant, translateItems: Resources]) => {
      const menuItems = [];
      if (checkGrant(grants, 'prendiVisione', FieldGrant.read, true)) {
        menuItems.push({
          label: this.translateService.translate(translateItems, 'genlogevento.prendiVisione'),
          title: this.translateService.translate(translateItems, 'genlogevento.prendiVisione'),
          icon: 'fa-solid fa-circle-check',
          command: () => {
            this.prendiVisione(this.entitiesSelected.map(e => e.id));
          }
        });
      }

      return menuItems;
    })
  );

  private subscription: Subscription;

  @ViewChild('dataTable') dataTable: Table;

  @Input()
  calCaleMani: CalCaleMani;

  @Input()
  tabIndex: number;

  @Input()
  readOnly: boolean = false;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private triggersService: TriggersService,
    private fb: FormBuilder,
    private baseDataService: BaseDataService,
    private logService: GenLogService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Search
    this.dataForm = this.fb.group({
      parentRowId: this.calCaleMani.id,
      simpleSearch: null,
      profiloId: null,
      utenteNomeCompleto: null,
      tipoLogEventoId: null,
      ambitoId: null,
      entityLabel: null,
      userMessage: null,
      utentePresaVisioneEmail: null,
      flPresaVisione: null,
    });

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;

    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(event.multiSortMeta);

    if (this.dataTable?.initialized) {
      this.loadData();
    }
  }

  onPage(event): void {
    this.paginatorFirst = event.first;
    this.paginatorRows = event.rows;
  }

  private loadData(): void {
    this.dataSearch = buildDataSearch(this.dataForm.value, this.searchFieldsConf, this.advancedSearch, this.dataSortValues, this.paginatorFirst, this.paginatorRows);

    this.entities$ = this.dataService.searchElements<GenLogEvento>(`${this.dataService.configSettings.restCommonUrl}/genlogevento`, this.dataSearch).pipe(
      tap(data => {
        this.elaboration = false;
        this.paginatorRowsTot = data.numRowsTot;
      }),
      map(data => data.entities)
    );
  }

  search(): void {
    this.paginatorFirst = this.basePaginatorFirst;
    this.loadData();
  }

  resetSearch(): void {
    this.dataForm.reset({ parentRowId: this.calCaleMani.id });
    this.dataSortValues = convertSortMetaArrayToDataSortValueArray(this.baseMultiSortMeta);
    this.paginatorFirst = this.basePaginatorFirst;
    this.paginatorRows = this.basePaginatorRows;
    this.multiSortMeta = [...this.baseMultiSortMeta];
    this.loadData();
  }

  prendiVisione(ids: number[]): void {
    if (!ids?.length) {
      return;
    }

    const options = {
      btnLabelOk: 'generic.confirm',
      btnIconOk: 'fa-solid fa-circle-check',
      btnClassOk: 'p-button-success',
      notTranslateTitle: true,
      notTranslateMessage: true
    };

    const messageData = new MessageData(
      MessageMode.OkKo,
      MessageLevel.Warning,
      this.translateService.translate(this.resourceList, 'genlogevento.prendiVisioneModalMessage'),
      this.translateService.translate(this.resourceList, 'genlogevento.prendiVisioneModalTitle'),
      null,
      options
    );

    const hEventOk = () => {
      this.elaboration = true;

      this.subscription.add(
        this.dataService.postGeneric(`${this.dataService.configSettings.restCommonUrl}/genlogevento/presavisione`, ids).subscribe({
          next: () => {
            this.elaboration = false;

            this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'genlogevento.prendiVisioneSuccessMsg')));
            this.clearEntitiesSelected()
            this.loadData();
          },
          error: () => {
            this.elaboration = false;
          }
        })
      );
    }

    messageData.hEventOk = hEventOk;

    this.appManagerService.showMessage(messageData);
  }

  public clearEntitiesSelected(): void {
    this.entitiesSelected = [];
  }

  modalEntityLogShow(tablename: string, rowId: number): void {
    this.logService.openModalEntityLog(tablename, rowId);
  }
}
