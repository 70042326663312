import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ReplaySubject, Subscription, lastValueFrom } from 'rxjs';
import { AppManagerService } from './app-manager.service';
import { DataService } from './data.service';

export const MESSAGE_GENERIC = 'msggen';
export const MESSAGE_NOTIFICA = 'msgnoti';
export const MESSAGE_NOTIFICA_LIST = "msgnotils";

@Injectable({ providedIn: 'root' })
export class MessageHubService {

  private subscription: Subscription;
  private messageHubConn: HubConnection;
  private _notificationEvent: ReplaySubject<NotificationEvent>;

  constructor(
    private dataService: DataService,
    private appManagerService: AppManagerService,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.subscription = new Subscription();
    this._notificationEvent = new ReplaySubject<NotificationEvent>(undefined);

    this.subscription.add(this.appManagerService.initialized$
      .subscribe({
        next: (appInitialized) => {
          if (appInitialized.initialized && appInitialized.caller == 'init') {
            // console.log(`${(new Date()).toUTCString()} MessageHubService start connection...`);
            const url = `${this.dataService.configSettings.restCommonUrl}/messagehub`;
            this.messageHubConn = new HubConnectionBuilder()
              // .configureLogging(signalR.LogLevel.Debug)
              .withUrl(url, {
                // skipNegotiation: true,
                // transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => { return lastValueFrom(oidcSecurityService.getAccessToken()) }
              })
              .withAutomaticReconnect() // default behavior try four reconnection attempts (0s, 0s, 10s, 30s)
              .build();
            this.onNotification();
            this.messageHubConn.start(); // this.messageHubConn.start().catch(err => {});
          }
        },
        error: (error) => { }
      })
    );

    this.subscription.add(this.appManagerService.disposed$
      .subscribe(
        (event) => {
          if (event?.disposed) {
            this.dispose();
          }
        },
        (error) => { }
      ));

  }

  /**
   * Call this method to stop connection and dispose the listeners
   */
  dispose() {
    // console.log(`${(new Date()).toUTCString()} MessageHubService dispose`);

    if (this.messageHubConn) {
      this.messageHubConn.stop();
    }

    if (this._notificationEvent) {
      this._notificationEvent.unsubscribe();
    }

    this.subscription.unsubscribe();
  }

  get notificationEvent$() {
    return this._notificationEvent.asObservable();
  }

  private onNotification() {
    this.messageHubConn.on(MESSAGE_GENERIC, data => {
      // console.log(`Message received MESSAGE_GENERIC \n ${(new Date()).toUTCString()} data => ${JSON.stringify(data)} `);

      this._notificationEvent.next({
        type: MESSAGE_GENERIC,
        data: data
      });
    });

    this.messageHubConn.on(MESSAGE_NOTIFICA_LIST, data => {
      // console.log(`Message received MESSAGE_NOTIFICA_LIST \n ${(new Date()).toUTCString()} data => ${JSON.stringify(data)} `);

      this._notificationEvent.next({
        type: MESSAGE_NOTIFICA_LIST,
        data: data
      });
    });

    this.messageHubConn.on(MESSAGE_NOTIFICA, data => {
      // console.log(`Message received MESSAGE_NOTIFICA \n ${(new Date()).toUTCString()} data => ${JSON.stringify(data)} `);

      this._notificationEvent.next({
        type: MESSAGE_NOTIFICA,
        data: data
      });
    });

  }

  /**
   * Create and notify new NotificationEvent for all listener
   *
   * @param type type of notification (see constants "MESSAGE_")
   * @param data body of nofication
   */
  public notify(type: string, data: any) {
    this._notificationEvent.next({
      type: type,
      data: data
    });
  }

}

export interface NotificationEvent {
  type: string;
  data: any;
}

