import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { MessageData, MessageLevel, MessageMode } from '../../models/message';
import { AppManagerService } from '../../services/app-manager.service';
import { Resources, TranslateService } from '../../services/translate.service';


@Component({
  selector: 'message-data',
  templateUrl: './message-data.component.html',
  styleUrls: ['./message-data.component.scss']
})
export class MessageDataComponent implements OnInit, OnDestroy {
  messageMode = MessageMode;
  messageLevel = MessageLevel
  messageData: MessageData;
  translateItems: Resources;
  visible: boolean = false;

  private subscription: Subscription;

  constructor(
    private appManagerService: AppManagerService,
    private translateService: TranslateService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    combineLatest([
      this.appManagerService.messageData$,
      this.translateService.translatedItems$(['generic.', 'error.'])
    ]).subscribe(
      ([messageData, translateItems]) => {
        if (messageData && translateItems) {
          this.messageData = messageData;
          this.translateItems = translateItems;
          this.visible = true;
        }
      }
    );
  }

  close() {
    this.visible = false;
    this.messageData.notify('close');
  }

  ko() {
    if (this.messageData.hEventKo) {
      this.messageData.hEventKo();
    }
    this.messageData.notify('ko');
    this.visible = false;
  }

  ok() {
    if (this.messageData.hEventOk) {
      this.messageData.hEventOk();
    }
    this.messageData.notify('ok');
    this.visible = false;
  }
}
