import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { ActionEvent, ActionEventType } from 'src/app/models/action-event';
import { GenAllegato } from 'src/app/models/anagrafiche/gen-allegato';
import { GenAllegatoTipo } from 'src/app/models/anagrafiche/gen-allegato-tipo';
import { ComparisonOperator, DataSearch, DataSearchValue, DataSortValue, SortMode } from 'src/app/models/data-search';
import { IEntityList } from 'src/app/models/entity';
import { EntityManager, EntityManagerGrant } from 'src/app/models/entity-config';
import { MessageDataConfig, MessageDataFromConfig, ToastMessageData } from 'src/app/models/message';
import { AppManagerService } from 'src/app/services/app-manager.service';
import { DataService } from 'src/app/services/data.service';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { GenLogService } from 'src/app/services/genlog.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { dateFormat } from 'src/app/utils/util';

@Component({
  selector: 'app-gen-allegato-list',
  templateUrl: './gen-allegato-list.component.html',
  styleUrls: ['./gen-allegato-list.component.scss']
})

export class GenAllegatoListComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat;
  T$: Observable<Resources>;
  tipiAllegatiList$: Observable<GenAllegatoTipo[]>;
  dataForm: FormGroup;
  elaboration: boolean = false;
  subscription: Subscription;
  resourceList: Resources;
  loadingSpinnerVisible: boolean = true;
  entityManager: EntityManager;
  grants: EntityManagerGrant;

  get allegatiListFa(): FormArray {
    return this.dataForm.get('allegatiList') as FormArray;
  }

  get allegatiListControls(): AbstractControl[] {
    return this.allegatiListFa.controls as AbstractControl[];
  }

  afuConfig = {
    multiple: false,
    formatsAllowed: "",
    maxSize: "20",
    uploadAPI: {},
    theme: "dragNDrop",
    resetUpload: false,
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: true
  };

  @Input() extTableName: string;
  @Input() extId: number;
  @Input() tabIndex: number;
  @Input() readOnly: boolean = false;

  @Output() isCompleted = new EventEmitter<boolean>();
  @Output() actionEvent = new EventEmitter<ActionEvent>();

  constructor(
    private appManagerService: AppManagerService,
    private dataService: DataService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private triggersService: TriggersService,
    private logService: GenLogService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.entityManager = this.appManagerService.getEntityManager(this.activatedRoute);

    // Recupera le grants di questa sezione
    this.grants = this.entityManager.grants;

    // Traduzioni
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GenAllegatoList.translateSuffixs ?? ['generic.']).pipe(
      tap(data => this.resourceList = data)
    );

    // Costruisco il form
    this.dataForm = this.fb.group({
      allegatiList: this.fb.array([])
    });

    this.subscription.add(
      this.dataForm.valueChanges.subscribe(
        () => {
          this.emitIsCompleted();
        }
      )
    );

    // Carico i dati
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadData(): void {
    this.loadingSpinnerVisible = true;

    // Carico gli allegati
    const ds = new DataSearch();
    ds.dataSortValues = [new DataSortValue('dtIns', SortMode.Asc)];
    ds.dataSearchValues = [
      new DataSearchValue([this.extTableName], ['extTableName'], ComparisonOperator.Equals),
      new DataSearchValue([this.extId.toString()], ['extId'], ComparisonOperator.Equals),
      new DataSearchValue(['0'], ['flNonVisibile'], ComparisonOperator.Equals)
    ];

    this.subscription.add(
      this.dataService.searchElements<GenAllegato>(`${this.dataService.configSettings.restCommonUrl}/genallegato`, ds).subscribe(data => {
        this.allegatiListFa.clear();
        data.entities.forEach(this.addAllegatoFg, this);

        this.loadingSpinnerVisible = false;
      })
    );
    // this.allegatiList$ = this.dataService.searchElements<GenAllegato>(`${this.dataService.configSettings.restCommonUrl}/genallegato`, ds).pipe(
    //   map((data: IEntityList<GenAllegato>) => {
    //     this.allegatiListFa.clear();
    //     data.entities.forEach(this.addAllegatoFg, this);
    //     return this.allegatiListControls;
    //   }),
    //   tap(() => this.loadingSpinnerVisible = false)
    // );

    // Carico i tipi di allegati
    this.tipiAllegatiList$ = this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/genallegatotipobyexttablename`, `extTablename=${this.extTableName}`).pipe(
      map((data: IEntityList<GenAllegatoTipo>) => data.entities),
      shareReplay()
    );
  }

  public DropComplete(env) {
    //Modalità solo un file: TODO multifile se richiesto...
    const fa = this.dataForm.get("allegatiList") as FormArray;
    const fg = fa.at(env.index) as FormGroup;
    if (env.files.length > 0) {
      fg.get("nomeFile").patchValue(env.files[0].name);
      if (env.files[0].name.indexOf(".") > -1) {
        fg.get("estensione").patchValue(env.files[0].name.split('.').pop());
      } else {
        fg.get("estensione").patchValue("");
      }
      fg.get("attachment").patchValue(env.attachment);

    }
    else {
      fg.get("nomeFile").patchValue(null);
      fg.get("estensione").patchValue(null);
      fg.get("attachment").patchValue(null);
    }
    fg.markAsDirty();
  }

  private buildAllegatoFg(allegato: GenAllegato): FormGroup {
    return this.fb.group({
      id: allegato.id,
      ambitoId: [allegato.ambitoId, Validators.required],
      extId: [allegato.extId, Validators.required],
      allegatoTipoId: [allegato.allegatoTipoId, Validators.required],
      nomeFile: [allegato.nomeFile, Validators.required],
      estensione: allegato.estensione,
      nota: allegato.nota,
      userIns: allegato.userIns,
      dtIns: allegato.dtIns,
      userUpd: allegato.userUpd,
      dtUpd: allegato.dtUpd,
      allegatoTipoDes: allegato.allegatoTipoDes,
      extTableName: [allegato.extTableName, Validators.required],
      attachment: null
    });
  }

  public downloadAttach(allegato: GenAllegato) {
    this.subscription.add(
      this.dataService.postGeneric(`${this.dataService.configSettings.restCommonUrl}/downloadAttach`, allegato).subscribe(ret => {
        this.getFileToDownload(allegato.nomeFile, ret.fileBase64StringData, ret.mimeType);
      })
    );
  }

  private getFileToDownload(filename: string, base64string: string, mimeType: string) {
    var byteCharacters = atob(base64string);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var blob = new Blob([new Uint8Array(byteNumbers)], { type: mimeType });

    if (/iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent)) {
      var a = document.createElement("a");
      a.download = filename;
      a.href = window["webkitURL"].createObjectURL(blob);
      a;
      a.click();
      setTimeout(function () { window.URL.revokeObjectURL(url); }, 3000);
    } else {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  private addAllegatoFg(allegato: GenAllegato): void {
    this.allegatiListFa.push(this.buildAllegatoFg(allegato));
  }

  newAllegato(): void {
    const allegato = new GenAllegato();
    allegato.id = 0;
    allegato.ambitoId = 0;
    allegato.extId = this.extId;
    allegato.extTableName = this.extTableName;
    allegato.nomeFile = '';
    allegato.estensione = '';
    allegato.nota = '';

    this.allegatiListFa.insert(0, this.buildAllegatoFg(allegato));
  }

  save() {
    this.elaboration = true;
    const data = this.allegatiListFa.value as GenAllegato[];

    this.subscription.add(
      this.dataService.upsertElements<GenAllegato>(`${this.dataService.configSettings.restCommonUrl}/genallegatous`, data).subscribe(
        (data: IEntityList<GenAllegato>) => {
          this.allegatiListFa.clear();
          data.entities.forEach(this.addAllegatoFg, this)

          this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.savesuccessmsg')));

          this.elaboration = false;
          this.dataForm.markAsPristine();

          this.emitIsCompleted();
          this.emitSaveCompleted(true);
        },
        (error: HttpErrorResponse) => {
          this.elaboration = false;
          this.emitSaveCompleted(false);
        }
      )
    );
  }

  modalEntityLogShow(id: number): void {
    this.logService.openModalEntityLog('GenAllegato', id);
  }

  deleteAllegato(i: number): void {
    const messageData = new MessageDataFromConfig(MessageDataConfig.Delete, () => {
      this.deleteAllegatoInner(i);
    });

    this.appManagerService.showMessage(messageData);
  }

  private deleteAllegatoInner(i: number): void {
    const allegato = this.allegatiListFa.at(i).value;
    if (allegato.id) {
      this.elaboration = true;

      this.subscription.add(
        this.dataService.deleteElement<GenAllegato>(`${this.dataService.configSettings.restCommonUrl}/genallegato`, allegato.id).subscribe(
          (data: number) => {
            this.elaboration = false;
            if (data > 0) {
              this.appManagerService.showToastMessage(new ToastMessageData('success', this.translateService.translate(this.resourceList, 'generic.deletesuccessmsg')));

              this.allegatiListFa.removeAt(i);
              this.emitIsCompleted()
              this.emitDeleteEvent(true);
            }
          },
          (error: HttpErrorResponse) => {
            this.elaboration = false;

            this.emitDeleteEvent(false);
          }
        )
      );
    } else {
      this.allegatiListFa.removeAt(i);
    }
  }

  private emitSaveCompleted(saveCompleted: boolean, data?: any): void {
    this.actionEvent.emit({
      action: ActionEventType.save,
      done: saveCompleted,
      data: data
    });
  }

  private emitDeleteEvent(deleteCompleted: boolean, data?: any): void {
    this.actionEvent.emit({
      action: ActionEventType.delete,
      done: deleteCompleted,
      data: data
    });
  }

  private emitIsCompleted(): void {
    this.isCompleted.emit(this.isCompletedCheck());
  }

  private isCompletedCheck() {
    return this.dataForm.pristine;
  }

}
