import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../components/button/button.component';
import { EntityFieldViewComponent } from '../components/entity/entity-field-view/entity-field-view.component';
import { EntityFormComponent } from '../components/entity/entity-form/entity-form.component';
import { EntityListEditableComponent } from '../components/entity/entity-list-editable/entity-list-editable.component';
import { EntityListComponent } from '../components/entity/entity-list/entity-list.component';
import { EntitySearchComponent } from '../components/entity/entity-search/entity-search.component';
import { EntityViewComponent } from '../components/entity/entity-view/entity-view.component';
import { ListDetailControlComponent } from '../components/form-control/list-detail-control.component';
import { ListSearchComponent } from '../components/search/list-search/list-search.component';
import { SearchFieldAutocompleteComponent } from '../components/search/search-field-autocomplete/search-field-autocomplete.component';
import { SearchFieldCalendarComponent } from '../components/search/search-field-calendar/search-field-calendar.component';
import { SearchFieldCheckboxComponent } from '../components/search/search-field-checkbox/search-field-checkbox.component';
import { SearchFieldDropdownComponent } from '../components/search/search-field-dropdown/search-field-dropdown.component';
import { SearchFieldInputnumberComponent } from '../components/search/search-field-inputnumber/search-field-inputnumber.component';
import { SearchFieldInputtextComponent } from '../components/search/search-field-inputtext/search-field-inputtext.component';
import { SearchFieldMultiselectComponent } from '../components/search/search-field-multiselect/search-field-multiselect.component';
import { SearchFieldYearComponent } from '../components/search/search-field-year/search-field-year.component';
import { SidemenuComponent } from '../components/sidemenu/sidemenu.component';
import { TextBlockComponent } from '../components/text-block/text-block.component';
import { WindowDetailComponent } from '../components/window/window-detail/window-detail.component';
import { WindowHelpComponent } from '../components/window/window-help/window-help.component';
import { WindowListDetailSearchComponent } from '../components/window/window-list-detail-search/window-list-detail-search.component';
import { WindowListEditableSearchComponent } from '../components/window/window-list-editable-search/window-list-editable-search.component';
import { WindowListSearchComponent } from '../components/window/window-list-search/window-list-search.component';
import { WindowTabComponent } from '../components/window/window-tab/window-tab.component';
import { YesNoComponent } from '../components/yes-no/yes-no.component';
import { EntityStateDirective } from '../directives/entity-state.directive';
import { GrantVisibleDirective } from '../directives/grant-visible.directive';
import { TableRowHilightDirective } from '../directives/table-row-hilight.directive';
import { PipesModule } from '../pipes/pipes.module';
import { TriggersService } from '../services/extension/triggers.service';
import { GenNotificheService } from '../services/gennotifiche.service';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { AngularFileUploaderComponent } from './gestione/components/angular-file-uploader/angular-file-uploader.component';
import { GenAllegatoListComponent } from './gestione/components/gen-allegato-list/gen-allegato-list.component';
import { GenLogEventoListComponent } from './gestione/components/gen-log-evento-list/gen-log-evento-list.component';
import { GenNotaAddComponent } from './gestione/components/gen-nota-add/gen-nota-add.component';
import { GenNotaListComponent } from './gestione/components/gen-nota-list/gen-nota-list.component';
import { EntityLogComponent } from './gestione/components/genlog-list/entity-log.component';
import { GenNotificaNewComponent } from './gestione/components/gennotifica-new/gennotifica-new.component';
import { GenNotificaWizardComponent } from './gestione/components/gennotifica-wizard/gennotifica-wizard.component';
import { ListExportComponent } from './gestione/components/list-export/list-export.component';
import { LoadingSpinnerComponent } from './gestione/components/loading-spinner/loading-spinner.component';
import { MessageAppComponent } from './gestione/components/message-app/message-app.component';
import { GiudiceDetailAbilitazioniComponent } from './giudici/components/giudice-detail/giudice-detail-abilitazioni/giudice-detail-abilitazioni.component';
import { GiudiceDetailBlocchiPrvComponent } from './giudici/components/giudice-detail/giudice-detail-blocchi-prv/giudice-detail-blocchi-prv.component';
import { GiudiceDetailBlocchiRazzeComponent } from './giudici/components/giudice-detail/giudice-detail-blocchi-razze/giudice-detail-blocchi-razze.component';
import { GiudiceDetailCertificatiComponent } from './giudici/components/giudice-detail/giudice-detail-certificati/giudice-detail-certificati.component';
import { GiudiceDetailImpegniEsteriComponent } from './giudici/components/giudice-detail/giudice-detail-impegni-esteri/giudice-detail-impegni-esteri.component';
import { GiudiceDetailImpegniManiExpRazzaComponent } from './giudici/components/giudice-detail/giudice-detail-impegni-mani-exp-razza/giudice-detail-impegni-mani-exp-razza.component';
import { GiudiceDetailImpegniManiExpComponent } from './giudici/components/giudice-detail/giudice-detail-impegni-mani-exp/giudice-detail-impegni-mani-exp.component';
import { GiudiceDetailImpegniManiPrvSpComponent } from './giudici/components/giudice-detail/giudice-detail-impegni-mani-prv-sp/giudice-detail-impegni-mani-prv-sp.component';
import { GiudiceDetailImpegniManiPrvComponent } from './giudici/components/giudice-detail/giudice-detail-impegni-mani-prv/giudice-detail-impegni-mani-prv.component';
import { GiudiceDetailSospensioniComponent } from './giudici/components/giudice-detail/giudice-detail-sospensioni/giudice-detail-sospensioni.component';
import { GenRilascioListComponent } from './profilo-utente/components/gen-rilascio-list/gen-rilascio-list.component';

@NgModule({
  declarations: [
    GrantVisibleDirective,
    ListDetailControlComponent,
    EntityFieldViewComponent,
    EntityListComponent,
    EntityListEditableComponent,
    EntityFormComponent,
    EntitySearchComponent,
    EntityViewComponent,
    WindowListSearchComponent,
    WindowListEditableSearchComponent,
    WindowDetailComponent,
    WindowListDetailSearchComponent,
    WindowTabComponent,
    WindowHelpComponent,
    SidemenuComponent,
    BreadcrumbComponent,
    GenNotificaWizardComponent,
    GenNotificaNewComponent,
    YesNoComponent,
    EntityLogComponent,
    ListExportComponent,
    EntityListComponent,
    GenNotaListComponent,
    GenNotaAddComponent,
    GenAllegatoListComponent,
    AngularFileUploaderComponent,
    EntityStateDirective,
    GiudiceDetailImpegniEsteriComponent,
    GiudiceDetailBlocchiRazzeComponent,
    GiudiceDetailBlocchiPrvComponent,
    GiudiceDetailSospensioniComponent,
    GiudiceDetailAbilitazioniComponent,
    GiudiceDetailImpegniManiPrvComponent,
    GiudiceDetailImpegniManiPrvSpComponent,
    GiudiceDetailImpegniManiExpComponent,
    GiudiceDetailImpegniManiExpRazzaComponent,
    GiudiceDetailCertificatiComponent,
    TableRowHilightDirective,
    LoadingSpinnerComponent,
    GenRilascioListComponent,
    MessageAppComponent,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    SearchFieldCheckboxComponent,
    SearchFieldAutocompleteComponent,
    ButtonComponent,
    TextBlockComponent,
    UnauthorizedUserComponent,
    GenLogEventoListComponent,
    ListSearchComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    TooltipModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    DialogModule,
    ProgressSpinnerModule,
    DividerModule,
    MenuModule,
    PanelMenuModule,
    AccordionModule,
    BreadcrumbModule,
    FieldsetModule,
    PanelModule,
    EditorModule,
    TagModule,
    TreeSelectModule,
    StepsModule,
    PaginatorModule,
    SidebarModule,
    OverlayPanelModule,
    BadgeModule,
    ScrollTopModule,
    ListboxModule,
    DynamicDialogModule,
    ChipsModule,
    BlockUIModule,
    SelectButtonModule,
    ChipModule,
    CardModule,
    DragDropModule,
    AutoCompleteModule,
    DataViewModule
  ],
  exports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    // TranslateModule,
    GrantVisibleDirective,
    SidemenuComponent,
    BreadcrumbComponent,
    WindowTabComponent,
    WindowHelpComponent,
    // EntityFieldViewComponent,
    // EntityListComponent,
    // EntityListEditableComponent,
    // EntityFormComponent,
    // EntitySearchComponent,
    // TooltipModule,
    // WindowListSearchComponent,
    // WindowListEditableSearchComponent,
    // WindowDetailComponent,
    // WindowListDetailSearchComponent
    TableModule,
    TabViewModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    InputNumberModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    AutoCompleteModule,
    FileUploadModule,
    DialogModule,
    ProgressSpinnerModule,
    DividerModule,
    MenuModule,
    PanelMenuModule,
    AccordionModule,
    BreadcrumbModule,
    FieldsetModule,
    PanelModule,
    EditorModule,
    TagModule,
    WindowListSearchComponent,
    TreeSelectModule,
    StepsModule,
    PaginatorModule,
    GenNotificaWizardComponent,
    GenNotificaNewComponent,
    OverlayPanelModule,
    BadgeModule,
    ScrollTopModule,
    YesNoComponent,
    EntityLogComponent,
    ListExportComponent,
    ListboxModule,
    GenNotaListComponent,
    GenNotaAddComponent,
    GenAllegatoListComponent,
    AngularFileUploaderComponent,
    DynamicDialogModule,
    EntityStateDirective,
    ChipsModule,
    GiudiceDetailImpegniEsteriComponent,
    GiudiceDetailBlocchiRazzeComponent,
    GiudiceDetailBlocchiPrvComponent,
    GiudiceDetailSospensioniComponent,
    GiudiceDetailAbilitazioniComponent,
    GiudiceDetailImpegniManiPrvComponent,
    GiudiceDetailImpegniManiPrvSpComponent,
    GiudiceDetailImpegniManiExpComponent,
    GiudiceDetailImpegniManiExpRazzaComponent,
    GiudiceDetailCertificatiComponent,
    BlockUIModule,
    SelectButtonModule,
    TableRowHilightDirective,
    LoadingSpinnerComponent,
    GenRilascioListComponent,
    MessageAppComponent,
    ChipModule,
    CardModule,
    DragDropModule,
    SearchFieldMultiselectComponent,
    SearchFieldCalendarComponent,
    SearchFieldDropdownComponent,
    SearchFieldInputtextComponent,
    SearchFieldInputnumberComponent,
    SearchFieldYearComponent,
    ButtonComponent,
    TextBlockComponent,
    SearchFieldCheckboxComponent,
    SearchFieldAutocompleteComponent,
    AutoCompleteModule,
    DataViewModule,
    GenLogEventoListComponent,
    ListSearchComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    GenNotificheService,
    TriggersService,
  ]
})
export class SharedModule { }
