import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AppManagerRoutes } from 'src/app/app-manager-routes';
import { CmdFieldTarget, EntityManager, EntityManagerInfo } from 'src/app/models/entity-config';
import { AppManagerService, SESSION_STORAGE_GLOBAL } from 'src/app/services/app-manager.service';
import { Resources, TranslateService } from '../../services/translate.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})

export class SidemenuComponent implements OnInit, OnDestroy {
  entityManager: EntityManager;
  translateItems: Resources;
  translatedItems$: Observable<Resources>;
  menuItems: MenuItem[] = [];
  menuSticky: boolean = true;
  isXsmall: boolean = false;

  @Input() menuExpanded: boolean = false;

  @Output() menuExpandedEvent = new EventEmitter<boolean>();
  @Output() menuStickyEvent = new EventEmitter<boolean>();

  private subscription: Subscription;

  readonly breakpoint$ = this.breakpointObserver.observe(Breakpoints.XSmall).pipe(
    distinctUntilChanged()
  );

  constructor(
    private router: Router,
    private appManagerService: AppManagerService,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    // this.subscription.add(
    //    this.appManagerService.initialized$
    //    .pipe(
    //       switchMap(appInitialized => {

    //          if (!appInitialized.initialized) {
    //             return EMPTY;
    //          }

    //          this.entityManager = this.appManagerService.getEntityManagerByUrl(this.router.url.split('/'), 1);

    //          return this.translateService.translatedItems$(this.entityManager.translateSuffixs);
    //       })
    //    )
    //    .subscribe(translateItems => {
    //       this.translateItems = translateItems;
    //       this.menuItems = this.buildMenuItems(this.entityManager.id);
    //    })
    // );

    this.subscription.add(
      this.appManagerService.initialized$.subscribe(
        appInitialized => {
          if (appInitialized.initialized) {
            this.entityManager = this.appManagerService.getEntityManagerByUrl(this.router.url.split('/'), 1);

            this.translatedItems$ = this.translateService.translatedItems$(this.entityManager.translateSuffixs).pipe(
              tap(translateItems => {
                this.translateItems = translateItems;
                this.menuItems = this.buildMenuItems(this.entityManager.id);
              })
            );
          }
        }
      )
    );

    this.subscription.add(
      this.breakpoint$.subscribe(
        () => this.breakpointChanged()
      )
    );

    this.menuSticky = this.appManagerService.getStorageData(SESSION_STORAGE_GLOBAL, 'menu')?.menuSticky ?? this.menuSticky;
  }

  private buildMenuItems(entityManagerId: string): MenuItem[] {
    const result: MenuItem[] = [];
    const entityManagerInfo: EntityManagerInfo = AppManagerRoutes[entityManagerId];
    const menuEntityManager = this.appManagerService.instantiateEntityManager(entityManagerInfo);
    menuEntityManager.getCmdFields(CmdFieldTarget.menu, null, false).forEach(field => {
      const item: MenuItem = {
        label: this.translateService.translate(this.translateItems, field.label),
        title: this.translateService.translate(this.translateItems, field.title),
        icon: field.icon
      };

      let childs: MenuItem[] = this.buildMenuItems(field.entityManagerInfo.id);
      if (childs.length > 0) {
        item.items = childs;
      } else {
        // if (field.entityManagerInfo.params?.length > 0) {
        //    item.routerLink = `${field.entityManagerInfo.url}/${field.entityManagerInfo.params.join('/')}`
        // }

        item.command = (event: any) => this.toggleMenuExpanded(event);

        if (field.entityManagerInfo.queryParams) {
          item.queryParams = field.entityManagerInfo.queryParams;
        }
        if (field.entityManagerInfo.parent?.prependParentOnUrl) {
          item.routerLink = `${field.entityManagerInfo.parent.url}/${field.entityManagerInfo.url}`;
        }
        else {
          item.routerLink = field.entityManagerInfo.url;
        }
      }
      result.push(item);
    })
    return result;
  }

  private toggleMenuExpanded(event: any): void {
    this.menuExpanded = !this.menuExpanded;
    this.menuExpandedEvent.emit(this.menuExpanded);
  }

  toggleMenuSticky(event: any): void {
    this.menuSticky = !this.menuSticky;
    this.appManagerService.setStorageData(SESSION_STORAGE_GLOBAL, 'menu', { menuSticky: this.menuSticky });
    this.menuStickyEvent.emit(this.menuSticky);
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.menuSticky = false;
      this.appManagerService.setStorageData(SESSION_STORAGE_GLOBAL, 'menu', { menuSticky: this.menuSticky });
      this.isXsmall = true;
      this.menuStickyEvent.emit(this.menuSticky);
    }
    else {
      this.isXsmall = false;
    }
  }

}
