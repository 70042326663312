import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TriggersService } from 'src/app/services/extension/triggers.service';
import { Resources, TranslateService } from 'src/app/services/translate.service';
import { VuxGiudiciAbilitazioniTreeDto } from 'src/app/models/vux/dto/vux-giudici-abilitazioni-tree-dto';
import { dateFormat } from 'src/app/utils/util';
import { DataService } from 'src/app/services/data.service';
import { AppManagerRoutes } from 'src/app/app-manager-routes';

@Component({
  selector: 'app-giudice-detail-abilitazioni',
  templateUrl: './giudice-detail-abilitazioni.component.html',
  styleUrls: ['./giudice-detail-abilitazioni.component.scss']
})
export class GiudiceDetailAbilitazioniComponent implements OnInit, OnDestroy {
  dateFormat: string = dateFormat
  T$: Observable<Resources>;
  abilitazioniGiud$: Observable<VuxGiudiciAbilitazioniTreeDto[]>;
  gruppoSel: VuxGiudiciAbilitazioniTreeDto;
  filterEnabled: boolean = true;

  private subscription: Subscription;

  @Input() abilitazioniTipo: 'PRV' | 'EXP';
  @Input() giudiceId: number;
  @Input() tabIndex: number;
  @Input() flAllRounder: boolean = false;

  get url(): string {
    return this.abilitazioniTipo === 'EXP' ? 'GetVuxGiudiciAbilitazioniExpTree' : 'GetVuxGiudiciAbilitazioniPrvTree'
  }

  constructor(
    private dataService: DataService,
    private translateService: TranslateService,
    private triggersService: TriggersService
  ) {
    this.subscription = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    // Etichette
    this.T$ = this.translateService.translatedItems$(AppManagerRoutes.GiudiceDetail.translateSuffixs ?? ['generic.']);

    // Caricamento dati all'inzializzazione
    this.loadData();

    // Refresh dati al cambio tab
    if (this.tabIndex >= 0) {
      this.subscription.add(
        this.triggersService.triggerTabChange$.subscribe(
          (currTabIndex: number) => {
            if (currTabIndex === this.tabIndex) {
              this.loadData();
            }
          }
        )
      );
    }
  }

  private loadData(): void {
    this.abilitazioniGiud$ = this.dataService.getGeneric(`${this.dataService.configSettings.restCommonUrl}/${this.url}`, `giudId=${this.giudiceId}`);

    this.gruppoSel = undefined;
  }

  selectGroup(gruppo: VuxGiudiciAbilitazioniTreeDto): void {
    this.gruppoSel = gruppo;
  }

  onChangeGiudiceDropdown(event: any): void {
    this.selectGroup(event.value);
  }

  toggleFilter(): void {
    this.filterEnabled = !this.filterEnabled;

    this.gruppoSel = undefined;
  }

}
